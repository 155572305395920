import { ref, computed, reactive } from "vue";
import { defineStore } from "pinia";
import cheetahJs from "cheetah-js";
import { fetchWrapper } from "../helpers/fetch-wrapper";

const baseUrl = `${import.meta.env.VITE_API_URL}/api/selection`;

export const useDataStore = defineStore("data", () => {
  const areaCorrectionsData = ref([]);
  const overAllCorrectionsData = ref([]);

  const getAreaCorrectionsData = () => {
    return fetchWrapper
      .get(`${baseUrl}/get-area-corrections`)
      .then((response) => {
        areaCorrectionsData.value = response.data;
      });
  };
  const getOverAllCorrectionsData = () => {
    return fetchWrapper
      .get(`${baseUrl}/get-over-all-corrections`)
      .then((response) => {
        overAllCorrectionsData.value = response.data;
      });
  };
  return {
    areaCorrectionsData,
    getAreaCorrectionsData,
    overAllCorrectionsData,
    getOverAllCorrectionsData,
  };
});
