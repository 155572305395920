<template>
  <div
    v-if="loading"
    class="absolute top-0 bottom-0 left-0 right-0 backdrop-blur-1 z-50"
  >
    <div class="m-auto block w-fit relative top-[48%]">
      <svg
        class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </div>
  </div>
  <nav class="flex mb-3 xs:px-2" aria-label="Breadcrumb">
    <ol
      role="list"
      class="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8 rounded-lg shadow bg-white"
    >
      <!-- <li class="flex">
        <div class="flex items-center">
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li> -->
      <li v-for="(page, idx) in pages" :key="page.name" class="flex">
        <div class="flex items-center">
          <svg
            v-if="idx > 0"
            class="h-full w-6 flex-shrink-0 text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <button
            @click="router.push(page.href)"
            class="text-sm font-medium text-gray-500 hover:text-gray-700"
            :class="{ 'ml-4': idx > 0 }"
            :aria-current="page.current ? 'page' : undefined"
          >
            {{ page.name }}
          </button>
        </div>
      </li>
    </ol>
  </nav>
  <div
    class="relative border-b border-gray-200 pb-5 sm:pb-0 mb-4 lg:max-xl:px-0 px-2"
  >
    <div class="md:flex md:items-center md:justify-between pt-3">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        <!-- <button class="h-[24px] align-top" @click="previousResult">
          <ChevronLeftIcon class="block h-6 w-6" />
        </button> -->
        {{ state.selectionResult.model }}
        <!-- <button class="h-[24px] align-top" @click="nextResult">
          <ChevronRightIcon class="block h-6 w-6" />
        </button> -->
      </h3>
      <div
        v-if="globalStore.isMobile"
        class="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600"
      >
        <div class="grid h-full max-w-lg grid-cols-2 mx-auto font-medium">
          <div class="inline-flex flex-col items-center justify-center">
            <Button
              v-if="isProjectReferrer"
              button-color="primary"
              button-text="button_text_save_changes"
              button-size="xs"
              :class="saveChangesClass"
              @click="saveProjectSelection"
            />
            <Button
              v-else
              button-color="primary"
              button-text="button_text_add_to_project"
              button-size="xs"
              :class="addToProjectClass"
              @click="addToProject"
            />
          </div>
          <div class="inline-flex flex-col items-center justify-center">
            <Button
              v-if="!showDownloadButton"
              :disabled="state.isPrinting"
              button-color="primary"
              :button-text="printButtonText"
              button-size="xs"
              class="ml-3"
              @click="printProduct"
            >
              <template v-if="state.isPrinting" #pre-content>
                <svg
                  aria-hidden="true"
                  role="status"
                  class="inline w-4 h-4 mr-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              </template>
            </Button>

            <Button
              v-if="showDownloadButton"
              :use-as-anchor="true"
              :href="state.printDownloadUrl"
              download
              button-color="primary"
              button-text="button_text_download_pdf"
              button-size="xs"
              class="ml-3 rounded-full "
            >
            </Button>
          </div>
        </div>
      </div>
      <div
        v-if="!globalStore.isMobile"
        class="mt-3 flex md:absolute md:top-3 md:right-0 md:mt-0"
      >
        <Button
          v-if="isProjectReferrer"
          button-color="primary"
          button-text="button_text_save_changes"
          button-size="xs"
          :class="saveChangesClass"
          @click="saveProjectSelection"
        />
        <Button
          v-else
          button-color="primary"
          button-text="button_text_add_to_project"
          button-size="xs"
          :class="addToProjectClass"
          @click="addToProject"
        />
        <Button
          v-if="!showDownloadButton"
          :disabled="state.isPrinting"
          button-color="primary"
          :button-text="printButtonText"
          button-size="xs"
          class="ml-3"
          @click="printProduct"
        >
          <template v-if="state.isPrinting" #pre-content>
            <svg
              aria-hidden="true"
              role="status"
              class="inline w-4 h-4 mr-3 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
          </template>
        </Button>

        <Button
          v-if="showDownloadButton"
          :use-as-anchor="true"
          :href="state.printDownloadUrl"
          download
          button-color="primary"
          button-text="button_text_download_pdf"
          button-size="xs"
          class="ml-3 rounded-full"
        >
        </Button>
      </div>
    </div>

    <div
      v-if="!globalStore.isMobile"
      class="mt-4 pb-3 flex items-center justify-between"
    >
      <div class="min-w-max max-w-max pr-4">
        <img :src="getProductImage" class="mx-auto" />
      </div>
      <div class="w-full whitespace-pre-line">
        <div>
          <T :keyName="state.selectionResult.shortDescription" />
        </div>
        <div class="text-sm">
          <T :keyName="state.selectionResult.longDescription" />
        </div>
      </div>
    </div>

    <div v-if="globalStore.isMobile" class="mt-4 grid grid-cols-1">
      <div class="col-span-1">
        <img :src="getProductImage" class="mx-auto" />
      </div>

      <div class="col-span-1 whitespace-pre-line">
        <div>
          <T :keyName="state.selectionResult.shortDescription" />
        </div>
        <div class="text-sm">
          <T :keyName="state.selectionResult.longDescription" />
        </div>
      </div>
    </div>
  </div>
  <div class="grid md:grid-cols-3 sm:grid-cols-1 gap-4">
    <div class="md:col-span-2 sm:col-span-1">
      <div class="columns-1">
        <div class="divide-y divide-gray-200 overflow-hidden bg-white shadow rounded-lg">
          <div class="px-4 py-2 text-black">
            <div class="text-md text-center font-bold">
              <T keyName="card_title_product_diagram" />
            </div>
          </div>
          <div class="px-2 pt-2">
            <!-- <img :src="getDrawing" class="max-w-full mx-auto" /> -->
            <div class="relative ">
              <img class="w-full h-auto" :src="getDrawing" alt=""/>
              <template v-if="!state.selectionResult.dimensions">
                <span id="length" class="text-sm md:text-base lg:text-lg  absolute top-[78%] left-[27%] translate-x-[-7%] translate-y-[-30%] font-semibold ">{{state.selectionResult.length}}mm</span>
                <span id="width" class="text-sm md:text-base lg:text-lg absolute top-[12%] left-[25%] translate-x-[-5.4%] translate-y-[-65.8%] font-semibold">{{state.selectionResult.width}}mm</span>
                <span id="height" class="text-sm md:text-base lg:text-lg  absolute top-[35.5%] left-[9.5%] translate-x-[-36.8%] translate-y-[-4%] font-semibold">{{state.selectionResult.height}}mm</span>
              </template>              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns-1">
      <TechnicalSection
        v-if="state.technicalSections.attenuator"
        :loading="false"
        :section="state.technicalSections.attenuator"
      />
      <div
        v-if="state.selectionResult.soundSpectrums && state.selectionResult.soundSpectrums.length > 0"
        class=" mt-3 bg-white divide-y divide-gray-200 overflow-hidden  shadow rounded-lg"
      >
        <div class="px-4 py-2 ">
          <div class="text-md text-black text-center font-bold">
            <T keyName="card_title_resultant_sound" />
          </div>
        </div>
        <div class="px-4 py-2">
          <div class="grid grid-cols-1 gap-2">
            <div class="col-span-1">
              <table class="text-xs w-full text-end">
                <thead class="font-bold">
                  <tr>
                    <td></td>
                    <td>63</td>
                    <td>125</td>
                    <td>250</td>
                    <td>500</td>
                    <td>1k</td>
                    <td>2k</td>
                    <td>4k</td>
                    <td>8k</td>
                    <!-- <td>Overall</td> -->
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="spectrum in state.selectionResult.soundSpectrums"
                  >
                    <tr>
                      <td class="font-bold">
                        {{ spectrum.description }}
                      </td>
                      <td>{{ spectrum.dB[0].toFixed(1) }}</td>
                      <td>{{ spectrum.dB[1].toFixed(1) }}</td>
                      <td>{{ spectrum.dB[2].toFixed(1) }}</td>
                      <td>{{ spectrum.dB[3].toFixed(1) }}</td>
                      <td>{{ spectrum.dB[4].toFixed(1) }}</td>
                      <td>{{ spectrum.dB[5].toFixed(1) }}</td>
                      <td>{{ spectrum.dB[6].toFixed(1) }}</td>
                      <td>{{ spectrum.dB[7].toFixed(1) }}</td>
                      <!-- <td>{{ spectrum.overall }}</td> -->
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div v-if="state.selectionResult.dimensions"
      class=" mt-3 bg-white divide-y divide-gray-200 overflow-hidden  shadow rounded-lg">
      <div class="px-4 py-2 ">
        <div class="text-md text-black text-center font-bold">
          <T keyName="Product Dimensions" />
        </div>
      </div>
      <div class="px-4 py-2">
        <div class="grid grid-cols-1 gap-2">
          <div class="col-span-1">
            <div class="text-base w-full ">
                <div
                v-if="state.selectionResult.dimensions"
                v-for="(dimension, index) in state.selectionResult.dimensions"
                class="flex flex-row "
                :class="{ 'border-b': index < state.selectionResult.dimensions.length - 1 }"
                >
                    <div class="w-[50%] mr-4  font-bold text-end">{{ dimension.key }}</div>
                    <div class=" w-[50%] text-start">
                      {{ dimension.key === 'n x Dth' ? dimension.value : dimension.value + 'mm' }}
                    </div> 
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    </div>
  </div>
  <div
    class="grid grid-cols-12 xs:grid-cols-1 gap-4 xs:px-2"
  >
    <div
      v-for="asset in state.selectionResult.productAssets"
      class="md:col-span-2 sm:col-span-1"
    >
      <div
        class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow"
      >
        <div class="px-2 py-2">
          <div class="grid grid-cols-12">
            <div class="col-span-12 mb-1">
              <DocumentArrowDownIcon class="w-24 h-24 mx-auto text-gray-300" />
            </div>
            <div class="col-span-12 mx-auto mb-1">
              <label class="text-xs text-ellipsis">
                {{ asset.description }}
              </label>
            </div>
            <div class="col-span-12 mx-auto mb-1">
              <Button
                :use-as-anchor="true"
                :href="asset.downloadUrl"
                download
                button-color="primary"
                button-text="button_text_download"
                button-size="xs"
                class=""
              >
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useSelectionStore } from "../stores/selection";
import { useProjectStore } from "../stores/project";
import { storeToRefs } from "pinia";
import { reactive, computed, watch } from "vue";
import { useRoute } from "vue-router";
import Button from "../components/shared/Button.vue";
import { Chart } from "highcharts-vue";
import { useRouter } from "vue-router";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  MinusIcon,
  DocumentArrowDownIcon,
} from "@heroicons/vue/20/solid";
import { HomeIcon } from "@heroicons/vue/20/solid";
import TechnicalSection from "@/components/technical/TechnicalSection.vue";
import { notify } from "notiwind";
import Input from "@/components/shared/Input.vue";
import { useGlobalStore } from "../stores/global";

const router = useRouter();
const selectionStore = useSelectionStore();
const projectStore = useProjectStore();
const route = useRoute();
const globalStore = useGlobalStore();

const {
  volumeFlowUnits,
  pressureUnits,
  selectionInformation,
  selectionResultItems,
} = storeToRefs(selectionStore);

const { project } = storeToRefs(projectStore);

const state = reactive({
  technicalSections: {},
  printDownloadUrl: "",
  isPrinting: false,
  loading: false,
  addToProjectBusy: false,
  selectionResult: null,
  replaceId: "",
});


const isProjectReferrer = computed(() => {
  return route.params.referrer == "project";
});

const addToProjectClass = computed(() => {
  return state.addToProjectBusy ? "opacity-75 pointer-events-none" : "";
});

const getAccessoryDrawing = (accessory) => {
  return accessory.drawingFilename && accessory.drawingFilename.length
    ? `${window.location.origin}/assets/images/drawings/accessories/${accessory.drawingFilename}`
    : "#";
};

const getAccessoryImage = (accessory) => {
  return accessory.imageFileName && accessory.imageFileName.length
    ? `${window.location.origin}/assets/images/products/accessories/${accessory.imageFileName}`
    : "https://picsum.photos/128";
};

const showDownloadButton = computed(() => {
  return state.printDownloadUrl.length > 0 && !state.isPrinting;
});

const printButtonText = computed(() => {
  return state.isPrinting
    ? "button_text_generating_pdf"
    : "button_text_generate_pdf";
});

const getDrawing = computed(() => {
  let imagePath = state.selectionResult.drawingFilename &&
    state.selectionResult.drawingFilename
    ? `${window.location.origin}/assets/images/drawings/${state.selectionResult.drawingFilename}`
    : "https://via.placeholder.com/128";
    return imagePath
});

const getProductImage = computed(() => {
  return state.selectionResult.imageFilename &&
    state.selectionResult.imageFilename
    ? `${window.location.origin}/assets/images/products/${state.selectionResult.imageFilename}`
    : "https://via.placeholder.com/128";
});

const printProduct = () => {
  state.printDownloadUrl = "";
  state.isPrinting = true;

  fetch(`${import.meta.env.VITE_API_URL}/api/print/print`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: state.selectionResult.id,
      printType: "product",
      referrer: route.params.referrer,
      culture: "en",
      filename: state.selectionResult.type.trim(),
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      state.printDownloadUrl = data.filename;
      state.isPrinting = false;
    });
};


const pages = computed(() => {
  return [
    { name: "Selections", href: "/selection", current: false },
    { name: state.selectionResult.model, href: "#", current: true },
  ];
});

const loading = computed(() => {
  return state.loading;
});


const selectFan = (volume, pressure) => {
  state.loading = true;
  fetch(`${import.meta.env.VITE_API_URL}/api/technical/selectFan`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      resultId: state.selectionResult.id,
      referrer: route.params.referrer,
      volumeFlow: volume,
      pressure: pressure,
      accessories: state.selectionResult.accessories,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      state.printDownloadUrl = "";
      state.selectionResult = data;
      loadProduct();
      state.loading = false;
    });
};


const decreaseAccessoryQuantity = (accessory) => {
  if (accessory.quantity > 0) {
    accessory.quantity--;
  }
  selectFan(0, 0);
};

const increaseAccessoryQuantity = (accessory) => {
  accessory.quantity++;
  selectFan(0, 0);
};

const loadProduct = () => {
  var resultItems =
    route.params.referrer == "selection"
      ? selectionResultItems.value
      : project.value.resultItems;

  if (state.selectionResult == null) {
    const result = resultItems.find((item) => item.id == route.params.id);
    state.selectionResult = JSON.parse(JSON.stringify(result));
    state.replaceId = state.selectionResult.id;
  }

  fetch(`${import.meta.env.VITE_API_URL}/api/technical/getTechnicalSection`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      resultId: state.selectionResult.id,
      referrer: route.params.referrer,
      include: [],
      exclude: [],
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      for (let i = 0; i < data.length; i++) {
        state.technicalSections[data[i].sectionId] = data[i];
      }
    });
};

const addToProject = () => {
  state.addToProjectBusy = true;
  const id = selectionResultItems.value.find(
    (item) => item.id == route.params.id
  ).id;
  projectStore
    .addToProject(state.selectionResult, state.selectionResult.accessories)
    .then(() => {
      state.addToProjectBusy = false;
      notify(
        {
          group: "notifications",
          title: "Success",
          text: "Item was added to the project",
        },
        5000
      );
    });
};

const saveProjectSelection = () => {
  projectStore
    .saveProjectSelection(state.replaceId, state.selectionResult)
    .then(() => {
      notify(
        {
          group: "notifications",
          title: "Success",
          text: "Project item changes were saved",
        },
        5000
      );
      router.push("/project");
    });
};

// const previousResult = () => {
//   let previousId =
//     selectionResultItems.value[
//       selectionResultItems.value.findIndex(
//         (i) => i.id == state.selectionResult.id
//       ) - 1
//     ].id;
//   router.push(`/selection/${previousId}`);
//   setActiveTab(state.tabs[0]);
//   loadProduct();
// };

// const nextResult = () => {
//   let nextId =
//     selectionResultItems.value[
//       selectionResultItems.value.findIndex(
//         (i) => i.id == state.selectionResult.id
//       ) + 1
//     ].id;
//   router.push(`/selection/${nextId}`);
//   setActiveTab(state.tabs[0]);
//   loadProduct();
// };

loadProduct();
window.scrollTo({ top: 0 });
</script>

<style lang="scss">
#notifications-container {
  @apply xl:pb-6 lg:pb-6 pb-20;
}
// .highcharts-background,
// .highcharts-plot-background,
// .highcharts-plot-border {
//   fill: white;
// }
// .highcharts-crosshair {
//   stroke: lightgray;
//   stroke-dasharray: 5;
// }
// .highcharts-line-series {
//   fill: white;
//   stroke: green;
// }
// .highcharts-axis-labels {
//   font-size: 12px;
// }
</style>
