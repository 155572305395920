<template>
  <div
    :id="props.popoverId"
    role="tooltip"
    class="absolute z-10 inline-block text-sm font-light text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800"
    :class="popoverClass"
  >
    <div class="px-3 py-2">
      <slot name="content"> </slot>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed } from "vue";
import { Popover } from "flowbite";

const props = defineProps([
  "popoverId",
  "triggerId",
  "triggerMode",
  "placement",
  "open",
  "offset",
]);

let popover = null;

const popoverClass = computed(() => {
  if (props.triggerMode == "none") {
    if (props.open) {
      return "opacity-100 visible";
    } else {
      return "opacity-0 invisible";
    }
  }
});

onMounted(() => {
  const $targetEl = document.getElementById(props.popoverId);

  // set the element that trigger the popover using hover or click
  const $triggerEl = document.getElementById(props.triggerId);

  // options with default values
  const options = {
    placement: props.placement,
    triggerType: props.triggerMode,
    offset: props.offset ?? 10,
  };

  popover = new Popover($targetEl, $triggerEl, options);
});
</script>

<style scoped></style>
