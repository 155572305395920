<template>
  <div>
    <label
      v-if="props.label"
      class="block text-sm font-medium text-gray-700"
      :class="props.labelClass"
    >
      <T :keyName="props.label" />
    </label>
    <div class="relative">
      <div
        v-if="leadingText"
        class="pointer-events-none absolute inset-y-0 left-3 flex items-center"
      >
        <span class="text-gray-500 sm:text-sm">{{ leadingText }}</span>
      </div>
      <input
        :type="getInputType"
        class="rounded-full shadow-sm focus:border-gray-500 focus:ring-gray-500 focus:ring-0 [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
        :class="inputClass"
        :placeholder="placeholder"
        :value="modelValue"
        @input="emitInput($event)"
        @change="emitChange()"
        :readonly="props.readonly"
      />
      <div
        v-if="trailingText"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <span class="text-gray-500 sm:text-sm">{{ trailingText }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";

const props = defineProps([
  "leadingText",
  "trailingText",
  "placeholder",
  "color",
  "small",
  "modelValue",
  "inputClass",
  "label",
  "labelClass",
  "inputType",
  "readonly",
]);



const emits = defineEmits(["update:modelValue", "input-change"]);

const emitChange = () => {
  console.log("input-change");
  emits("input-change");
}

const getInputType = computed(() => {
  return props.inputType ? props.inputType : "number";
});

const inputClass = computed(() => {
  let paddingY = props.small ? "py-1" : "py-2";
  let classes = `block w-full border-gray-300 pl-6 pr-6 focus:border-${props.color}-500 focus:ring-${props.color}-500 text-sm ${paddingY}`;
  classes = `${classes} ${props.inputClass}`;
  return classes;
});

const emitInput = (evt) => {
  emits("update:modelValue", evt.target.value);
};
</script>

<style scoped></style>
