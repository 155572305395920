<template>
  <Modal
    :open="props.open"
    @on-close="emits('on-close')"
    panel-class="!max-w-[1280px]"
  >
    <template #content>
      <div class="grid grid-cols-2">
        <div class="col-span-2">
          <div class="text-md">
            <T keyName="button_text_projects" />
          </div>
        </div>
        <div class="col-span-2 mt-2">
          <div>
            <label
              for="default-search"
              class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >Search</label
            >
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                v-model="filters.search"
                class="block w-full p-3 pl-10 text-sm text-gray-900 border border-gray-300 bg-white focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Search..."
                required
              />
            </div>
          </div>
        </div>
        <div
          v-if="user.userGroup.permissions.project.showAllProjects"
          class="col-span-1 mt-2"
        >
          <Dropdown
            label="input_label_user"
            :options="getUserList"
            color="primary"
            v-model="filters.userId"
          />
        </div>
        <div class="col-span-1 mt-2">
          <Checkbox
            class="mt-8 ml-4"
            label="input_label_show_revisions"
            v-model="filters.showRevisions"
          />
        </div>
        <div class="col-span-2 pt-4">
          <EasyDataTable
            ref="dataTable"
            :items="props.projectList"
            :loading="props.loading"
            :headers="state.columns"
            :server-items-length="props.serverItemsLength"
            v-model:server-options="serverOptions"
            hide-footer
            class="min-h-fit"
            must-sort
          >
            <template #item-modifiedDate="item">
              {{ formatDateTime(item.modifiedDate) }}
            </template>
            <template #item-createdDate="item">
              {{ formatDateTime(item.createdDate) }}
            </template>
            <template #item-actions="item">
              <div class="flex justify-end">
                <Button
                  button-color="primary"
                  button-text="button_text_open"
                  button-size="xs"
                  @click="openClick(item)"
                  class="mr-2"
                />
                <Button
                  button-color="primary"
                  button-text="button_text_copy"
                  button-size="xs"
                  @click="copyClick(item)"
                  class="mr-2"
                />
                <Button
                  button-color="red"
                  button-text="button_text_delete"
                  button-size="xs"
                  @click="emits('toggle-delete-prompt', item)"
                  :id="`button-save-project-prompt-${item.projectId}`"
                />
                <Popover
                  :popover-id="`popover-save-project-${item.projectId}`"
                  :trigger-id="`button-save-project-prompt-${item.projectId}`"
                  placement="bottom"
                  trigger-mode="none"
                  :open="item.showDeletePrompt"
                  class="w-56"
                >
                  <template #content>
                    <div class="grid grid-cols-2">
                      <div class="col-span-2 mb-2">
                        <T keyName="text_confirm_delete" />
                      </div>
                      <Button
                        button-color="red"
                        button-text="button_text_delete"
                        button-size="xs"
                        class="col-span-2 flex justify-center"
                        @click="deleteClick(item)"
                      />
                    </div>
                  </template>
                </Popover>
              </div>
            </template>
            <template #loading>
              <div role="status">
                <svg
                  class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            </template>
          </EasyDataTable>
        </div>
        <div class="col-span-2">
          <div
            class="flex items-center justify-between border-t border-gray-200 py-3"
          >
            <div class="flex flex-1 justify-between sm:hidden">
              <a
                href="#"
                class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >Previous</a
              >
              <a
                href="#"
                class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >Next</a
              >
            </div>
            <div
              class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between"
            >
              <div>
                <p class="text-sm text-gray-700">
                  Showing
                  {{ " " }}
                  <span class="font-medium">{{ currentPageFirstIndex }}</span>
                  {{ " " }}
                  to
                  {{ " " }}
                  <span class="font-medium">{{ currentPageLastIndex }}</span>
                  {{ " " }}
                  of
                  {{ " " }}
                  <span class="font-medium">{{ props.serverItemsLength }}</span>
                  {{ " " }}
                  results
                </p>
              </div>
              <div>
                <nav
                  class="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    @click="prevPage"
                    class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span class="sr-only">Previous</span>
                    <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                  <template v-for="p in maxPaginationNumber" :key="p">
                    <button
                      @click="updatePage(p)"
                      aria-current="page"
                      :class="[
                        p == currentPaginationNumber
                          ? activePageClass
                          : pageClass,
                      ]"
                    >
                      {{ p }}
                    </button>
                  </template>

                  <button
                    @click="nextPage"
                    class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  >
                    <span class="sr-only">Next</span>
                    <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import Modal from "../shared/Modal.vue";

import {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowTopRightOnSquareIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import { reactive, computed, watch, ref, onMounted } from "vue";
import Button from "../shared/Button.vue";
import "../../assets/datatable.scss";
import Dropdown from "../shared/Dropdown.vue";
import Checkbox from "../shared/Checkbox.vue";
import { useAuthStore } from "../../stores/auth";
import { storeToRefs } from "pinia";
import Popover from "../shared/Popover.vue";
import { format } from "date-fns";

const userStore = useAuthStore();

const { user } = storeToRefs(userStore);

const dataTable = ref();

const formatDateTime = (value) => {
  return format(new Date(value), "dd/MM/yyyy HH:mm:ss");
};

const props = defineProps([
  "open",
  "projectList",
  "loading",
  "serverOptions",
  "userList",
  "serverItemsLength",
]);

const getUserList = computed(() => {
  return user.value.userGroup.permissions.project.showAllProjects
    ? props.userList.map((u) => {
        return { text: u.email, value: u.id };
      })
    : [];
});

const serverOptions = ref({
  page: 1,
  rowsPerPage: 5,
  sortBy: "age",
  sortType: "desc",
});

const filters = ref({
  search: "",
  showRevisions: false,
  userId: "",
});

const state = reactive({
  columns: [
    {
      text: "Name",
      value: "projectName",
      sortable: true,
    },
    {
      text: "Reference",
      value: "projectReference",
      sortable: true,
    },
    {
      text: "Revision",
      value: "projectRevision",
    },
    {
      text: "Quotation Number",
      value: "quotationNumber",
      sortable: true,
    },
    {
      text: "Status",
      value: "status",
      sortable: true,
    },
    {
      text: "Modified",
      value: "modifiedDate",
      sortable: true,
    },
    {
      text: "Created",
      value: "createdDate",
      sortable: true,
    },
    {
      text: "",
      value: "actions",
      sortable: false,
    },
  ],
});

const emits = defineEmits([
  "actions-open-click",
  "actions-copy-click",
  "actions-delete-click",
  "toggle-delete-prompt",
  "on-close",
  "server-options-updated",
]);

const openClick = (projectItem) => {
  emits("actions-open-click", projectItem);
};

const copyClick = (projectItem) => {
  emits("actions-copy-click", projectItem);
};

const deleteClick = (projectItem) => {
  emits("actions-delete-click", projectItem);
};

watch(
  serverOptions,
  (value) => {
    serverOptionsUpdated();
  },
  { deep: true }
);

watch(
  filters,
  (value) => {
    serverOptionsUpdated();
  },
  { deep: true }
);

const serverOptionsUpdated = () => {
  emits("server-options-updated", {
    ...serverOptions.value,
    userId: filters.value.userId,
    filter: filters.value.search,
    showRevisions: filters.value.showRevisions,
  });
};

const activePageClass =
  "relative z-10 inline-flex items-center border border-primary-500 bg-primary-50 px-4 py-2 text-sm font-medium text-primary-600 focus:z-20";
const pageClass =
  "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20";

// index related
const currentPageFirstIndex = computed(() => {
  return props.projectList.length > 0
    ? dataTable.value?.currentPageFirstIndex
    : 0;
});
const currentPageLastIndex = computed(
  () => dataTable.value?.currentPageLastIndex
);

// pagination related
const maxPaginationNumber = computed(
  () => dataTable.value?.maxPaginationNumber
);
const currentPaginationNumber = computed(() => serverOptions.value.page);

const isFirstPage = computed(() => dataTable.value?.isFirstPage);
const isLastPage = computed(() => dataTable.value?.isLastPage);

const nextPage = () => {
  if (serverOptions.value.page < maxPaginationNumber.value)
    serverOptions.value.page++;
};
const prevPage = () => {
  if (serverOptions.value.page > 1) serverOptions.value.page--;
};
const updatePage = (paginationNumber) => {
  serverOptions.value.page = paginationNumber;
};

// rows per page related
const rowsPerPageOptions = computed(() => dataTable.value?.rowsPerPageOptions);
const rowsPerPageActiveOption = computed(
  () => dataTable.value?.rowsPerPageActiveOption
);

const updateRowsPerPageSelect = (e) => {
  dataTable.value.updateRowsPerPageActiveOption(Number(e.target.value));
};

onMounted(() => {
  serverOptions.value = {
    page: props.serverOptions.page,
    rowsPerPage: props.serverOptions.rowsPerPage,
    sortBy: props.serverOptions.sortBy,
    sortType: props.serverOptions.sortType,
  };
  filters.value.search = props.serverOptions.filter;
  filters.value.userId = props.serverOptions.userId;
  filters.value.showRevisions = props.serverOptions.showRevisions;
});
</script>

<style scoped></style>
