/* Creating a store for the user. */
import { ref, computed, reactive } from "vue";
import { defineStore } from "pinia";
import { fetchWrapper } from "../helpers/fetch-wrapper";
import router from "../router/index";

const baseUrl = `${import.meta.env.VITE_API_URL}/api/user`;

export const useAuthStore = defineStore("auth", () => {
  const user = ref(JSON.parse(localStorage.getItem("user")));
  const returnUrl = ref(null);
  const refreshTokenTimeout = ref(null);
  const isAuthenticated = computed(() => {
    return user.value != null;
  });
  const refreshToken = () => {
    fetchWrapper
      .post(`${baseUrl}/refresh-token`)
      .then((response) => {
        user.value = response;
        startRefreshTokenTimer();
      })
      .catch(() => {
        logout();
      });
  };

  const startRefreshTokenTimer = () => {
    // parse json object from base64 encoded jwt token
    const jwtBase64 = user.value.jwtToken.split(".")[1];
    const jwtToken = JSON.parse(atob(jwtBase64));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    refreshTokenTimeout.value = setTimeout(refreshToken, timeout);
  };
  const stopRefreshTokenTimer = () => {
    clearTimeout(refreshTokenTimeout);
  };

  const login = (email, password) => {
    return fetchWrapper
      .post(`${baseUrl}/authenticate`, {
        email,
        password,
      })
      .then((response) => {
        user.value = response;
        // update pinia state
        startRefreshTokenTimer();
        // store user details and jwt in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user.value));

        // redirect to previous url or default to home page
        router.push(returnUrl.value || "/");
      });
  };

  const logout = () => {
    user.value = null;
    localStorage.removeItem("user");
    localStorage.removeItem("selection");
    stopRefreshTokenTimer();
    router.push({ path: "/login", replace: true });
  };
  if (isAuthenticated.value) {
    startRefreshTokenTimer();
  }
  return { user, isAuthenticated, returnUrl, logout, login };
});
