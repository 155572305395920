<template>
  <div class="flex min-h-full flex-col justify-center py-4">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="text-center text-3xl tracking-tight text-gray-700 font-light">
        Reset your password
      </h2>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-6 px-6 shadow sm:rounded-lg">
        <div class="space-y-4">
          <Input
            label="Password"
            input-type="password"
            v-model="state.resetPassword.password"
          />
          <Input
            label="Confirm password"
            input-type="password"
            v-model="state.resetPassword.confirmPassword"
          />
          <div>
            <button
              class="flex w-full justify-center rounded-md bg-primary py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              @click="resetPassword"
            >
              Reset password
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Input from "../components/shared/Input.vue";
import { reactive, watch, onMounted } from "vue";
import { useUserStore } from "../stores/user";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import { fetchWrapper } from "../helpers/fetch-wrapper";

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();

const state = reactive({
  resetPassword: {
    password: "",
    confirmPassword: "",
    token: "",
  },
});

const resetPassword = () => {
  fetchWrapper
    .post(
      `${import.meta.env.VITE_API_URL}/api/user/reset-password`,
      state.resetPassword
    )
    .then(() => {
      notify(
        {
          group: "notifications",
          title: "Success",
          text: "You can now log in",
        },
        5000
      );
      router.push("/login");
    });
};

onMounted(() => {
  watch(
    () => route.query,
    () => {
      if (route.query.token) {
        state.resetPassword.token = route.query.token;
      } else {
        router.push("/login");
      }
    },
    { immediate: true, deep: true }
  );
});
</script>

<style lang="scss" scoped></style>
