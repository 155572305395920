const getTotalItemPrice = (item, project) => {
  let totalPrice = item.price;
  let salePrice = item.price;
  let projectOptions = project.options.filter(
    (opt) => opt.name.toLowerCase() != "tax"
  );
  projectOptions.forEach((opt) => {
    let selectedOption = opt.items.find((i) => i.value == opt.selectedValue);
    totalPrice += salePrice * selectedOption.priceFactor - salePrice;
  });
  totalPrice *= item.quantity;
  return totalPrice;
};

const getTotalItemCost = (item) => {
  let totalCost = item.cost;
  totalCost *= item.quantity;
  return totalCost;
};

const getTotalProjectCost = (project) => {
  var totalCost = 0;
  project.resultItems.forEach((i) => {
    totalCost += getTotalItemCost(i);
    i.accessories
      .filter((a) => a.quantity > 0)
      .forEach((a) => {
        totalCost += i.quantity * getTotalItemCost(a);
      });
  });
  return totalCost;
};

const getTotalProjectPrice = (project) => {
  var totalPrice = 0;
  project.resultItems.forEach((i) => {
    totalPrice += getTotalItemPrice(i, project);
    i.accessories
      .filter((a) => a.quantity > 0)
      .forEach((a) => {
        totalPrice += i.quantity * getTotalItemPrice(a, project);
      });
  });
  return totalPrice;
};

const getTotalProjectPriceWithDiscount = (project) => {
  let totalPrice = getTotalProjectPrice(project);
  let discount = totalPrice * (project.discount / 100);
  return totalPrice - discount;
};

const getTotalProjectPriceWithTax = (project) => {
  let taxOption = project.options.find(
    (opt) => opt.name.toLowerCase() == "tax"
  );
  let selectedTax = taxOption.items.find(
    (i) => i.value == taxOption.selectedValue
  );
  let tax = isNaN(selectedTax.priceFactor) ? 1 : selectedTax.priceFactor;
  let totalPrice = getTotalProjectPriceWithDiscount(project);
  totalPrice = totalPrice * tax;
  return totalPrice;
};

const toCurrencyString = (val) => {
  return val.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export {
  getTotalItemPrice,
  getTotalProjectPrice,
  getTotalProjectPriceWithDiscount,
  getTotalProjectPriceWithTax,
  getTotalItemCost,
  getTotalProjectCost,
  toCurrencyString,
};
