<template>
  <div
    v-show="props.show"
    class="w-11/12 md:w-3/4 lg:w-1/2 bg-white divide-y divide-gray-200 drag-modal z-40 rounded-md shadow-lg absolute left-1/2 top-[40%] transform -translate-x-1/2 -translate-y-1/2 border-gray-400 border-2"
    ref="modal"
  >
    <div class="flex items-center">
      <div
        id="modal-handle"
        class="cursor-move p-3 select-none flex justify-between items-center grow"
        @mousedown="startDrag"
      >
        <p class="inline">Ductwork Configurator</p>
      </div>
      <a class="mr-2" @click="emits('close')">
        <XMarkIcon class="h-6 w-6 hover:fill-primary cursor-pointer" />
      </a>
    </div>

    <div class="modal-content p-3">
      <div class="space-y-3">
        <div class="flex items-center">
          <label class="mr-4">Duct Shape:</label>
          <div class="flex gap-4">
            <label
              v-for="ductType in ductworkConfig.ductShape.items"
              :key="ductType"
              class="flex items-center"
            >
              <input
                type="radio"
                v-model="ductworkConfig.ductShape.selectedValue"
                :value="ductType.value"
                @click="clearInputs"
              />
              <span class="pl-1.5">
                {{ ductType.text }}
              </span>
            </label>
          </div>
        </div>

        <div class="flex space-x-2">
          <div class="w-1/2">
            <Dropdown
              label="Duct Type:"
              v-model="ductworkConfig.availableDuctType.ductType.selectedValue"
              :options="availableDuctTypeItems"
            />
          </div>

          <div class="w-1/2">
            <Dropdown
              label="Sub Type:"
              v-if="selectedDuctType.subItems"
              v-model="selectedDuctType.subItems.selectedValue"
              :options="selectedDuctType.subItems.items"
            />
            <Dropdown :select-class="disabled" v-else />
          </div>
        </div>

        <div class="flex space-x-2">
          <template
            v-if="ductworkConfig.ductShape.selectedValue === 'rectangular'"
          >
            <div class="flex w-1/2 space-x-2">
              <div class="w-1/2">
                <LeadingTrailingInput
                  label="Width"
                  trailing-text="mm"
                  input-class="!text-xs !pl-5"
                  input-type="number"
                  placeholder="0"
                  v-model.number="ductworkConfig.availableDuctType.width"
                />
              </div>
              <div class="w-1/2">
                <LeadingTrailingInput
                  label="Height"
                  trailing-text="mm"
                  input-class="!text-xs !pl-5"
                  input-type="number"
                  placeholder="0"
                  v-model.number="ductworkConfig.availableDuctType.height"
                />
              </div>
            </div>
          </template>

          <div v-else class="w-1/2">
            <LeadingTrailingInput
              label="Diameter"
              trailing-text="mm"
              input-class="!text-xs !pl-5"
              input-type="number"
              class="w-full"
              placeholder="0"
              v-model.number="ductworkConfig.availableDuctType.diameter"
            />
          </div>
          <div class="flex space-x-2 w-1/2">
            <div class="w-1/2">
              <LeadingTrailingInput
                label="Length"
                trailing-text="m"
                input-class="!text-xs !pl-5"
                input-type="number"
                placeholder="0"
                v-model.number="ductworkConfig.availableDuctType.length"
              />
            </div>
            <div class="w-1/2">
              <LeadingTrailingInput
                label="Quantity"
                trailing-text="unit(s)"
                input-class="!text-xs !pl-5"
                input-type="number"
                placeholder="0"
                v-model.number="ductworkConfig.availableDuctType.quantity"
              />
            </div>
          </div>
        </div>
      </div>

      <Button
        @click="addItem"
        button-text="Add"
        button-size="sm"
        class="bg-primary text-white px-4 py-2 my-3 focus:ring-primary"
      />

      <!-- <Datatable :columns="headers" :items="ductworkConfig.addedDuctTypes" :loading="false"
        class="min-h-[100px] max-h-[450px]">
      </Datatable> -->

      <EasyDataTable
        ref="ductworkDataTable"
        :headers="headers"
        :items="ductworkConfig.addedDuctTypes"
        theme-color="#F22534"
        :loading="false"
        rows-per-page="5"
        hide-rows-per-page="true"
        class="min-h-[100px] max-h-[550px]"
        header-text-direction="center"
        body-text-direction="center"
        table-class-name="customize-table"
        empty-message="- use the above inputs to add ductwork sections-"
        buttons-pagination="true"
      >
        <template #item-duct_type="item">
          {{ getSelectedDuctType(item).text }} ({{ capitalize(item.shape) }})
        </template>
        <template #item-sub_type="item">
          {{ getSelectedSubDuctType(getSelectedDuctType(item)).text }}
        </template>
        <template #item-operations="item">
          <div class="text-center">
            <Button
              button-color="primary"
              button-text="button_text_delete"
              button-size="xs"
              @click="removeItem(item.id)"
            />
          </div>
        </template>
      </EasyDataTable>

      <div class="flex gap-4 justify-end">
        <Button
          @click="applyChanges"
          button-text="Apply"
          button-size="sm"
          class="bg-primary text-white px-4 py-2 my-3 focus:ring-primary"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { onMounted, ref, computed, reactive, watch } from "vue";
import Dropdown from "../shared/Dropdown.vue";
import Input from "../shared/Input.vue";
import { cloneDeep, capitalize } from "lodash";
import Button from "../shared/Button.vue";
import "vue3-easy-data-table/dist/style.css";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import LeadingTrailingInput from "../shared/LeadingTrailingInput.vue";
import { useSelectionStore } from "../../stores/selection";
const { selectionInformation } = useSelectionStore();

gsap.registerPlugin(Draggable);

const modal = ref(null);

const props = defineProps(["show"]);
const emits = defineEmits(["close", "applyChanges"]);

const wrapper = document.body;

const state = reactive({});

onMounted(() => {
  let isDragging = false;
  let offsetX = 0;
  let offsetY = 0;

  const modalHandle = document.getElementById("modal-handle");

  Draggable.create(modal.value, {
    type: "x,y",
    trigger: modalHandle, // Initialise the Draggable instance for the handle element only
    edgeResistance: 0.95,
    bounds: wrapper,

    onDragStart: () => {
      isDragging = true;
    },
    onDrag: () => {
      if (!isDragging) return;

      modal.value.style.transform = `translate(${offsetX + Draggable.x}px, ${
        offsetY + Draggable.y
      }px)`;
    },
    onRelease: () => {
      isDragging = false;
      offsetX += Draggable.x;
      offsetY += Draggable.y;
    },
  });

  watch(
    () => {
      return ductworkConfig.value.ductShape.selectedValue;
    },
    () => {
      ductworkConfig.value.availableDuctType.ductType.selectedValue = -1;
    },
    { immediate: false }
  );
});

const availableDuctTypeItems = computed(() => {
  return ductworkConfig.value.availableDuctType.ductType.items.filter(
    (i) => !i.shape || i.shape == ductworkConfig.value.ductShape.selectedValue
  );
});

const applyChanges = () => {
  emits("applyChanges");
};

const ductworkDataTable = ref();
// console.log(ductworkDataTable.value?.isFirstPage) look to ProjectListModal with how to create custom paginatin controls to match the rest of the project

const ductworkConfig = computed(() => {
  return selectionInformation.ductworkConfiguration;
});

const startDrag = (event) => {
  event.preventDefault();
};

const selectedDuctType = computed(() => {
  return getSelectedDuctType(ductworkConfig.value.availableDuctType);
});

const getSelectedSubDuctType = (info) => {
  return info.subItems.items.find(
    (d) => d.value == info.subItems.selectedValue
  );
};

const getSelectedDuctType = (info) => {
  return info.ductType.items.find(
    (d) => d.value == info.ductType.selectedValue
  );
};

const headers = [
  { text: "Duct Type", value: "duct_type", width: 150 },
  { text: "Sub Type", value: "sub_type", width: 75 },
  { text: "Width", value: "width" },
  { text: "Height", value: "height" },
  { text: "Diameter", value: "diameter" },
  { text: "Length", value: "length" },
  { text: "Quantity", value: "quantity" },
  { text: "", value: "operations", width: 66 },
];

const addItem = () => {
  let newItem = cloneDeep(ductworkConfig.value.availableDuctType);
  newItem.shape = ductworkConfig.value.ductShape.selectedValue;
  newItem.width = ductworkConfig.value.availableDuctType.width;
  newItem.height = ductworkConfig.value.availableDuctType.height;
  newItem.diameter = ductworkConfig.value.availableDuctType.diameter;

  newItem.id = Math.round(Math.random() * 1000);
  ductworkConfig.value.addedDuctTypes.unshift(newItem);

  // reset values for next input
  ductworkConfig.value.availableDuctType.width = "";
  ductworkConfig.value.availableDuctType.height = "";
  ductworkConfig.value.availableDuctType.diameter = "";
  ductworkConfig.value.availableDuctType.length = "";
  ductworkConfig.value.availableDuctType.quantity = "";
};

const removeItem = (itemIdToRemove) => {
  ductworkConfig.value.addedDuctTypes =
    ductworkConfig.value.addedDuctTypes.filter(
      (item) => item.id !== itemIdToRemove
    );
};

const clearInputs = () => {
  // clear any width/height/diameter values entered upon user switching duct shape
  ductworkConfig.value.availableDuctType.width = "";
  ductworkConfig.value.availableDuctType.height = "";
  ductworkConfig.value.availableDuctType.diameter = "";
};
</script>

<style lang="scss">
label {
  @apply text-sm font-medium;
}

.vue3-easy-data-table__header tr th {
  font-weight: 500 !important;
  font-size: 13px !important;
}

.vue3-easy-data-table__body tr td {
  font-weight: 400 !important;
  font-size: 13px !important;
}

.customize-table {
  --easy-table-header-item-padding: 4px !important;
  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1000;
}

.buttons-pagination .item.button.active {
  background-color: none !important;
  background-image: none;
}

.buttons-pagination {
  column-gap: 0 !important;
}

input[type="radio"] {
  background-color: white;
  color: #f22534;
  border: 0.15em solid #bebfc0;
  border-radius: 50%;
}

[type="radio"]:focus {
  --tw-ring-color: #f22534 !important;
}
</style>
