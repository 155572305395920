<template>
  <div v-if="showLoading" class="absolute top-0 bottom-0 left-0 right-0 backdrop-blur-1 z-50 h-full min-h-[66vh]">
    <div class="m-auto block w-fit relative top-[48vh]">
      <svg class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500"
        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor" />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill" />
      </svg>
    </div>
  </div>
  <template v-if="project.projectId">
    <div class="grid grid-cols-2 xs:px-2">
      <div class="col-span-1 xs:col-span-2">
        <div class="mr-2 mt-2">
          <p class="text-xs text-gray-500">
            <T keyName="label_current_revision" />
            {{ project.revision }} -
            <T keyName="label_project_last_saved" />
            <T v-if="!project.lastModified" keyName="label_never" />
            <template v-else>
              {{ projectLastModified }}
            </template>
          </p>
        </div>
      </div>
      <div class="col-span-1 xs:col-span-2">
        <div v-if="!globalStore.isMobile" class="flex justify-end mb-2">
          <div class="flex">
            <Button button-color="primary" button-text="button_text_projects" button-size="xs" class="ml-3"
              @click="projectsButtonClick" />
            <Button button-color="primary" button-text="button_text_new" button-size="xs" class="ml-3"
              @click="newProject" />

            <Button button-color="primary" button-text="button_text_save" button-size="xs" class="ml-3"
              id="button-save-project-prompt" @click="saveClick" />
            <Popover popover-id="popover-save-project" trigger-id="button-save-project-prompt" placement="bottom"
              trigger-mode="none" :open="state.incrementRevisionOpen" class="w-56">
              <template #content>
                <div class="grid grid-cols-2">
                  <div class="col-span-2 mb-2">
                    <T keyName="text_save_project_revision_confirm" />
                  </div>
                  <div class="col-span-1">
                    <Button button-color="primary" button-text="button_text_new_revision" button-size="xs" class="w-max"
                      id="button-save-project-prompt" @click="saveProject(true)" />
                  </div>
                  <div class="col-span-1 flex justify-end">
                    <Button button-color="primary" button-text="button_text_overwrite" button-size="xs"
                      id="button-save-project-prompt" @click="saveProject(false)" />
                  </div>
                </div>
              </template>
            </Popover>

            <Button :disabled="!projectStore.saved || state.isPrinting" button-color="primary"
              :button-text="printButtonText" button-size="xs" class="ml-3"
              @click="state.generatePdfOpen = !state.generatePdfOpen" id="button-print-project-prompt">
              <template v-if="state.isPrinting" #pre-content>
                <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-white animate-spin"
                  viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB" />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor" />
                </svg>
              </template>
            </Button>
            <Popover popover-id="popover-print-project" trigger-id="button-print-project-prompt" placement="bottom"
              trigger-mode="none" :open="state.generatePdfOpen" class="w-32">
              <template #content>
                <div class="grid grid-cols-2">
                  <div class="col-span-2">
                    <Checkbox label-class="font-normal text-xs" label="Quotation"
                      v-model="state.printOptions.quotation" />
                  </div>
                  <div class="col-span-2">
                    <Checkbox label-class="font-normal text-xs" label="Datasheets"
                      v-model="state.printOptions.technicalDatasheets" />
                  </div>
                  <div class="col-span-2 mt-2">
                    <Button button-color="primary" button-text="Generate" button-size="xs" class="w-full"
                      @click="printProject">
                    </Button>
                  </div>
                </div>
              </template>
            </Popover>

            <Button :use-as-anchor="true" :href="state.printDownloadUrl" download button-color="primary"
              button-text="button_text_download_pdf" button-size="xs" class="ml-3"
              :class="{ disabled: downloadButtonDisabled }">
            </Button>
          </div>
        </div>
        <div v-else class="grid grid-cols-1 mb-2 mt-2">
          <Button button-color="primary" button-text="button_text_projects" button-size="xs" class="col-span-1 mb-2"
            @click="projectsButtonClick" />
          <Button button-color="primary" button-text="button_text_new" button-size="xs" class="col-span-1 mb-2"
            @click="newProject" />

          <Button button-color="primary" button-text="button_text_save" button-size="xs" class="col-span-1 mb-2"
            id="button-save-project-prompt" @click="saveClick" />
          <Popover popover-id="popover-save-project" trigger-id="button-save-project-prompt" placement="bottom"
            trigger-mode="none" :open="state.incrementRevisionOpen" class="w-56">
            <template #content>
              <div class="grid grid-cols-2">
                <div class="col-span-2 mb-2">
                  <T keyName="text_save_project_revision_confirm" />
                </div>
                <div class="col-span-1">
                  <Button button-color="primary" button-text="button_text_new_revision" button-size="xs" class="w-max"
                    id="button-save-project-prompt" @click="saveProject(true)" />
                </div>
                <div class="col-span-1 flex justify-end">
                  <Button button-color="primary" button-text="button_text_overwrite" button-size="xs"
                    id="button-save-project-prompt" @click="saveProject(false)" />
                </div>
              </div>
            </template>
          </Popover>

          <Button :disabled="!projectStore.saved || state.isPrinting" button-color="primary"
            :button-text="printButtonText" button-size="xs" class="col-span-1 mb-2"
            @click="state.generatePdfOpen = !state.generatePdfOpen" id="button-print-project-prompt">
            <template v-if="state.isPrinting" #pre-content>
              <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-white animate-spin"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor" />
              </svg>
            </template>
          </Button>
          <Popover popover-id="popover-print-project" trigger-id="button-print-project-prompt" placement="bottom"
            trigger-mode="none" :open="state.generatePdfOpen" class="w-32">
            <template #content>
              <div class="grid grid-cols-2">
                <div class="col-span-2">
                  <Checkbox label-class="font-normal text-xs" label="Quotation" v-model="state.printOptions.quotation" />
                </div>
                <div class="col-span-2">
                  <Checkbox label-class="font-normal text-xs" label="Datasheets"
                    v-model="state.printOptions.technicalDatasheets" />
                </div>
                <div class="col-span-2 mt-2">
                  <Button button-color="primary" button-text="Generate" button-size="xs" class="w-full"
                    @click="printProject">
                  </Button>
                </div>
              </div>
            </template>
          </Popover>

          <Button :use-as-anchor="true" :href="state.printDownloadUrl" download button-color="primary"
            button-text="button_text_download_pdf" button-size="xs" class="col-span-1 mb-2"
            :class="{ disabled: downloadButtonDisabled }">
          </Button>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-4 mt-2 xs:px-2">
      <div class="col-span-12">
        <div class="divide-y divide-gray-200 overflow-hidden bg-white shadow rounded-lg">
          <div class="px-4 py-2 text-md rounded-lg">
            <T keyName="card_title_project_details" />
          </div>
          <div class="px-4 py-2">
            <div class="grid grid-cols-12 gap-3">
              <Input class="col-span-3 xs:col-span-12" input-class="text-sm" label="Name" v-model="project.name" />
              <Input class="col-span-3 xs:col-span-12" label="Reference" input-class="text-sm"
                v-model="project.reference" />
              <Dropdown label="Status" color="primary" class="col-span-3 xs:col-span-12" :options="project.status.items"
                v-model="project.status.selectedValue" />
              <Input v-if="showSalePricing" class="col-span-3 xs:col-span-12 disabled" label="Quotation Number"
                input-class="text-sm" v-model="formattedQuotationNumber" readonly />
              <Dropdown v-if="showSalePricing" label="Sales Manager" color="primary" class="col-span-3 xs:col-span-12"
                :options="project.salesManagers.items" v-model="project.salesManagers.selectedValue" />
              <Dropdown v-if="showSalePricing" label="Currency" color="primary" class="col-span-3 xs:col-span-12"
                :options="project.currencyOptions" v-model="project.currency" />
            </div>
          </div>
          <div class="px-4 py-2 text-md">
            <T keyName="card_title_customer_details" />
          </div>
          <div class="px-4 py-2">
            <div class="grid grid-cols-12 gap-3">
              <Input class="col-span-3 xs:col-span-12" input-class="text-sm" label="MEP Contractor"
                v-model="project.mepContractor" />
              <Input class="col-span-3 xs:col-span-12" label="Mobile" input-class="text-sm" v-model="project.mepMobile" />
              <Input class="col-span-3 xs:col-span-12" label="Email" input-class="text-sm" v-model="project.mepEmail" />
              <Input class="col-span-3 xs:col-span-12" label="Contact Person" input-class="text-sm"
                v-model="project.mepContactPerson" />
              <Dropdown label="Location" color="primary" class="col-span-3 xs:col-span-12" :select-class="locationClass"
                :options="project.locations.items" v-model="project.locations.selectedValue" />
              <Input class="col-span-3 xs:col-span-12" label="Client" input-class="text-sm" v-model="project.client" />
              <Input class="col-span-3 xs:col-span-12" label="Consultant" input-class="text-sm"
                v-model="project.consultant" />
              <Input class="col-span-3 xs:col-span-12" label="Main Contractor" input-class="text-sm"
                v-model="project.mainContractor" />
            </div>
          </div>
          <div class="px-4 py-2 text-md">
            <T keyName="card_title_notes" />
          </div>
          <div class="p-4">
            <div class="grid grid-cols-12 gap-3">
              <div class="col-span-12">
                <TextArea v-model="project.notes" rows="4" />
              </div>
            </div>
          </div>
          <template v-if="showSalePricing">
            <div class="px-4 py-2 text-md">
              <T keyName="card_title_conditions_sale" />
            </div>
            <div class="p-4">
              <div class="grid grid-cols-12 gap-3">
                <template v-for="option in project.options">
                  <Dropdown :label="option.name" color="primary" class="col-span-3 xs:col-span-12" :options="option.items"
                    v-model="option.selectedValue" />
                </template>
                <LeadingTrailingInput class="col-span-3 xs:col-span-12" v-if="showDiscount" color="primary"
                  label="Discount" trailing-text="%" label-class="mb-1" input-class="!pl-3" v-model="project.discount" />
              </div>
            </div>
          </template>
          <template v-if="showSalePricing">
            <div v-if="showCostPricing" class="px-4 py-2">
              <div class="grid grid-cols-12">
                <div class="col-span-4 col-start-9 xs:col-span-12 text-right text-sm">
                  Total Cost ({{ projectCurrency.value }}):
                  <span class="font-medium ml-2"> {{ totalProjectCost }}</span>
                </div>
              </div>
            </div>
            <div class="px-4 py-2">
              <div class="grid grid-cols-12">
                <div class="col-span-4 col-start-9 xs:col-span-12 text-right text-sm">
                  Total Price ({{ projectCurrency.value }}):
                  <span class="font-medium ml-2"> {{ totalProjectPrice }}</span>
                </div>
              </div>
            </div>
            <div v-if="showDiscount" class="px-4 py-2">
              <div class="grid grid-cols-12">
                <div class="col-span-4 col-start-9 xs:col-span-12 text-right text-sm">
                  Special Project Discounted Price ({{
                    projectCurrency.value
                  }}):
                  <span class="font-medium ml-2">
                    {{ totalProjectPriceWithDiscount }}</span>
                </div>
              </div>
            </div>
            <div v-if="showSalePricing" class="px-4 py-2">
              <div class="grid grid-cols-12">
                <div class="col-span-4 col-start-9 xs:col-span-12 text-right text-sm">
                  Total Price incl VAT ({{ projectCurrency.value }}):
                  <span class="font-medium ml-2">
                    {{ totalProjectPriceWithTax }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="user.userGroup.isAdmin" class="col-span-12">
        <div class="flex justify-end">
          <Button button-color="primary" button-text="button_text_add_custom_item" button-size="xs"
            @click="addCustomItemClick" />
        </div>
      </div>

      <div class="col-span-12">
        <ProjectItemGrid v-if="state.renderGrid" :items="sortedProjectItems" :grid-columns="projectItemsGridColumns"
          :loading="false" :pagination="state.pagination" @actions-edit-clicked="projectItemEditClicked"
          @actions-view-clicked="projectItemViewClicked" @increase-accessory-quantity="increaseAccessoryQuantity"
          @decrease-accessory-quantity="decreaseAccessoryQuantity" @increase-item-quantity="increaseItemQuantity"
          @decrease-item-quantity="decreaseItemQuantity" @actions-delete-clicked="removeFromProject"
          @accessory-reference-changed="accessoryReferenceChanged" @item-reference-changed="itemReferenceChanged"
          @item-sorted="itemSorted" @item-selected="itemSelected" @set-item-quantity="setItemQuantity" />
      </div>
      <ProjectsListModal :user-list="state.userList" :open="state.projectsOpen" :loading="state.projectListLoading"
        :project-list="state.projectListItems" @on-close="state.projectsOpen = false"
        @server-options-updated="serverOptionsUpdated" :server-options="state.projectListServerOptions"
        :server-items-length="state.projectListLength" @toggle-delete-prompt="projectListToggleDeletePrompt"
        @actions-delete-click="deleteProject" @actions-open-click="openProject" @actions-copy-click="copyProject" />
      <Modal :open="state.addCustomItem.open" @on-close="closeCustomItem" panel-class="!max-w-lg">
        <template #content>
          <div class="grid grid-cols-2">
            <div class="col-span-2">
              <div class="text-md">
                <T keyName="button_text_add_custom_item" />
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-2 mt-2">
            <Dropdown color="primary" v-model="state.addCustomItem.addTo" :options="addToOptions" class="col-span-8"
              label="Item Destination" />
            <Input v-model="state.addCustomItem.newItem.modelDescription" label="Model/Description" class="col-span-12" />
            <Input v-model="state.addCustomItem.newItem.reference" label="Reference" class="col-span-12" />
            <Input input-type="number" v-model="state.addCustomItem.newItem.quantity" label="Quantity"
              class="col-span-4" />
            <template v-if="state.addCustomItem.addTo == 'project'">
              <LeadingTrailingInput v-model="state.addCustomItem.newItem.volume" label="Volume"
                class="col-span-4 col-start-1" color="primary" trailing-text="m³/s" input-class="!pl-3"
                placeholder="0.00" />
              <LeadingTrailingInput v-model="state.addCustomItem.newItem.pressure" label="Pressure" class="col-span-4"
                color="primary" trailing-text="Pa" input-class="!pl-3" placeholder="0" />
            </template>
            <LeadingTrailingInput v-model="state.addCustomItem.newItem.costPrice" label="Cost Price"
              class="col-span-4 col-start-1" input-class="!pl-3" color="primary" trailing-text="AED" placeholder="0.00" />
            <LeadingTrailingInput v-model="state.addCustomItem.newItem.salePrice" label="Sale Price" class="col-span-4"
              color="primary" trailing-text="AED" input-class="!pl-3" placeholder="0.00" />
          </div>
          <div class="col-span-12 mt-4">
            <div class="flex justify-end">
              <Button button-color="primary" button-text="button_text_add" button-size="xs"
                @click="addCustomItemToProject" />
            </div>
          </div>
        </template>
      </Modal>
      <Modal :open="state.showUnsavedWarningModal" @on-close="state.showUnsavedWarningModal = false"
        panel-class="!max-w-sm">
        <template #content>
          <div class="flex justify-center">
            <ExclamationTriangleIcon class="h-16 w-16 text-amber-500" />
          </div>
          <div class="flex justify-center">
            Creating a new project will lose any unsaved changes. <br />
            Do you want to continue?
          </div>
          <div class="flex justify-center mt-4">
            <Button button-color="white" button-text="button_text_yes" button-size="sm" class="text-black mr-8"
              @click="continueNewProject" />
            <Button button-color="white" button-text="button_text_no" button-size="sm" class="text-black"
              @click="cancelNewProject" />
          </div>
        </template>
      </Modal>
    </div>
  </template>
</template>

<script setup>
import Input from "../components/shared/Input.vue";
import { useProjectStore } from "../stores/project";
import { useAuthStore } from "../stores/auth";
import { useUserStore } from "../stores/user";
import ProjectItemGrid from "../components/project/ProjectItemsGrid.vue";
import ProjectsListModal from "../components/project/ProjectsListModal.vue";
import { storeToRefs } from "pinia";
import Button from "../components/shared/Button.vue";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import {
  reactive,
  computed,
  onBeforeMount,
  watch,
  onMounted,
  nextTick,
} from "vue";
import { useRouter } from "vue-router";
import Popover from "../components/shared/Popover.vue";
import { format } from "date-fns";
import utils from "../services/utils";
import Dropdown from "../components/shared/Dropdown.vue";
import { notify } from "notiwind";
import { fetchWrapper } from "../helpers/fetch-wrapper";
import TextArea from "../components/shared/TextArea.vue";
import LeadingTrailingInput from "../components/shared/LeadingTrailingInput.vue";
import * as pricing from "../helpers/pricing";
import router from "../router";
import { useSelectionStore } from "../stores/selection";
import Checkbox from "../components/shared/Checkbox.vue";
import Modal from "../components/shared/Modal.vue";
import { useGlobalStore } from "../stores/global";

const projectStore = useProjectStore();
const { project, saved, blockNextUpdate } = storeToRefs(projectStore);
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);
const userStore = useUserStore();
const selectionStore = useSelectionStore();
const { selectionInformation } = storeToRefs(selectionStore);
const globalStore = useGlobalStore();

const state = reactive({
  showUnsavedWarningModal: false,
  addCustomItem: {
    open: false,
    addTo: "project",
    newItem: {
      modelDescription: "",
      reference: "",
      quantity: 1,
      volume: null,
      pressure: null,
      costPrice: null,
      salePrice: null,
    },
    newItemReset: null,
  },
  generatePdfOpen: false,
  renderGrid: true,
  projectsOpen: false,
  loading: false,
  pagination: {
    currentPage: 1,
    pageLength: 10,
    prevPage: () => { },
    nextPage: () => { },
  },
  projectListServerOptions: {
    page: 1,
    rowsPerPage: 10,
    sortBy: "modifiedDate",
    sortType: "desc",
    filter: "",
    showRevisions: false,
    userId: user.value.id,
  },
  projectListLength: 0,
  userList: [],
  incrementRevisionOpen: false,
  projectListLoading: false,
  projectListItems: [],
  isPrinting: false,
  printDownloadUrl: "",
  printOptions: {
    quotation: true,
    technicalDatasheets: true,
  },
  selectedItem: null,
});

const addToOptions = computed(() => {
  let opts = [
    { text: "Project", value: "project" },
    {
      text: `Selected product (${state.selectedItem ? state.selectedItem.model : "none"
        })`,
      value: "product",
      disabled: state.selectedItem == null,
    },
  ];

  return opts;
});

const itemSelected = (item) => {
  state.selectedItem = !item.selected ? item : null;
};

const addCustomItemClick = () => {
  state.addCustomItem.newItemReset = { ...state.addCustomItem.newItem };
  state.addCustomItem.open = true;
};

const closeCustomItem = () => {
  state.addCustomItem.open = false;
  state.addCustomItem.addTo = "project";
  state.addCustomItem.newItem = { ...state.addCustomItem.newItemReset };
};

const addCustomItemToProject = () => {
  var item = {
    destination:
      state.addCustomItem.addTo == "project"
        ? "project"
        : state.selectedItem.id,
    modelDescription: state.addCustomItem.newItem.modelDescription,
    reference: state.addCustomItem.newItem.reference,
    quantity: state.addCustomItem.newItem.quantity,
    volume: state.addCustomItem.newItem.volume,
    pressure: state.addCustomItem.newItem.pressure,
    costPrice: state.addCustomItem.newItem.costPrice,
    salePrice: state.addCustomItem.newItem.salePrice,
  };

  projectStore.addCustomItemToProject(item).then(() => {
    closeCustomItem();
    notify(
      {
        group: "notifications",
        title: "Success",
        text: "Custom item was added",
      },
      5000
    );
  });
};

const projectCurrency = computed(() => {
  return project.value.currencyOptions.find(
    (c) => c.value == project.value.currency
  );
});

const showLoading = computed(() => {
  return state.loading || projectStore.busy;
});

const showSalePricing = computed(() => {
  return user.value.userGroup.permissions.pricing.showSalePricing;
});

const showCostPricing = computed(() => {
  return user.value.userGroup.permissions.pricing.showCostPricing;
});

const sortedProjectItems = computed(() => {
  const sortedItems = project.value.resultItems.sort((a, b) => {
    return a.order - b.order;
  });
  return sortedItems;
});

const arrayMove = (arr, oldIndex, newIndex) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

const rerenderGrid = () => {
  nextTick().then(() => {
    state.renderGrid = false;
    nextTick().then(() => {
      state.renderGrid = true;
    });
  });
};

const itemSorted = (evt) => {
  let cloneSortedItems = [...sortedProjectItems.value];
  cloneSortedItems = arrayMove(cloneSortedItems, evt.oldIndex, evt.newIndex);
  let resortItems = [];
  for (let index = 0; index < cloneSortedItems.length; index++) {
    cloneSortedItems[index].order = index + 1;
    let projectItemIndex = project.value.resultItems.findIndex(
      (i) => i.id == cloneSortedItems[index].id
    );
    project.value.resultItems[projectItemIndex].order = index + 1;
    resortItems.push({
      id: cloneSortedItems[index].id,
      order: cloneSortedItems[index].order,
    });
  }
  projectStore.sortItems(resortItems);
  rerenderGrid();
  console.log(evt);
};

const projectChanges = computed(() => {
  let watchObject = {
    name: project.value.name,
    reference: project.value.reference,
    status: project.value.status.selectedValue,
    salesManager: project.value.salesManagers.selectedValue,
    currency: project.value.currency,
    mepContractor: project.value.mepContractor,
    mepMobile: project.value.mepMobile,
    mepEmail: project.value.mepEmail,
    mepContactPerson: project.value.mepContactPerson,
    location: project.value.locations.selectedValue,
    client: project.value.client,
    consultant: project.value.consultant,
    mainContractor: project.value.mainContractor,
    notes: project.value.notes,
    options: project.value.options.map((op) => op.selectedValue),
    discount: project.value.discount,
  };
  return watchObject;
});

watch(
  () => projectChanges.value,
  () => {
    if (!blockNextUpdate.value) {
      projectStore.updateProjectStatus(false);
      state.printDownloadUrl = "";
    } else {
      blockNextUpdate.value = false;
    }
  },
  { immediate: false }
);

const openProject = (projectItem) => {
  state.loading = true;
  state.projectsOpen = false;
  projectStore
    .openProject(
      projectItem.projectId,
      projectItem.projectRevision,
      authStore.user.id
    )
    .then(() => {
      rerenderGrid();
      state.printDownloadUrl = "";
      state.loading = false;
    });
};

const copyProject = (projectItem) => {
  state.loading = true;
  state.projectOpen = false;
  projectStore
    .copyProject(
      projectItem.projectId,
      projectItem.projectRevision,
      authStore.user.id
    )
    .then(() => {
      state.loading = false;
      state.projectsOpen = false;
      notify(
        {
          group: "notifications",
          title: "Success",
          text: "Project was copied and opened",
        },
        5000
      );
    });
};

const projectDiscount = computed(() => {
  return project.value.discount;
});

watch(
  () => projectDiscount.value,
  () => {
    if (
      projectDiscount.value >
      user.value.userGroup.permissions.pricing.maximumDiscount
    ) {
      project.value.discount = 0;
    }

    console.log(projectDiscount.value);
  }
);

const formattedQuotationNumber = computed(() => {
  if (
    project.value.quotationNumber &&
    project.value.quotationNumber.length > 0
  ) {
    return project.value.revision > 0
      ? `${project.value.quotationNumber}R${project.value.revision}`
      : project.value.quotationNumber;
  } else {
    return "";
  }
});

const totalProjectCost = computed(() => {
  var totalCost = pricing.getTotalProjectCost(project.value);
  totalCost = totalCost * projectCurrency.value.conversionRate;
  return pricing.toCurrencyString(totalCost, projectCurrency.value.value);
});

const totalProjectPrice = computed(() => {
  var totalPrice = pricing.getTotalProjectPrice(project.value);
  totalPrice = totalPrice * projectCurrency.value.conversionRate;
  return pricing.toCurrencyString(totalPrice, projectCurrency.value.value);
});

const totalProjectPriceWithDiscount = computed(() => {
  var totalPrice = pricing.getTotalProjectPriceWithDiscount(project.value);
  totalPrice = totalPrice * projectCurrency.value.conversionRate;
  return pricing.toCurrencyString(totalPrice, projectCurrency.value.value);
});

const totalProjectPriceWithTax = computed(() => {
  var totalPrice = pricing.getTotalProjectPriceWithTax(project.value);
  totalPrice = totalPrice * projectCurrency.value.conversionRate;
  return pricing.toCurrencyString(totalPrice, projectCurrency.value.value);
});

const showDiscount = computed(() => {
  return user.value.userGroup.permissions.pricing.maximumDiscount > 0;
});

const cancelNewProject = () => {
  state.showUnsavedWarningModal = false;
};

const continueNewProject = () => {
  state.showUnsavedWarningModal = false;
  state.loading = true;
  projectStore.newProject().then(() => {
    state.loading = false;
  });
};

const newProject = () => {
  if (!projectStore.saved) {
    state.showUnsavedWarningModal = true;
  } else {
    state.loading = true;
    projectStore.newProject().then(() => {
      state.loading = false;
    });
  }
};

const locationClass = computed(() => {
  return project.value.quotationNumber != null &&
    project.value.quotationNumber.length > 0
    ? "disabled"
    : "";
});

const itemReferenceChanged = (item, value) => {
  let projectItem = project.value.resultItems.find((i) => i.id == item.id);
  projectItem.reference = value;
};

const accessoryReferenceChanged = (item, accessory, value) => {
  let projectItem = project.value.resultItems.find((i) => i.id == item.id);
  let accessoryItem = projectItem.accessories.find(
    (i) => i.guid == accessory.guid
  );
  accessoryItem.reference = value;
};

const deleteProject = (projectItem) => {
  state.projectListLoading = true;
  projectStore
    .deleteProject(
      projectItem.projectId,
      projectItem.projectRevision,
      authStore.user.id
    )
    .then(() => {
      getProjectList();
    });
};

const removeFromProject = (item) => {
  state.loading = true;
  projectStore
    .removeFromProject(item)
    .then(() => {
      rerenderGrid();
      state.loading = false;
    })
    .then(() => {
      notify(
        {
          group: "notifications",
          title: "Success",
          text: "Item was removed from project",
        },
        5000
      );
    });
};

const projectLastModified = computed(() => {
  return format(new Date(project.value.lastModified), "dd/MM/yyyy HH:mm:ss");
});

const projectListToggleDeletePrompt = (item) => {
  let projectListItem = state.projectListItems.find(
    (i) => i.projectId == item.projectId
  );
  projectListItem.showDeletePrompt = !projectListItem.showDeletePrompt;
};

const saveProject = (incrementRevision) => {
  state.loading = true;
  projectStore
    .saveProject(projectStore.project, incrementRevision, authStore.user.id)
    .then(() => {
      state.loading = false;
      state.incrementRevisionOpen = false;
      notify(
        {
          group: "notifications",
          title: "Success",
          text: "Project saved successfully",
        },
        5000
      );
    });
};

const saveClick = () => {
  if (formattedQuotationNumber.value.length > 0) {
    state.incrementRevisionOpen = !state.incrementRevisionOpen;
  } else {
    saveProject(false);
  }
};

const printClick = () => { };

const projectsButtonClick = () => {
  getProjectList();
  state.projectsOpen = !state.projectsOpen;
};

const serverOptionsUpdated = (options) => {
  state.projectListServerOptions = options;
  getProjectList();
};

const projectItemEditClicked = (item) => {
  let projectItem = project.value.resultItems.find((i) => i.id == item.id);
  projectItem.editing = !projectItem.editing;
  if (!projectItem.editing) {
    state.loading = true;
    projectStore.updateItem(item).then(() => {
      state.loading = false;
    });
  }
};

const projectItemViewClicked = (item) => {
  router.push({
    name: "technical",
    params: { referrer: "project", id: item.id },
  });
};

const increaseItemQuantity = (item) => {
  let projectItem = project.value.resultItems.find((i) => i.id == item.id);
  projectItem.quantity++;
};

const decreaseItemQuantity = (item) => {
  let projectItem = project.value.resultItems.find((i) => i.id == item.id);
  if (projectItem.quantity > 1) projectItem.quantity--;
};

const setItemQuantity = (item, evt) => {
  let projectItem = project.value.resultItems.find((i) => i.id == item.id);
  if (evt.length > 0) projectItem.quantity = parseInt(evt);
};

const getProjectList = () => {
  state.projectListLoading = true;
  fetchWrapper
    .post(
      `${import.meta.env.VITE_API_URL}/api/project/get-projects`,
      state.projectListServerOptions
    )
    .then((response) => {
      state.projectListItems = response.projectList;
      state.projectListLoading = false;
      state.projectListLength = response.totalCount;
      state.pagination.currentPage = state.projectListServerOptions.page;
    });
};

const getUserList = (options = null) => {
  userStore.getUsers().then((response) => {
    state.userList = response;
  });
};

const decreaseAccessoryQuantity = (item, accessory) => {
  let projectItem = project.value.resultItems.find((i) => i.id == item.id);
  let itemAccessory = projectItem.accessories.find(
    (a) => a.guid == accessory.guid
  );
  if (itemAccessory.quantity > 0) {
    itemAccessory.quantity--;
  }
};

const increaseAccessoryQuantity = (item, accessory) => {
  let projectItem = project.value.resultItems.find((i) => i.id == item.id);
  let itemAccessory = projectItem.accessories.find(
    (a) => a.guid == accessory.guid
  );
  itemAccessory.quantity++;
};

const projectItemsGridColumns = computed(() => {
  let columns = [
    {
      value: "model",
      text: "Model",
      fixed: !globalStore.isMobile,
      width: 300,
    },
    {
      value: "reference",
      text: "Reference",
      width: 200,
    },
    {
      value: "quantity",
      text: "Quantity",
      width: 160,
    },
    {
      value: "cost",
      text: `Cost Price (${projectCurrency.value.value})`,
      width: 140,
    },
    {
      value: "price",
      text: `Unit Price (${projectCurrency.value.value})`,
      width: 140,
    },
    {
      value: "totalPrice",
      text: `Total Price (${projectCurrency.value.value})`,
      width: 150,
    },
    {
      value: "volumeFlow",
      text: "Flow",
      width: 120,
    },
    {
      value: "staticPressure",
      text: "Pressure",
      width: 120,
    },
    {
      value: "velocity",
      text: "Velocity (m/s)",
      width: 130,
    },
    {
      value: "motorRating",
      text: "Motor Power (kW)",
      width: 110,
    },

    {
      value: "totalEfficiency",
      text: "Tot Eff (%)",
      width: 110,
    },
    {
      value: "soundLevel",
      text: "LpA dB(A)",
      width: 110,
    },
    {
      value: "speed",
      text: "Speed (rpm)",
      width: 120,
    },
  ];
  if (!showCostPricing.value) {
    columns = columns.filter((c) => c.value != "cost");
  }
  if (!showSalePricing.value) {
    columns = columns.filter(
      (c) => c.value != "price" && c.value != "totalPrice"
    );
  }

  return columns;
});

const printProject = () => {
  state.printDownloadUrl = "";
  state.generatePdfOpen = false;
  state.isPrinting = true;

  fetchWrapper
    .post(`${import.meta.env.VITE_API_URL}/api/print/print`, {
      id: projectStore.project.projectId,
      printType: "project",
      referrer: "project",
      culture: "en",
      filename: projectStore.project.name.trim(),
      printOptions: { ...state.printOptions },
    })
    .then((response) => {
      state.printDownloadUrl = response.filename;
      state.isPrinting = false;
    });
};

const printButtonText = computed(() => {
  return state.isPrinting
    ? "button_text_generating_pdf"
    : "button_text_generate_pdf";
});

const downloadButtonDisabled = computed(() => {
  return state.printDownloadUrl.length == 0 || state.isPrinting;
});

onBeforeMount(() => {
  if (user.value.userGroup.permissions.project.showAllProjects) {
    getUserList();
  }
});

onMounted(() => { });
</script>
