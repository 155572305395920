<template>
  <div class="flex min-h-full flex-col justify-center py-4 px-0 xs:px-2">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="text-center text-3xl tracking-tight text-gray-700 font-light">
        Create an account
      </h2>
    </div>

    <div class="mt-8 mx-auto w-full max-w-2xl">
      <div class="bg-white py-6 px-6 shadow">
        <div class="grid grid-cols-2 gap-2">
          <Input
            class="col-span-1 xs:col-span-2"
            label="Email address"
            v-model="state.registration.email"
            :validation="true"
            :is-valid="state.validation.emailValid"
            :validation-text="state.validation.emailInvalidText"
            @blur="emailValid()"
          />

          <Input
            class="col-span-1 xs:col-span-2"
            label="Confirm email address"
            v-model="state.registration.confirmEmail"
            :validation="true"
            :is-valid="state.validation.confirmEmailValid"
            :validation-text="state.validation.confirmEmailInvalidText"
            @blur="confirmEmailValid()"
          />

          <Input
            class="col-span-1 xs:col-span-2"
            label="Password"
            input-type="password"
            v-model="state.registration.password"
            :validation="true"
            :is-valid="state.validation.passwordValid"
            :validation-text="state.validation.passwordInvalidText"
            @blur="passwordValid()"
          />

          <Input
            class="col-span-1 xs:col-span-2"
            label="Confirm password"
            input-type="password"
            v-model="state.registration.confirmPassword"
            :validation="true"
            :is-valid="state.validation.confirmPasswordValid"
            :validation-text="state.validation.confirmPasswordInvalidText"
            @blur="confirmPasswordValid()"
          />
          <Dropdown
            class="col-span-1 xs:col-span-2"
            v-model="state.registration.title"
            :options="state.titles"
            label="Title"
            color="primary"
          />
          <Input
            class="col-span-1 xs:col-span-2"
            label="First name"
            v-model="state.registration.firstName"
            :validation="true"
            :is-valid="state.validation.firstNameValid"
            :validation-text="state.validation.firstNameInvalidText"
            @blur="firstNameValid()"
          />
          <Input
            class="col-span-1 xs:col-span-2"
            label="Last name"
            v-model="state.registration.lastName"
            :validation="true"
            :is-valid="state.validation.lastNameValid"
            :validation-text="state.validation.lastNameInvalidText"
            @blur="lastNameValid()"
          />
          <Input
            class="col-span-1 xs:col-span-2"
            label="Mobile number"
            v-model="state.registration.mobileNumber"
          />
          <Input
            class="col-span-1 xs:col-span-2"
            label="Company"
            v-model="state.registration.company"
          />
          <Input
            class="col-span-1 xs:col-span-2"
            label="Job title"
            v-model="state.registration.jobTitle"
          />

          <div class="col-span-1 xs:col-span-2 col-start-2">
            <button
              :disabled="state.loading"
              class="mt-1 flex w-full justify-center bg-primary py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              @click="doSignUp"
            >
              Create account
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Input from "../components/shared/Input.vue";
import Checkbox from "../components/shared/Checkbox.vue";
import Dropdown from "../components/shared/Dropdown.vue";
import { reactive, computed, watch } from "vue";
import { useUserStore } from "../stores/user";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { notify } from "notiwind";

const router = useRouter();
const userStore = useUserStore();

const state = reactive({
  loading: false,
  registration: {
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
    title: "",
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    mobileNumber: "",
  },
  validation: {
    firstNameValid: true,
    firstNameInvalidText: "First name is required",
    lastNameValid: true,
    lastNameInvalidText: "Last name is required",
    emailValid: true,
    emailInvalidText: "Must be a valid email",
    confirmEmailValid: true,
    confirmEmailInvalidText: "Emails must match",
    passwordValid: true,
    passwordInvalidText: "Password must be at least 8 characters",
    confirmPasswordValid: true,
    confirmPasswordInvalidText: "Passwords must match",
  },
  titles: [
    { text: "Please select", value: "" },
    { text: "Mr", value: "Mr" },
    { text: "Mrs", value: "Mrs" },
    { text: "Miss", value: "Miss" },
    { text: "Ms", value: "Ms" },
    { text: "Dr", value: "Dr" },
  ],
});

const emailValid = () => {
  if (!validateEmail(state.registration.email)) {
    state.validation.emailValid = false;
  } else {
    state.validation.emailValid = true;
  }

  if (state.registration.confirmEmail.length > 0) {
    let confirmEmailValid =
      state.registration.confirmEmail == state.registration.email;
    state.validation.confirmEmailValid = confirmEmailValid;
  }

  return state.validation.emailValid;
};

const confirmEmailValid = () => {
  let confirmEmailValid =
    state.registration.confirmEmail == state.registration.email;
  state.validation.confirmEmailValid = confirmEmailValid;
  return confirmEmailValid;
};

const firstNameValid = () => {
  if (state.registration.firstName.length == 0) {
    state.validation.firstNameValid = false;
  } else {
    state.validation.firstNameValid = true;
  }
  return state.validation.firstNameValid;
};

const lastNameValid = () => {
  if (state.registration.lastName.length == 0) {
    state.validation.lastNameValid = false;
  } else {
    state.validation.lastNameValid = true;
  }
  return state.validation.lastNameValid;
};

const passwordValid = () => {
  let passwordValid = state.registration.password.length >= 8;
  state.validation.passwordValid = passwordValid;
  if (state.registration.confirmPassword.length > 0) {
    let confirmPasswordValid =
      state.registration.confirmPassword == state.registration.password;
    state.validation.confirmPasswordValid = confirmPasswordValid;
  }
  return state.validation.passwordValid;
};

const confirmPasswordValid = () => {
  let confirmPasswordValid =
    state.registration.confirmPassword == state.registration.password;
  state.validation.confirmPasswordValid = confirmPasswordValid;
  return confirmPasswordValid;
};

const validateRegistration = () => {
  firstNameValid();
  lastNameValid();
  emailValid();
  confirmEmailValid();
  passwordValid();
  confirmPasswordValid();
  return (
    state.validation.firstNameValid &&
    state.validation.lastNameValid &&
    state.validation.emailValid &&
    state.validation.confirmEmailValid &&
    state.validation.passwordValid &&
    state.validation.confirmPasswordValid
  );
};

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const doSignUp = () => {
  if (validateRegistration()) {
    state.loading = true;
    userStore
      .register(state.registration)
      .then(() => {
        notify(
          {
            group: "notifications",
            title: "Account created",
            text: "Please confirm your email address to log in",
          },
          5000
        );
        router.push("/login");
      })
      .catch((err) => {
        state.loading = false;
        notify(
          {
            group: "notifications",
            title: "Error",
            text: err,
            type: "error",
          },
          5000
        );
      });
  }
};
</script>

<style lang="scss" scoped></style>
