<template>
  <Disclosure as="nav" class="bg-white" v-slot="{ open }">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between">
        <div class="flex">
          <div class="flex flex-shrink-0 items-center w-28">
            <img class="block h-auto w-28 bg-white p-1" src="../../assets/logo.svg" alt="Your Company" />
          </div>
          <div class="hidden sm:-my-px sm:ml-10 sm:flex sm:space-x-4 uppercase">
            <template v-for="item in state.navigation">
              <RouterLink v-if="!item.auth ||
                (item.auth &&
                  userStore.isAuthenticated &&
                  (!item.admin ||
                    (userStore.user.userGroup &&
                      item.admin == userStore.user.userGroup.isAdmin)))
                " :key="item.name" class="hover:text-red inline-flex items-center px-4 py-2 text-sm font-semibold my-6"
                active-class="text-red-500" :to="`/${item.href}`">{{ item.name }}
                <template v-if="item.href == 'project'">
                  <CheckCircleIcon v-if="projectStore.saved" class="h-5 w-5 ml-2 text-green-400" aria-hidden="true" />
                  <ExclamationCircleIcon v-else class="h-5 w-5 ml-2 text-yellow-400" aria-hidden="true" />
                </template>
              </RouterLink>
            </template>
          </div>
        </div>
        <div class="hidden sm:ml-6 sm:flex sm:items-center">
          <!-- <button
            v-if="isAuthenticated"
            type="button"
            class="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button> -->

          <!-- Profile dropdown -->
          <Menu as="div" class="relative ml-3">
            <template v-if="userStore.isAuthenticated">
              <MenuButton
                class="flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2">
                <span class="sr-only">Open user menu</span>
                <img class="h-8 w-8"
                  :src="`https://ui-avatars.com/api/?background=f22534&color=fff&name=${user.firstName}+${user.lastName}&rounded=true&size=48`"
                  alt="" />
              </MenuButton>

              <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right bg-white rounded-md py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-for="item in state.userNavigation" :key="item.name" v-slot="{ active }">
                  <a v-if="!item.onClick" :href="item.href" :class="[
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700',
                  ]">{{ item.name }}</a>
                  <a v-else href="#" @click="item.onClick()" :class="[
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700',
                  ]">{{ item.name }}</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </template>
            <template v-else>
              <MenuButton href="#"
                class="inline-flex items-center border bg-primary px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 my-6"
                @click="router.push(`/login`)">
                <T keyName="nav_link_login" />
              </MenuButton>
            </template>
          </Menu>
        </div>
        <div class="-mr-2 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 pt-2 pb-3">
        <template v-for="item in state.navigation">
          <RouterLink v-if="!item.auth ||
            (item.auth &&
              userStore.isAuthenticated &&
              (!item.admin ||
                (userStore.user.userGroup &&
                  item.admin == userStore.user.userGroup.isAdmin)))
            " :key="item.name"
            class="block border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 items-center px-4 py-2 border-b-2 text-base font-medium"
            active-class="border-primary text-gray-900" :to="`/${item.href}`">
            <div class="flex items-center">
              {{ item.name }}
              <template v-if="item.href == 'project'">
                <CheckCircleIcon v-if="projectStore.saved" class="h-5 w-5 ml-2 text-green-400" aria-hidden="true" />
                <ExclamationCircleIcon v-else class="h-5 w-5 ml-2 text-yellow-400" aria-hidden="true" />
              </template>
            </div>
          </RouterLink>
        </template>
      </div>
      <div v-if="userStore.isAuthenticated" class="border-t border-gray-200 pt-4 pb-3">
        <div class="flex items-center px-4">
          <div class="flex-shrink-0">
            <img class="h-8 w-8 rounded-full"
              :src="`https://ui-avatars.com/api/?background=6EBD47&color=fff&name=${user.firstName}+${user.lastName}&rounded=true&size=48`"
              alt="" />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">
              {{ userStore.user.name }}
            </div>
            <div class="text-sm font-medium text-gray-500">
              {{ userStore.user.email }}
            </div>
          </div>
        </div>
        <div class="mt-3 space-y-1">
          <DisclosureButton v-for="item in state.userNavigation" :key="item.name" as="a" :href="item.href"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800">{{ item.name
            }}</DisclosureButton>
        </div>
      </div>
      <div v-else class="border-t border-gray-200 pt-4 pb-3">
        <div class="mt-3 space-y-1">
          <DisclosureButton as="a" href="/login"
            class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800">Login
          </DisclosureButton>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
} from "@heroicons/vue/20/solid";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { useAuth0 } from "@auth0/auth0-vue";
import { reactive } from "vue";
import { useProjectStore } from "../../stores/project";
import { useAuthStore } from "../../stores/auth";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
const projectStore = useProjectStore();
const userStore = useAuthStore();
const router = useRouter();
const route = useRoute();

const { user } = storeToRefs(userStore);

const emits = defineEmits(["userPreferencesClick"]);

const state = reactive({
  navigation: [
    // {
    //   name: "Home",
    //   href: "home",
    //   auth: false,
    //   admin: false,
    // },
    {
      name: "Selector",
      href: "selection",
      auth: true,
      admin: false,
    },
    // {
    //   name: "Project",
    //   href: "project",
    //   auth: true,
    //   admin: false,
    // },
    // {
    //   name: "Dashboard",
    //   href: "dashboard",
    //   auth: true,
    //   admin: true,
    // },
  ],
  userNavigation: [
    // { name: "Your Profile", href: "#" },
    {
      name: "Preferences",
      href: "#",
      onClick: () => {
        emits("userPreferencesClick");
      },
    },
    {
      name: "Sign out",
      href: "#",
      onClick: () => {
        userStore.logout();
      },
    },
  ],
});
</script>
