<template>
  <a v-if="props.useAsAnchor" :href="props.href" :class="buttonClass">
    <slot name="pre-content"> </slot>
    <template v-if="hasContentSlot">
      <slot name="content"> </slot>
    </template>
    <span v-if="props.buttonText" class="w-full">
      <T :keyName="buttonText" />
    </span>
  </a>
  <button v-else type="button" :class="buttonClass">
    <slot name="pre-content"> </slot>
    <template v-if="hasContentSlot">
      <slot name="content"> </slot>
    </template>
    <span v-if="props.buttonText" class="w-full">
      <T :keyName="buttonText" />
    </span>
  </button>
</template>

<script setup>
import { computed, useSlots } from "vue";

const slots = useSlots();

const hasContentSlot = computed(() => {
  return !!slots["content"];
});

const props = defineProps([
  "buttonText",
  "buttonColor",
  "buttonSize",
  "useAsAnchor",
  "href",
]);

const buttonPaddingX = computed(() => {
  if (props.buttonSize) {
    if (props.buttonSize == "xs") return "px-2.5";
    if (props.buttonSize == "sm") return "px-3";
    if (props.buttonSize == "md") return "px-4";
    if (props.buttonSize == "lg") return "px-4";
    if (props.buttonSize == "xl") return "px-6";
  } else {
    return "px-4";
  }
});

const buttonPaddingY = computed(() => {
  if (props.buttonSize) {
    if (props.buttonSize == "xs") return "py-1.5";
    if (props.buttonSize == "sm") return "py-2";
    if (props.buttonSize == "md") return "py-2";
    if (props.buttonSize == "lg") return "py-2";
    if (props.buttonSize == "xl") return "py-3";
  } else {
    return "py-2";
  }
});

const buttonTextSize = computed(() => {
  if (props.buttonSize) {
    if (props.buttonSize == "xs") return "text-xs";
    if (props.buttonSize == "sm") return "text-sm";
    if (props.buttonSize == "md") return "text-sm";
    if (props.buttonSize == "lg") return "text-base";
    if (props.buttonSize == "xl") return "text-base";
  } else {
    return "text-sm";
  }
});

const buttonClass = computed(() => {
  return `inline-flex items-center border border-transparent bg-${props.buttonColor} ${buttonPaddingX.value} ${buttonPaddingY.value} ${buttonTextSize.value} font-medium text-white shadow-sm hover:bg-${props.buttonColor}-600 focus:outline-none focus:ring-2 focus:ring-${props.buttonColor}-500 focus:ring-offset-2`;
});
</script>

<style scoped></style>
