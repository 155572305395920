<template>
  <Combobox as="div">
    <ComboboxLabel class="block text-sm font-medium text-gray-700">
      <T :keyName="label" />
    </ComboboxLabel>
    <div class="relative">
      <ComboboxInput
        readonly
        @click="clickButton"
        class="cursor-default w-full border border-gray-300 bg-white py-1.5 pl-3 pr-10 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-0 focus:ring-gray-500 text-sm rounded-full"
        :display-value="
          (item) => t(props.textKey ? item[props.textKey] : item.text)
        "
      />
      <ComboboxButton
        ref="button"
        class="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none"
      >
        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions
        v-if="props.items.length > 0"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm rounded-lg"
      >
        <ComboboxOption
          v-for="item in items"
          :key="props.valueKey ? item[props.valueKey] : item.value"
          :value="item"
          as="template"
          v-slot="{ active, selected }"
        >
          <li
            :class="[
              'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
              active ? 'bg-gray-50' : '',
            ]"
          >
            <span :class="['block truncate', selected && 'font-semibold']">
              <T :keyName="props.textKey ? item[props.textKey] : item.text" />
            </span>

            <span
              v-if="selected"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4 text-primary-600',
                active ? '' : '',
              ]"
            >
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script setup>
import { computed, ref } from "vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import { useTranslate } from "@tolgee/vue";
const t = useTranslate();
const props = defineProps(["label", "items", "textKey", "valueKey"]);

const button = ref(null);

const clickButton = () => {
  button.value ? button.value.el.click() : () => {};
};
</script>

<style scoped></style>
