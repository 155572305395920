<template>
  <TolgeeProvider :config="tolgeeConfig">
    <RouterView />
  </TolgeeProvider>
</template>

<script setup>
import { RouterLink, RouterView } from "vue-router";
import { TolgeeProvider } from "@tolgee/vue";
import { reactive, watch, onMounted } from "vue";

const tolgeeConfig = reactive({
  apiUrl: import.meta.env.VITE_VUE_APP_TOLGEE_API_URL,
  apiKey: import.meta.env.VITE_VUE_APP_TOLGEE_API_KEY,
  availableLanguages: ["en" /*, "de", "cs", "fr"*/],
  defaultLanguage: "en",
});
</script>
