<template>
  <Modal
    :open="props.open"
    @on-close="emits('on-close')"
    panel-class="!max-w-2xl overflow-visible rounded-md"
  >
    <template #content>
      <div
        v-if="state.loading"
        class="absolute top-0 bottom-0 left-0 right-0 backdrop-blur-1 z-50 h-full"
      >
        <div class="m-auto block w-fit relative top-[48%]">
          <svg
            class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      </div>

      <div class="grid grid-cols-1">
        <div class="col-span-1">
          <div class="text-md">
            <T keyName="label_preferences" />
          </div>
        </div>
        <div class="col-span-1 lg:grid lg:grid-cols-12 lg:gap-x-5 pt-4">
          <aside class="py-6 px-2 sm:px-6 lg:col-span-3 lg:py-0 lg:px-0">
            <nav class="space-y-1">
              <a
                v-for="item in state.navigation"
                :key="item.name"
                href="#"
                @click="setCurrentPage(item)"
                :class="[
                  item.current
                    ? 'bg-gray-50 text-primary-600 hover:bg-white'
                    : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                  'group px-3 py-2 flex items-center text-sm font-medium rounded-md',
                ]"
                :aria-current="item.current ? 'page' : undefined"
              >
                <span class="truncate">{{ item.name }}</span>
              </a>
            </nav>
          </aside>

          <div class="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
            <template v-if="state.currentPageId == 0">
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-6">
                  <Dropdown
                    :options="state.titles"
                    v-model="state.userDetails.title"
                    color="primary"
                    label="Title"
                  />
                </div>
                <div class="col-span-6">
                  <Input
                    v-model="state.userDetails.firstName"
                    color="primary"
                    label="First name"
                    size="xs"
                  />
                </div>
                <div class="col-span-6">
                  <Input
                    v-model="state.userDetails.lastName"
                    color="primary"
                    label="Last name"
                    size="xs"
                  />
                </div>
                <div class="col-span-6">
                  <Input
                    v-model="state.userDetails.company"
                    color="primary"
                    label="Company"
                    size="xs"
                  />
                </div>
                <div class="col-span-6">
                  <Input
                    v-model="state.userDetails.jobTitle"
                    color="primary"
                    label="Job title"
                    size="xs"
                  />
                </div>
                <div class="col-span-6">
                  <Input
                    v-model="state.userDetails.mobileNumber"
                    color="primary"
                    label="Mobile number"
                    size="xs"
                  />
                </div>
                <div class="col-span-6">
                  <Dropdown
                    :options="project.currencyOptions"
                    v-model="state.userDetails.currency"
                    color="primary"
                    label="Default currency"
                  />
                </div>
              </div>
            </template>
            <template v-if="state.currentPageId == 1">
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-6">
                  <Dropdown
                    :options="getVolumeUnits"
                    v-model="state.userPreferences.volumeUnit"
                    color="primary"
                    label="Airflow unit"
                  />
                </div>
                <div class="col-span-6">
                  <Dropdown
                    :options="getPressureUnits"
                    v-model="state.userPreferences.pressureUnit"
                    color="primary"
                    label="Pressure unit"
                  />
                </div>
                <div class="col-span-6">
                  <div>
                    <label
                      for="value"
                      class="block text-xs font-medium text-gray-700"
                    >
                      <T keyName="label_air_conditions" />
                    </label>
                  </div>
                  <div class="grid grid-cols-12 gap-1">
                    <label
                      for="value"
                      class="col-span-9 py-2 block text-xs font-medium text-gray-500"
                    >
                      {{ conditionsText }}
                    </label>
                    <Button
                      class="col-span-3 px-2"
                      button-color="primary"
                      button-text="button_text_edit"
                      button-size="xs"
                      id="button-edit-air-conditions-up"
                      @click="state.conditionsOpen = !state.conditionsOpen"
                    />
                    <AirConditionsPopover
                      :selection-information="selectionInformation"
                      popover-id="popover-air-conditions"
                      trigger-id="button-edit-air-conditions-up"
                      trigger-mode="none"
                      placement="right"
                      class="w-80 mt-10"
                      :open="state.conditionsOpen"
                    />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <Button button-text="Save" button-color="primary" @click="updateUser" />
        <Button
          button-text="Cancel"
          button-color="white"
          class="text-gray-900 border-gray-200 mr-4"
          @click="resetPreferences"
        />
      </div>
    </template>
  </Modal>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import Modal from "../shared/Modal.vue";

import {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowTopRightOnSquareIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import { reactive, computed, watch, ref, onMounted } from "vue";
import Button from "../shared/Button.vue";
import Dropdown from "../shared/Dropdown.vue";
import Input from "../shared/Input.vue";
import LeadingTrailingInput from "../shared/LeadingTrailingInput.vue";
import { useAuthStore } from "../../stores/auth";
import { storeToRefs } from "pinia";
import cheetahJs from "cheetah-js";
import { useSelectionStore } from "../../stores/selection";
import AirConditionsPopover from "../selection/AirConditionsPopover.vue";
import { fetchWrapper } from "../../helpers/fetch-wrapper";
import { useUserStore } from "../../stores/user";
import { useProjectStore } from "../../stores/project";

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);
const userStore = useUserStore();
const selectionStore = useSelectionStore();
const projectStore = useProjectStore();
const { project } = storeToRefs(projectStore);

const {
  volumeFlowUnits,
  pressureUnits,
  temperatureUnits,
  densityUnits,
  distanceUnits,
  selectionInformation,
} = storeToRefs(selectionStore);

const props = defineProps(["open"]);
const emits = defineEmits(["on-close"]);

const state = reactive({
  loading: false,
  currentPageId: 0,
  resetPreferences: {},
  userPreferences: {},
  resetUserDetails: {},
  userDetails: {
    title: "",
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    mobileNumber: "",
    currency: "",
  },
  selectionInformation: {},
  titles: [
    { text: "Please select", value: "" },
    { text: "Mr", value: "Mr" },
    { text: "Mrs", value: "Mrs" },
    { text: "Miss", value: "Miss" },
    { text: "Ms", value: "Ms" },
    { text: "Dr", value: "Dr" },
  ],
  navigation: [
    { index: 0, name: "Details", current: true },
    { index: 1, name: "Selection", current: false },
  ],
});

const setCurrentPage = (item) => {
  state.currentPageId = item.index;
  state.navigation.forEach((i) => (i.current = i.index == item.index));
};

const getVolumeUnits = computed(() => {
  const units = [];
  volumeFlowUnits.value.forEach((u, k) => {
    units.push({
      text: u.text,
      value: k,
    });
  });
  return units;
});

const getPressureUnits = computed(() => {
  const units = [];
  pressureUnits.value.forEach((u, k) => {
    units.push({
      text: u.text,
      value: k,
    });
  });
  return units;
});

const conditionsText = computed(() => {
  let temperature = `${state.userPreferences.conditions.operatingTemperature} ${
    temperatureUnits.value.get(
      cheetahJs.units.Enums.TemperatureUnits[
        state.userPreferences.conditions.temperatureUnit
      ]
    )?.text
  }`;

  let altitude = `${state.userPreferences.conditions.altitude} ${
    distanceUnits.value.get(
      cheetahJs.units.Enums.DistanceUnits[
        state.userPreferences.conditions.altitudeUnit
      ]
    )?.text
  }`;

  let density = `${state.userPreferences.conditions.calculatedDensity} ${
    densityUnits.value.get(
      cheetahJs.units.Enums.DensityUnits[
        state.userPreferences.conditions.densityUnit
      ]
    )?.text
  }`;

  return `${temperature} @ ${altitude} (${density})`;
});

const updateUser = () => {
  state.loading = true;
  const updateRequest = {
    title: state.userDetails.title,
    firstName: state.userDetails.firstName,
    lastName: state.userDetails.lastName,
    company: state.userDetails.company,
    jobTitle: state.userDetails.jobTitle,
    mobileNumber: state.userDetails.mobileNumber,
    currency: state.userDetails.currency,
    userPreferences: state.userPreferences,
  };
  userStore.updateUser(updateRequest).then(() => {
    state.resetPreferences = { ...state.userPreferences };
    state.resetUserDetails = { ...state.userDetails };
    state.loading = false;
  });
};

const resetPreferences = () => {
  state.userPreferences = { ...state.resetPreferences };
  emits("on-close");
};

onMounted(() => {
  state.resetPreferences = { ...user.value.userPreferences };
  state.userPreferences = { ...user.value.userPreferences };
  state.selectionInformation = { ...selectionInformation.value };
  state.userDetails.title = user.value.title;
  state.userDetails.firstName = user.value.firstName;
  state.userDetails.lastName = user.value.lastName;
  state.userDetails.company = user.value.company;
  state.userDetails.jobTitle = user.value.jobTitle;
  state.userDetails.mobileNumber = user.value.mobileNumber;
  state.userDetails.currency = user.value.currency;
  state.resetUserDetails = { ...state.userDetails };
});
</script>

<style scoped></style>
