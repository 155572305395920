import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { T } from "@tolgee/vue";
import App from "./App.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "flowbite";
import Highcharts from "highcharts";
import stockInit from "highcharts/modules/stock";
stockInit(Highcharts);
import HighchartsVue from "highcharts-vue";
import Notifications from "notiwind";
import router from "./router";
import "./assets/main.scss";

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app
  .use(pinia)
  .use(router)
  .use(Notifications)
  .component("T", T)
  .component("EasyDataTable", Vue3EasyDataTable)
  .mount("#app");
