import { ref, computed, reactive } from "vue";
import { defineStore } from "pinia";
import cheetahJs from "cheetah-js";
import { fetchWrapper } from "../helpers/fetch-wrapper";

export const useProjectStore = defineStore("project", () => {
  const project = ref({});
  const saved = ref(true);
  const busy = ref(false);
  const blockNextUpdate = ref(false);

  const getCurrentProject = () => {
    busy.value = true;
    return fetchWrapper
      .get(`${import.meta.env.VITE_API_URL}/api/project/current`)
      .then((response) => {
        project.value = response.currentProject;
        updateProjectStatus(response.projectSaved);
        blockNextUpdate.value = true;
        busy.value = false;
      })
      .catch((error) => {
        throw error;
      });
  };

  const copyProject = (projectId, revision, userId) => {
    return fetchWrapper
      .post(`${import.meta.env.VITE_API_URL}/api/project/copy`, {
        projectId,
        revision,
        userId,
      })
      .then((response) => {
        project.value = response;
        updateProjectStatus(true);
        blockNextUpdate.value = true;
        return true;
      });
  };

  const saveProject = (saveProject, incrementRevision, userId) => {
    let slimProject = { ...saveProject, resultItems: [] };
    return fetchWrapper
      .post(`${import.meta.env.VITE_API_URL}/api/project/save`, {
        project: slimProject,
        incrementRevision,
        userId,
      })
      .then((response) => {
        let tempProject = response;
        project.value.revision = tempProject.revision;
        project.value.lastModified = tempProject.lastModified;
        project.value.reference = tempProject.reference;
        project.value.quotationNumber = tempProject.quotationNumber;
        saved.value = true;
      });
  };

  const openProject = (projectId, revision, userId) => {
    return fetchWrapper
      .post(`${import.meta.env.VITE_API_URL}/api/project/open`, {
        projectId,
        revision,
        userId,
      })
      .then((response) => {
        project.value = response;
        updateProjectStatus(true);
        blockNextUpdate.value = true;
        return true;
      });
  };
  const newProject = () => {
    return fetchWrapper
      .get(`${import.meta.env.VITE_API_URL}/api/project/new`)
      .then((response) => {
        project.value = response;
        updateProjectStatus(false);
        blockNextUpdate.value = true;
      })
      .catch((error) => console.log(error));
  };

  const deleteProject = (projectId, revision, userId) => {
    return fetchWrapper.post(
      `${import.meta.env.VITE_API_URL}/api/project/delete`,
      {
        projectId,
        revision,
        userId,
      }
    );
  };

  const addToProject = (item, accessories) => {
    let sortOrder = 1;
    if (project.value.resultItems.length > 0) {
      sortOrder =
        Math.max(...project.value.resultItems.map((i) => i.order)) + 1;
    }

    return fetchWrapper
      .post(`${import.meta.env.VITE_API_URL}/api/project/add-item`, {
        resultId: item.id,
        accessories,
        sortOrder,
      })
      .then((response) => {
        project.value.resultItems.push(response);
        updateProjectStatus(false);
        return true;
      })
      .catch((error) => console.log(error));
  };

  const addCustomItemToProject = (item) => {
    let isFan = item.destination == "project";

    if (isFan) {
      let sortOrder = 1;
      if (project.value.resultItems.length > 0) {
        sortOrder =
          Math.max(...project.value.resultItems.map((i) => i.order)) + 1;
      }
      item.sortOrder = sortOrder;
    }

    return fetchWrapper
      .post(`${import.meta.env.VITE_API_URL}/api/project/add-custom-item`, item)
      .then((response) => {
        if (isFan) {
          project.value.resultItems.push(response);
        } else {
          let toItem = project.value.resultItems.find(
            (i) => i.id == item.destination
          );
          toItem.accessories.push(response);
        }

        updateProjectStatus(false);
        return true;
      })
      .catch((error) => console.log(error));
  };

  const removeFromProject = (item) => {
    return fetchWrapper
      .post(`${import.meta.env.VITE_API_URL}/api/project/remove-item`, {
        resultId: item.id,
      })
      .then((response) => {
        let itemIndex = project.value.resultItems.findIndex(
          (i) => i.id == item.id
        );
        project.value.resultItems.splice(itemIndex, 1);
        updateProjectStatus(false);
        return true;
      })
      .catch((error) => console.log(error));
  };
  const updateItem = (item) => {
    return fetchWrapper
      .post(`${import.meta.env.VITE_API_URL}/api/project/update-item`, {
        resultId: item.id,
        reference: item.reference,
        quantity: item.quantity,
        accessories: item.accessories,
      })
      .then((response) => {
        updateProjectStatus(false);
        return true;
      })
      .catch((error) => console.log(error));
  };
  const sortItems = (items) => {
    return fetchWrapper
      .post(`${import.meta.env.VITE_API_URL}/api/project/sort-items`, {
        items,
      })
      .then((response) => {
        updateProjectStatus(false);
        return true;
      })
      .catch((error) => console.log(error));
  };

  const saveProjectSelection = (replaceId, item) => {
    return fetchWrapper
      .post(`${import.meta.env.VITE_API_URL}/api/project/save-selection`, {
        replaceId,
        resultId: item.id,
      })
      .then((response) => {
        let originalIndex = project.value.resultItems.findIndex(
          (i) => i.id == replaceId
        );
        project.value.resultItems[originalIndex] = response;
        updateProjectStatus(false);
        return true;
      })
      .catch((error) => console.log(error));
  };
  const updateProjectDetails = () => {
    return fetchWrapper
      .post(
        `${import.meta.env.VITE_API_URL}/api/project/update-project-details`,
        {
          projectName: project.value.name,
          projectReference: project.value.reference,
        }
      )
      .then((response) => {
        updateProjectStatus(false);
        return true;
      })
      .catch((error) => console.log(error));
  };
  const updateProjectStatus = (value) => {
    saved.value = value;
  };

  return {
    project,
    saved,
    busy,
    blockNextUpdate,
    getCurrentProject,
    openProject,
    newProject,
    addToProject,
    removeFromProject,
    updateItem,
    updateProjectDetails,
    updateProjectStatus,
    copyProject,
    saveProject,
    deleteProject,
    saveProjectSelection,
    sortItems,
    addCustomItemToProject,
  };
});
