<template>
  <div class="flex min-h-full flex-col justify-center py-4 xs:px-2">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="text-center text-3xl tracking-tight text-gray-700 font-light">
        Forgot password?
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-6 px-6 shadow sm:rounded-lg">
        <div class="space-y-4">
          <div>
            <Input v-model="state.email" label="Email address" />
          </div>

          <div>
            <button
              class="flex w-full justify-center rounded-md bg-primary py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              @click="forgotPassword"
            >
              Reset password
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Input from "../components/shared/Input.vue";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { notify } from "notiwind";
import { fetchWrapper } from "../helpers/fetch-wrapper";

const router = useRouter();

const state = reactive({
  email: "",
});

const forgotPassword = () => {
  fetchWrapper
    .post(`${import.meta.env.VITE_API_URL}/api/user/forgot-password`, {
      email: state.email,
    })
    .then(() => {
      notify(
        {
          group: "notifications",
          title: "Success",
          text: "Please check your email to reset your password",
        },
        5000
      );
      router.push("/login");
    });
};
</script>

<style lang="scss" scoped></style>
