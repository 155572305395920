<template>
  <footer class="bg-white" aria-labelledby="footer-heading">
    <div class="w-full bg-primary-500">
      <div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
        <div class="xl:grid xl:grid-cols-4 xl:gap-8">
          <div class="grid grid-cols-2 gap-8 xl:col-span-4">
            <div class="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 class="text-sm font-medium text-white text-center"></h3>
              </div>
              <div class="mt-12 md:mt-0">
                <h3 class="text-sm font-medium text-white text-center"></h3>
              </div>
            </div>
            <div class="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 class="text-sm font-medium text-white text-center"></h3>
              </div>
              <div class="mt-12 md:mt-0">
                <h3 class="text-sm font-medium text-white text-center"></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-auto max-w-7xl pt-2 pb-4 px-4 sm:px-6 lg:px-8">
      <div class="pb-8 xl:grid xl:grid-cols-4 xl:gap-8">
        <div class="grid grid-cols-2 gap-8 xl:col-span-4">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <ul role="list" class="space-y-4">
                <li
                  v-for="item in navigation.solutions"
                  :key="item.name"
                  class="text-center"
                >
                  <a
                    :href="item.href"
                    class="text-sm text-gray-500 hover:text-gray-900"
                    >{{ item.name }}</a
                  >
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <ul role="list" class="space-y-4">
                <li
                  v-for="item in navigation.support"
                  :key="item.name"
                  class="text-center"
                >
                  <a
                    :href="item.href"
                    class="text-sm text-gray-500 hover:text-gray-900"
                    >{{ item.name }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <ul role="list" class="space-y-4">
                <li
                  v-for="item in navigation.company"
                  :key="item.name"
                  class="text-center"
                >
                  <a
                    :href="item.href"
                    class="text-sm text-gray-500 hover:text-gray-900"
                    >{{ item.name }}</a
                  >
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <ul role="list" class="space-y-4">
                <li
                  v-for="item in navigation.legal"
                  :key="item.name"
                  class="text-center"
                >
                  <a
                    :href="item.href"
                    class="text-sm text-gray-500 hover:text-gray-900"
                    >{{ item.name }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between"
      >
        <div class="flex space-x-6 md:order-2">
          <a
            v-for="item in navigation.social"
            :key="item.name"
            :href="item.href"
            class="text-gray-400 hover:text-gray-500"
          >
            <span class="sr-only">{{ item.name }}</span>
            <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
          </a>
        </div>
        <p class="mt-8 text-sm text-gray-400 md:order-1 md:mt-0">
          &copy; 2023 MM Metalica. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { defineComponent, h } from "vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
const navigation = {
  solutions: [{ name: "", href: "#" }],
  support: [{ name: "", href: "#" }],
  company: [{ name: "", href: "#" }],
  legal: [{ name: "", href: "#" }],
  social: [],
};
</script>

<style lang="scss" scoped></style>
