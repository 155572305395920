<template>
  <div class="grid grid-cols-2">
    <div class="col-span-2 pt-4">
      <EasyDataTable ref="dataTable" :items="props.items" :loading="props.loading" :headers="props.columns" hide-footer
        class="min-h-fit">
        <template #item-maxdiscount="item">
          {{ item.permissions.pricing.maximumDiscount }}
        </template>
        <template #item-showcostprice="item">
          <CheckCircleIcon v-if="item.permissions.pricing.showCostPricing" class="h-5 w-5 m-auto text-green-400"
            aria-hidden="true" />
          <XCircleIcon v-else class="h-5 w-5 m-auto text-red-400" aria-hidden="true" />
        </template>
        <template #item-showsaleprice="item">
          <CheckCircleIcon v-if="item.permissions.pricing.showSalePricing" class="h-5 w-5 m-auto text-green-400"
            aria-hidden="true" />
          <XCircleIcon v-else class="h-5 w-5 m-auto text-red-400" aria-hidden="true" />
        </template>
        <template #item-showanalytics="item">
          <CheckCircleIcon v-if="item.permissions.feature.allowAnalytics" class="h-5 w-5 m-auto text-green-400"
            aria-hidden="true" />
          <XCircleIcon v-else class="h-5 w-5 m-auto text-red-400" aria-hidden="true" />
        </template>
        <template #item-allowcustomitem="item">
          <CheckCircleIcon v-if="item.permissions.project.allowCustomItem" class="h-5 w-5 m-auto text-green-400"
            aria-hidden="true" />
          <XCircleIcon v-else class="h-5 w-5 m-auto text-red-400" aria-hidden="true" />
        </template>
        <template #item-actions="item">
          <div class="flex justify-end">
            <Button button-color="primary" button-text="button_text_edit" button-size="xs" class="mr-2"
              @click="emits(`action-edit-click`, item.id)" />
            <Button v-if="item.canDelete" button-color="red" button-text="button_text_delete" button-size="xs"
              @click="emits(`action-delete-click`, item)" />
          </div>
        </template>
        <template #loading>
          <div role="status">
            <svg class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500"
              viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor" />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill" />
            </svg>
          </div>
        </template>
      </EasyDataTable>
    </div>
    <div class="col-span-2">
      <div class="flex items-center justify-between border-t border-gray-200 py-3">
        <div class="flex flex-1 justify-between sm:hidden">
          <a href="#"
            class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
            Previous
          </a>
          <a href="#"
            class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
            Next
          </a>
        </div>
        <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p class="text-sm text-gray-700">
              Showing
              {{ " " }}
              <span class="font-medium">{{ currentPageFirstIndex }}</span>
              {{ " " }}
              to
              {{ " " }}
              <span class="font-medium">{{ currentPageLastIndex }}</span>
              {{ " " }}
              of
              {{ " " }}
              <span class="font-medium">{{ clientItemsLength }}</span>
              {{ " " }}
              results
            </p>
          </div>
          <div>
            <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button @click="prevPage"
                class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">
                <span class="sr-only"> Previous </span>
                <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
              </button>
              <template v-for="p in maxPaginationNumber">
                <button @click="updatePage(p)" aria-current="page" :class="[
                  p == currentPaginationNumber ? activePageClass : pageClass,
                ]">
                  {{ p }}
                </button>
              </template>

              <button @click="nextPage"
                class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">
                <span class="sr-only"> Next </span>
                <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/vue/20/solid";
import { reactive, computed, ref } from "vue";
import Button from "../shared/Button.vue";

const props = defineProps(["items", "columns", "loading"]);
const emits = defineEmits(["action-edit-click", "action-delete-click"]);

const dataTable = ref();

const activePageClass =
  "relative z-10 inline-flex items-center border border-primary-500 bg-primary px-4 py-2 text-sm font-medium text-white focus:z-20";
const pageClass =
  "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20";

// index related
const currentPageFirstIndex = computed(
  () => dataTable.value?.currentPageFirstIndex
);
const currentPageLastIndex = computed(
  () => dataTable.value?.currentPageLastIndex
);
const clientItemsLength = computed(() => dataTable.value?.clientItemsLength);

// pagination related
const maxPaginationNumber = computed(
  () => dataTable.value?.maxPaginationNumber
);
const currentPaginationNumber = computed(
  () => dataTable.value?.currentPaginationNumber
);

const isFirstPage = computed(() => dataTable.value?.isFirstPage);
const isLastPage = computed(() => dataTable.value?.isLastPage);

const nextPage = () => {
  dataTable.value.nextPage();
};
const prevPage = () => {
  dataTable.value.prevPage();
};
const updatePage = (paginationNumber) => {
  dataTable.value.updatePage(paginationNumber);
};

// rows per page related
const rowsPerPageOptions = computed(() => dataTable.value?.rowsPerPageOptions);
const rowsPerPageActiveOption = computed(
  () => dataTable.value?.rowsPerPageActiveOption
);

const updateRowsPerPageSelect = (e) => {
  dataTable.value.updateRowsPerPageActiveOption(e.target.value);
};
</script>

<style scoped></style>
