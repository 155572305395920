<template>
  <Popover :popover-id="props.popoverId" :trigger-id="props.triggerId" :trigger-mode="props.triggerMode"
    :placement="props.placement" :open="props.open" class="rounded-lg">
    <template #content>
      <div class="grid grid-cols-4 gap-2">
        <div class="col-span-2">
          <InputDropdown label="input_label_altitude" :options="distanceUnits" placeholder="0"
            :input-value="state.conditions.altitude" :select-value="cheetahJs.units.Enums.DistanceUnits[state.conditions.altitudeUnit]
              " @input-changed="altitudeChanged" @select-changed="altitudeUnitChanged" />
        </div>
        <div class="col-span-2">
          <InputDropdown label="input_label_air_temperature" :options="temperatureUnits" placeholder="0"
            :input-value="state.conditions.operatingTemperature" :select-value="cheetahJs.units.Enums.TemperatureUnits[
              state.conditions.temperatureUnit
            ]
              " @input-changed="temperatureChanged" @select-changed="temperatureUnitChanged" />
        </div>
        <div class="col-span-2">
          <InputDropdown label="input_label_inlet_pressure" :options="pressureUnits" placeholder="0"
            :input-value="state.conditions.inletPressure" :select-value="cheetahJs.units.Enums.PressureUnits[state.conditions.pressureUnit]
              " @input-changed="pressureChanged" @select-changed="pressureUnitChanged" />
        </div>
        <div class="col-span-2">
          <label for="value" class="block text-sm font-medium text-gray-700 mb-1 rounded-full">
            <T keyName="input_label_humidity" />
          </label>
          <LeadingTrailingInput color="primary" trailing-text="%" v-model="state.conditions.relativeHumidity" />
        </div>
      </div>
    </template>
  </Popover>
</template>

<script setup>
import InputDropdown from "../shared/InputDropdown.vue";
import Popover from "../shared/Popover.vue";
import { useSelectionStore } from "../../stores/selection";
import { storeToRefs } from "pinia";
import { reactive } from "vue";
import { Conditions } from "cheetah-js/src/conditions";
import { onMounted } from "vue";
import { watch } from "vue";
import cheetahJs from "cheetah-js";
import LeadingTrailingInput from "../shared/LeadingTrailingInput.vue";

const selectionStore = useSelectionStore();

const { distanceUnits, temperatureUnits, densityUnits, pressureUnits } =
  storeToRefs(selectionStore);

const props = defineProps([
  "selectionInformation",
  "popoverId",
  "triggerId",
  "triggerMode",
  "placement",
  "open",
]);

const emits = defineEmits(["conditionsUpdated"]);

let conditions = new Conditions();

const state = reactive({
  conditions,
});

watch(
  state.conditions,
  () => {
    state.conditions.computeDensity();
    emits("conditionsUpdated", state.conditions);
  },
  { deep: true }
);

const altitudeChanged = (val) => {
  state.conditions.altitude = parseFloat(val);
};

const temperatureChanged = (val) => {
  state.conditions.operatingTemperature = parseFloat(val);
};

const pressureChanged = (val) => {
  state.conditions.inletPressure = parseFloat(val);
};

const altitudeUnitChanged = (val) => {
  state.conditions.pressureUnit = cheetahJs.units.Enums.DistanceUnits[val];
};

const temperatureUnitChanged = (val) => {
  state.conditions.pressureUnit = cheetahJs.units.Enums.TemperatureUnits[val];
};

const pressureUnitChanged = (val) => {
  state.conditions.pressureUnit = cheetahJs.units.Enums.PressureUnits[val];
};

onMounted(() => {
  state.conditions.altitude = props.selectionInformation.conditions.altitude;
  state.conditions.altitudeUnit =
    props.selectionInformation.conditions.altitudeUnit;
  state.conditions.barometricPressure =
    props.selectionInformation.conditions.barometricPressure;
  state.conditions.calculatedDensity =
    props.selectionInformation.conditions.calculatedDensity;
  state.conditions.densityUnit =
    props.selectionInformation.conditions.densityUnit;
  state.conditions.inletPressure =
    props.selectionInformation.conditions.inletPressure;
  state.conditions.operatingTemperature =
    props.selectionInformation.conditions.operatingTemperature;
  state.conditions.pressureUnit =
    props.selectionInformation.conditions.pressureUnit;
  state.conditions.relativeHumidity =
    props.selectionInformation.conditions.relativeHumidity;
  state.conditions.specificGravity =
    props.selectionInformation.conditions.specificGravity;
  state.conditions.specificHumidity =
    props.selectionInformation.conditions.specificHumidity;
  state.conditions.standardDensity =
    props.selectionInformation.conditions.standardDensity;
  state.conditions.temperatureUnit =
    props.selectionInformation.conditions.temperatureUnit;
  state.conditions.testDensity =
    props.selectionInformation.conditions.cTestDensity;
  state.conditions.testTemperature =
    props.selectionInformation.conditions.cTestTemperature;
});
</script>

<style scoped></style>
