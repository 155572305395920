import * as signalR from "@microsoft/signalr";
import { fetchWrapper } from "../helpers/fetch-wrapper";

export default class SelectionService {
  hubConnection = null;
  connectionId = "";
  controller = null;

  onItemSelected = (item) => {};
  onItemRejected = (item) => {};
  onSelectionComplete = () => {};

  startConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${import.meta.env.VITE_API_URL}/selection_hub`)
      .build();
    this.hubConnection
      .start()
      .then(() => console.log("Connection started"))
      .then(() => this.getConnectionId())
      .then(() => this.setupEvents())
      .catch((err) => console.log("Error while starting connection: " + err));
  }

  setupEvents() {
    this.hubConnection?.on("onItemSelected", (item) => {
      if (this.onItemSelected) this.onItemSelected(JSON.parse(item));
    });
    this.hubConnection?.on("onSelectionComplete", () => {
      if (this.onSelectionComplete) this.onSelectionComplete();
    });
  }

  getConnectionId() {
    this.hubConnection?.invoke("getconnectionid").then((data) => {
      console.log(data);
      this.connectionId = data;
    });
  }

  startSelection(data) {
    this.controller = new AbortController();

    return fetchWrapper.post(
      `${import.meta.env.VITE_API_URL}/api/selection/select`,
      data,
      this.controller.signal
    );
  }

  stopSelection() {
    this.controller.abort();
  }
}
