<template>
  <div>
    <label
      v-if="props.label"
      for="email"
      class="block text-sm font-medium text-gray-700 mb-1"
      >{{ label }}</label
    >
    <div class="">
      <input
        class="block w-full border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 focus:ring-0 text-sm rounded-full"
        :type="inputType"
        :id="id"
        :placeholder="placeholder"
        :value="modelValue ?? inputValue"
        @input="emitInput($event)"
        :class="props.inputClass"
        :disabled="props.disabled"
        :readonly="props.readonly"
        @keyup="emits('input-keyup', $event)"
        @keydown="emits('keydown')"
        @blur="emits('blur')"
        @change="emits('change', $event)"
      />
      <p
        v-if="props.validation && !props.isValid"
        class="mt-2 text-xs text-red-600 dark:text-red-400"
      >
        {{ props.validationText }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps([
  "id",
  "label",
  "inputValue",
  "inputType",
  "selectValue",
  "options",
  "callback",
  "placeholder",
  "errorMessage",
  "modelValue",
  "inputClass",
  "disabled",
  "readonly",
  "validation",
  "is-valid",
  "validation-text",
]);

const inputType = computed(() => {
  return props.inputType ? props.inputType : "text";
});

const emits = defineEmits([
  "update:modelValue",
  "input-keyup",
  "keydown",
  "blur",
  "change",
]);

const emitInput = (evt) => {
  emits("update:modelValue", evt.target.value);
};
</script>
