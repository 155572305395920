<template>
  <div v-if="state.loading" class="absolute top-0 bottom-0 left-0 right-0 backdrop-blur-1 z-50">
    <div class="m-auto block w-fit relative top-[48%]">
      <svg class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500"
        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor" />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill" />
      </svg>
    </div>
  </div>
  <div class="grid grid-cols-12 gap-x-5">
    <aside class="py-6 px-2 sm:px-6 col-span-2 xs:col-span-12 lg:py-0 lg:px-0">
      <nav class="space-y-1">
        <a v-for="item in state.subNavigation" :key="item.name" :href="item.href" @click="setCurrentPage(item)" :class="[
          item.current
            ? 'bg-gray-50 text-primary-600 hover:bg-white'
            : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
          'group rounded-md px-3 py-2 flex items-center text-sm font-medium',
        ]" :aria-current="item.current ? 'page' : undefined">
          <component :is="item.icon" :class="[
            item.current
              ? 'text-primary-500'
              : 'text-gray-400 group-hover:text-gray-500',
            'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
          ]" aria-hidden="true" />
          <span class="truncate">{{ item.name }}</span>
        </a>
      </nav>
    </aside>

    <div class="space-y-6 sm:px-6 col-span-10 xs:col-span-12 xs:px-2">
      <template v-if="currentPageId == 0">
        <div class="divide-y divide-gray-200 overflow-hidden bg-white shadow">
          <div class="px-4 py-2 text-sm bg-primary text-white">
            <T keyName="card_title_users" />
          </div>
          <div class="px-4 py-2">
            <UsersTable :columns="state.userTableColumns" :items="state.userList" @action-edit-click="actionEditUserClick"
              @action-approve-click="actionApproveUserClick" @action-revoke-click="actionRevokeUserClick"
              @action-delete-click="actionDeleteUserClick" />
          </div>
        </div>
      </template>

      <template v-if="currentPageId == 1">
        <div class="divide-y divide-gray-200 overflow-hidden bg-white shadow">
          <div class="px-4 py-2 text-sm bg-primary text-white">
            <T keyName="card_title_user_groups" />
          </div>
          <div class="px-4 py-2">
            <div class="flex justify-end">
              <Button button-color="primary" button-text="button_text_new_user_group" button-size="xs"
                @click="state.newUserGroupModalOpen = true" />
            </div>
            <UserGroupsTable :columns="state.userGroupTableColumns" :items="state.userGroupList"
              @action-edit-click="actionEditUserGroupClick" @action-delete-click="actionDeleteUserGroupClick" />
          </div>
        </div>
      </template>
      <template v-if="currentPageId == 2">
        <div v-if="state.analytics.loading" class="absolute top-0 bottom-0 left-0 right-0 backdrop-blur-1 z-50">
          <div class="m-auto block w-fit relative top-[48%]">
            <svg class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500"
              viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor" />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill" />
            </svg>
          </div>
        </div>
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-9">
            <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow py-2">
              <Chart v-if="state.analytics.data.allRegionsStatusesYtd" :options="allStatusRegionsChartOptions" />
            </div>
          </div>
          <div class="col-span-3">
            <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow py-2">
              <div class="grid grid-cols-2 px-4 text-sm">
                <div class="col-span-1 border-b font-semibold">Status</div>
                <div class="col-span-1 border-b text-right font-semibold">
                  Value, AED (M)
                </div>

                <div class="col-span-2 py-1 space-y-1">
                  <template v-for="item in summaryAllRegionsStatusesYtd">
                    <div class="flex">
                      <div class="w-[50%]">{{ item.status }}</div>
                      <div class="w-[50%] text-right">
                        {{ item.total.toFixed(3) }}
                      </div>
                    </div>
                  </template>
                </div>

                <div class="col-span-1 border-b font-semibold">
                  Total, AED (M)
                </div>
                <div class="col-span-1 border-b text-right font-semibold">
                  {{
                    pricing.toCurrencyString(totalValueAllRegionsStatusesYtd)
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12">
            <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow py-2">
              <Chart :options="productCoverageYtdChartOptions" />
            </div>
          </div>
        </div>
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-12">
            <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow py-4 px-4">
              <div class="grid grid-cols-12 gap-2">
                <div class="col-span-2">
                  <Dropdown color="primary" label="Location" :options="state.analytics.filters.locations"
                    v-model="state.analytics.filters.selectedLocation" />
                </div>
                <div class="col-span-3">
                  <Dropdown color="primary" label="Status" :options="state.analytics.filters.statuses"
                    v-model="state.analytics.filters.selectedStatus" />
                </div>
                <div class="col-span-3">
                  <Dropdown color="primary" label="User" :options="state.analytics.filters.users"
                    v-model="state.analytics.filters.selectedUser" />
                </div>
                <div class="col-span-2">
                  <Input input-type="date" label="Start Date" v-model="state.analytics.filters.startDate" />
                </div>
                <div class="col-span-2">
                  <Input input-type="date" label="End Date" v-model="state.analytics.filters.endDate" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-9">
            <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow py-2">
              <Chart v-if="state.analytics.data.topProjects" :options="topProjectsChartOptions" />
            </div>
          </div>
          <div class="col-span-3">
            <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow py-2">
              <div class="grid grid-cols-2 px-4 text-sm">
                <div class="col-span-1 border-b font-semibold">Status</div>
                <div class="col-span-1 border-b text-right font-semibold">
                  Value, AED (M)
                </div>

                <div class="col-span-2 py-1 space-y-1">
                  <template v-for="item in summaryTopProjectsStatuses">
                    <div class="flex">
                      <div class="w-[50%]">{{ item.status }}</div>
                      <div class="w-[50%] text-right">
                        {{ item.total.toFixed(3) }}
                      </div>
                    </div>
                  </template>
                </div>

                <div class="col-span-1 border-b font-semibold">
                  Total, AED (M)
                </div>
                <div class="col-span-1 border-b text-right font-semibold">
                  {{
                    pricing.toCurrencyString(totalValueTopProjectsStatuses)
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-9">
            <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow py-2">
              <Chart v-if="state.analytics.data.topContractors" :options="topContractorsChartOptions" />
            </div>
          </div>
          <div class="col-span-3">
            <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow py-2">
              <div class="grid grid-cols-2 px-4 text-sm">
                <div class="col-span-1 border-b font-semibold">Status</div>
                <div class="col-span-1 border-b text-right font-semibold">
                  Value, AED (M)
                </div>

                <div class="col-span-2 py-1 space-y-1">
                  <template v-for="item in summaryTopContractorsStatuses">
                    <div class="flex">
                      <div class="w-[50%]">{{ item.status }}</div>
                      <div class="w-[50%] text-right">
                        {{ item.total.toFixed(3) }}
                      </div>
                    </div>
                  </template>
                </div>

                <div class="col-span-1 border-b font-semibold">
                  Total, AED (M)
                </div>
                <div class="col-span-1 border-b text-right font-semibold">
                  {{
                    pricing.toCurrencyString(totalValueTopContractorsStatuses)
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-9">
            <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow py-2">
              <Chart v-if="state.analytics.data.topConsultants" :options="topConsultantsChartOptions" />
            </div>
          </div>
          <div class="col-span-3">
            <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow py-2">
              <div class="grid grid-cols-2 px-4 text-sm">
                <div class="col-span-1 border-b font-semibold">Status</div>
                <div class="col-span-1 border-b text-right font-semibold">
                  Value, AED (M)
                </div>

                <div class="col-span-2 py-1 space-y-1">
                  <template v-for="item in summaryTopConsultantsStatuses">
                    <div class="flex">
                      <div class="w-[50%]">{{ item.status }}</div>
                      <div class="w-[50%] text-right">
                        {{ item.total.toFixed(3) }}
                      </div>
                    </div>
                  </template>
                </div>
                <div class="col-span-1 border-b font-semibold">
                  Total, AED (M)
                </div>
                <div class="col-span-1 border-b text-right font-semibold">
                  {{
                    pricing.toCurrencyString(totalValueTopConsultantsStatuses)
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12">
            <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow py-2">
              <Chart :options="productCoverageChartOptions" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
  <Modal v-if="state.editingUser" :open="state.editUserModalOpen" @on-close="cancelEditUser" panel-class="!max-w-md">
    <template #content>
      <div class="grid grid-cols-1">
        <div class="col-span-1">
          <div class="text-md">
            <T keyName="label_edit_user" />: {{ state.editingUser.email }}
          </div>
        </div>
        <div class="col-span-1 grid grid-cols-12 my-4">
          <div class="col-span-12">
            <Dropdown label="User group" :options="userGroupOptions" color="primary"
              v-model="state.editingUser.userGroupId" />
          </div>
        </div>
        <div class="col-span-1">
          <div class="flex justify-end">
            <Button button-color="primary" button-text="Save" button-size="xs" class="mr-4" @click="saveEditUser" />
            <Button button-color="white" button-text="Cancel" class="text-gray-900 border-gray-200" button-size="xs"
              @click="cancelEditUser" />
          </div>
        </div>
      </div>
    </template>
  </Modal>
  <Modal v-if="state.deleteUserId" :open="state.deleteUserModalOpen" @on-close="cancelDeleteUser" panel-class="!max-w-md">
    <template #content>
      <div class="grid grid-cols-1">
        <div class="col-span-1">
          <div class="text-md">
            <T keyName="label_confirm_action" />
          </div>
        </div>
        <div class="col-span-1 my-4">
          Are you sure you want to delete the user
          {{ state.deleteUserEmail }}?
        </div>
        <div class="col-span-1">
          <div class="flex justify-end">
            <Button button-color="red" button-text="Delete" button-size="xs" class="mr-4" @click="deleteUser" />
            <Button button-color="white" button-text="Cancel" class="text-gray-900 border-gray-200" button-size="xs"
              @click="cancelDeleteUser" />
          </div>
        </div>
      </div>
    </template>
  </Modal>
  <Modal v-if="state.approveUserId" :open="state.approveUserModalOpen" @on-close="cancelApproveUser"
    panel-class="!max-w-md">
    <template #content>
      <div class="grid grid-cols-1">
        <div class="col-span-1">
          <div class="text-md">
            <T keyName="label_confirm_action" />
          </div>
        </div>
        <div class="col-span-1 my-4">
          Are you sure you want to approve the user registration for
          {{ state.approveUserEmail }}?
        </div>
        <div class="col-span-1">
          <div class="flex justify-end">
            <Button button-color="primary" button-text="Approve" button-size="xs" class="mr-4" @click="approveUser" />
            <Button button-color="white" button-text="Cancel" class="text-gray-900 border-gray-200" button-size="xs"
              @click="cancelApproveUser" />
          </div>
        </div>
      </div>
    </template>
  </Modal>
  <Modal v-if="state.revokeUserId" :open="state.revokeUserModalOpen" @on-close="cancelRevokeUser" panel-class="!max-w-md">
    <template #content>
      <div class="grid grid-cols-1">
        <div class="col-span-1">
          <div class="text-md">
            <T keyName="label_confirm_action" />
          </div>
        </div>
        <div class="col-span-1 my-4">
          Are you sure you want to revoke access for the user
          {{ state.revokeUserEmail }}?
        </div>
        <div class="col-span-1">
          <div class="flex justify-end">
            <Button button-color="red" button-text="Revoke" button-size="xs" class="mr-4" @click="revokeUser" />
            <Button button-color="white" button-text="Cancel" class="text-gray-900 border-gray-200" button-size="xs"
              @click="cancelRevokeUser" />
          </div>
        </div>
      </div>
    </template>
  </Modal>
  <Modal v-if="state.editUserGroupModalOpen" :open="state.editUserGroupModalOpen" @on-close="cancelEditUserGroup"
    panel-class="!max-w-md">
    <template #content>
      <div class="grid grid-cols-1">
        <div class="col-span-1">
          <div class="text-md">
            <T keyName="label_edit_user_group" />:
            {{ state.editingUserGroup.name }}
          </div>
        </div>
        <div class="col-span-1 grid grid-cols-12 py-2 gap-4">
          <div class="col-span-6">
            <Input label="Group name" size="xs" v-model="state.editingUserGroup.name" />
          </div>
          <div class="col-span-6">
            <Checkbox label="Administrative Priviledges" class="h-full pt-5" v-model="state.editingUserGroup.isAdmin" />
          </div>
          <div class="col-span-12">
            <div class="block p-4 bg-white border border-gray-200 shadow mb-4">
              <h5 class="mb-2 text-base font-medium tracking-tight text-gray-900 dark:text-white">
                Pricing Permissions
              </h5>
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-6">
                  <LeadingTrailingInput label="Maximum Discount" input-class="!pl-3" trailing-text="%" color="primary"
                    v-model="state.editingUserGroup.permissions.pricing.maximumDiscount
                      " />
                </div>
                <div class="col-span-6 col-start-1">
                  <Checkbox label="Show cost pricing" class="h-full" v-model="state.editingUserGroup.permissions.pricing.showCostPricing
                    " />
                </div>
                <div class="col-span-6">
                  <Checkbox label="Show sale pricing" class="h-full" v-model="state.editingUserGroup.permissions.pricing.showSalePricing
                    " />
                </div>
              </div>
            </div>
            <div class="block p-4 bg-white border border-gray-200 shadow">
              <h5 class="mb-2 text-base font-medium tracking-tight text-gray-900 dark:text-white">
                Project Permissions
              </h5>
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-6">
                  <Checkbox label="Show all projects" class="h-full" v-model="state.editingUserGroup.permissions.project.showAllProjects
                    " />
                </div>
                <div class="col-span-6">
                  <Checkbox label="Allow custom items" class="h-full" v-model="state.editingUserGroup.permissions.project.allowCustomItem
                    " />
                </div>
              </div>
            </div>
            <div class="block p-4 bg-white border border-gray-200 rounded-lg shadow">
              <h5 class="mb-2 text-base font-medium tracking-tight text-gray-900 dark:text-white">
                Feature Permissions
              </h5>
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-6">
                  <Checkbox label="Allow access to analytics" class="h-full" v-model="state.editingUserGroup.permissions.feature.allowAnalytics
                    " />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-1">
          <div class="flex justify-end">
            <Button button-color="primary" button-text="Save" button-size="xs" class="mr-4" @click="saveEditUserGroup" />
            <Button button-color="white" button-text="Cancel" class="text-gray-900 border-gray-200" button-size="xs"
              @click="cancelEditUserGroup" />
          </div>
        </div>
      </div>
    </template>
  </Modal>
  <Modal v-if="state.deleteUserGroupId" :open="state.deleteUserGroupModalOpen" @on-close="cancelDeleteUserGroup"
    panel-class="!max-w-md">
    <template #content>
      <div class="grid grid-cols-1">
        <div class="col-span-1">
          <div class="text-md">
            <T keyName="label_confirm_action" />
          </div>
        </div>
        <div class="col-span-1 my-4">
          Are you sure you want to delete the user group
          {{ state.deleteUserGroupName }}?
        </div>
        <div class="col-span-1">
          <div class="flex justify-end">
            <Button button-color="red" button-text="Delete" button-size="xs" class="mr-4" @click="deleteUserGroup" />
            <Button button-color="white" button-text="Cancel" class="text-gray-900 border-gray-200" button-size="xs"
              @click="cancelDeleteUserGroup" />
          </div>
        </div>
      </div>
    </template>
  </Modal>
  <Modal v-if="state.newUserGroupModalOpen" :open="state.newUserGroupModalOpen" @on-close="cancelNewUserGroup"
    panel-class="!max-w-md">
    <template #content>
      <div class="grid grid-cols-1">
        <div class="col-span-1">
          <div class="text-md">
            <T keyName="label_new_user_group" />
          </div>
        </div>
        <div class="col-span-1 grid grid-cols-12 py-2 gap-4">
          <div class="col-span-6">
            <Input label="Group name" size="xs" v-model="state.newUserGroup.name" />
          </div>
          <div class="col-span-6">
            <Checkbox label="Administrative Priviledges" class="h-full pt-5" v-model="state.newUserGroup.isAdmin" />
          </div>
          <div class="col-span-12">
            <div class="block p-4 bg-white border border-gray-200 rounded-lg shadow mb-4">
              <h5 class="mb-2 text-base font-medium tracking-tight text-gray-900 dark:text-white">
                Pricing Permissions
              </h5>
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-6">
                  <LeadingTrailingInput label="Maximum Discount" input-class="!pl-3" trailing-text="%" color="primary"
                    v-model="state.newUserGroup.permissions.pricing.maximumDiscount
                      " />
                </div>
                <div class="col-span-6 col-start-1">
                  <Checkbox label="Show cost pricing" class="h-full" v-model="state.newUserGroup.permissions.pricing.showCostPricing
                    " />
                </div>
                <div class="col-span-6">
                  <Checkbox label="Show sale pricing" class="h-full" v-model="state.newUserGroup.permissions.pricing.showSalePricing
                    " />
                </div>
              </div>
            </div>
            <div class="block p-4 bg-white border border-gray-200 rounded-lg shadow">
              <h5 class="mb-2 text-base font-medium tracking-tight text-gray-900 dark:text-white">
                Project Permissions
              </h5>
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-6">
                  <Checkbox label="Show all projects" class="h-full" v-model="state.newUserGroup.permissions.project.showAllProjects
                    " />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-1">
          <div class="flex justify-end">
            <Button button-color="primary" button-text="Save" button-size="xs" class="mr-4" @click="saveNewUserGroup" />
            <Button button-color="white" button-text="Cancel" class="text-gray-900 border-gray-200" button-size="xs"
              @click="cancelEditUserGroup" />
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref } from "vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
  Switch,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import {
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  CheckCircleIcon,
} from "@heroicons/vue/20/solid";
import {
  Bars3Icon,
  BellIcon,
  CogIcon,
  CreditCardIcon,
  KeyIcon,
  SquaresPlusIcon,
  UserCircleIcon,
  XMarkIcon,
  UserGroupIcon,
  UsersIcon,
  BriefcaseIcon,
  ChartPieIcon,
} from "@heroicons/vue/24/outline";
import { reactive, computed, onBeforeMount, watch } from "vue";
import { useUserStore } from "../stores/user";
import Modal from "../components/shared/Modal.vue";
import UsersTable from "../components/dashboard/UsersTable.vue";
import UserGroupsTable from "../components/dashboard/UserGroupsTable.vue";
import Button from "../components/shared/Button.vue";
import Dropdown from "../components/shared/Dropdown.vue";
import Input from "../components/shared/Input.vue";
import Checkbox from "../components/shared/Checkbox.vue";
import LeadingTrailingInput from "../components/shared/LeadingTrailingInput.vue";
import { fetchWrapper } from "../helpers/fetch-wrapper";
import { Chart } from "highcharts-vue";
import * as pricing from "../helpers/pricing";

const userStore = useUserStore();

const dateUtc = new Date();

const analyticsUrl = `${import.meta.env.VITE_API_URL}/api/analytics`;

const state = reactive({
  loading: false,
  subNavigation: [
    { id: 0, name: "Users", href: "#", icon: UsersIcon, current: true },
    {
      id: 1,
      name: "User Groups",
      href: "#",
      icon: UserGroupIcon,
      current: false,
    },
    { id: 2, name: "Analytics", href: "#", icon: ChartPieIcon, current: false },
  ],
  userList: [],
  userTableColumns: [
    {
      value: "email",
      text: "Email Address",
      sortable: true,
    },
    {
      value: "fullName",
      text: "Name",
      sortable: true,
    },
    {
      value: "company",
      text: "Company",
      sortable: true,
    },
    {
      value: "jobTitle",
      text: "Designation",
      sortable: true,
    },
    {
      value: "userGroup",
      text: "Group",
      sortable: true,
    },
    {
      value: "verified",
      text: "Verified",
    },
    {
      value: "approved",
      text: "Approved",
    },
    {
      value: "actions",
      text: "",
      sortable: false,
    },
  ],
  userGroupList: [],
  userGroupTableColumns: [
    {
      value: "name",
      text: "Name",
      sortable: true,
    },
    {
      value: "maxDiscount",
      text: "Max. Discount",
      sortable: true,
      width: 130,
    },
    {
      value: "showCostPrice",
      text: "Show Cost Price",
      width: 120,
    },
    {
      value: "showSalePrice",
      text: "Show Sale Price",
      width: 120,
    },
    {
      value: "allowCustomItem",
      text: "Allow Custom Item",
      width: 120,
    },
    {
      value: "showAnalytics",
      text: "Allow Analytics",
      width: 120,
    },
    {
      value: "actions",
      text: "",
    },
  ],
  editUserModalOpen: false,
  editUserGroupModalOpen: false,
  deleteUserGroupModalOpen: false,
  deleteUserModalOpen: false,
  deleteUserId: null,
  deleteUserEmail: null,
  approveUserModalOpen: false,
  revokeUserModalOpen: false,
  newUserGroupModalOpen: false,
  editUserId: null,
  editingUser: null,
  editingUserGroup: null,
  approveUserId: null,
  revokeUserId: null,
  approveUserEmail: null,
  revokeUserEmail: null,
  editUserGroupId: null,
  deleteUserGroupId: null,
  deleteUserGroupName: null,
  newUserGroup: {
    name: "New user group",
    isAdmin: false,
    permissions: {
      pricing: {
        maximumDiscount: 0,
        showCostPricing: false,
        showSalePricing: false,
      },
      project: {
        showAllProjects: false,
      },
    },
  },
  analytics: {
    loading: false,
    data: {
      allRegionsStatusesYtd: [],
      productCoverageYtd: [],
      topContractors: [],
      topConsultants: [],
      topProjects: [],
      productCoverage: [],
    },
    filters: {
      endDate: dateUtc.toISOString().split("T")[0],
      startDate: new Date(dateUtc.setDate(dateUtc.getDate() - 30))
        .toISOString()
        .split("T")[0],
      selectedLocation: null,
      locations: [],
      selectedStatus: null,
      statuses: [],
      selectedUser: null,
      users: [],
    },
  },
});

const allStatusRegionsChartOptions = computed(() => {
  let tooltip = {
    headerFormat: `<span style="font-size:10px">{point.key}</span><table>`,
    pointFormatter: function () {
      let self = this;
      let tooltipText = [
        `<tr><td style="color:${self.series.color};padding:0"><b>Total ${self.series.name}: </b></td>` +
        `<td style="padding-left:10px"><b>${pricing.toCurrencyString(
          self.y
        )}</b></td></tr>`,
      ];
      return tooltipText.join("");
    },
    footerFormat: "</table>",
    shared: false,
    useHTML: true,
  };
  return createChartOptions(
    state.analytics.data.allRegionsStatuses,
    "All Regions & Statuses, YTD, Value (AED)",
    tooltip
  );
});

const topProjectsChartOptions = computed(() => {
  let tooltip = {
    headerFormat: `<span style="font-size:10px">{point.key}</span><table>`,
    pointFormatter: function () {
      let self = this;
      let tooltipText = [
        `<tr><td style="color:${self.series.color};padding:0"><b>Total ${self.series.name}: </b></td>` +
        `<td style="padding-left:10px"><b>${pricing.toCurrencyString(
          self.y
        )}></td></tr>`,
      ];

      self.series.options.analyticsItems.forEach((d) => {
        if (d.location == self.category)
          tooltipText.push(
            `<tr><td style="color:{series.color};padding:0">${d.projectName}: </td>` +
            `<td style="padding-left:10px"><b>${pricing.toCurrencyString(
              d.totalPrice
            )}</b></td></tr>`
          );
      });
      return tooltipText.join("");
    },
    footerFormat: "</table>",
    shared: false,
    useHTML: true,
  };
  return createChartOptions(
    state.analytics.data.topProjects,
    "Top 10 Projects, Value (AED)",
    tooltip
  );
});

const productCoverageChartOptions = computed(() => {
  let series = [
    {
      name: "Products",
      colorByPoint: true,
      data: state.analytics.data.productCoverage.map((d) => {
        return {
          name: d.rangeId,
          y: d.totalQuantity,
          custom: d,
        };
      }),
    },
  ];

  let opts = {
    chart: {
      type: "pie",
      style: {
        fontFamily: "Inter",
      },
    },
    credits: { enabled: false },
    title: {
      text: "Product Coverage",
    },
    xAxis: {
      crosshair: true,
    },
    series,
    tooltip: {
      headerFormat: `<span style="font-size:10px">{point.key}</span>`,
      pointFormatter: function () {
        let self = this;
        let tooltipText = [
          `<table>` +
          `<tr>
              <td style="color:${self.series.color
          };padding:0"><b>Total Qauntity: </b></td>
              <td style="padding-left:10px"><b>${self.y}</b></td>
              </tr>
              <tr>
                <td style="color:${self.series.color
          };padding:0"><b>Total Value: </b></td>
              <td style="padding-left:10px"><b>${pricing.toCurrencyString(
            self.custom.totalPrice
          )}</b></td>
                </tr>
              `,
        ];
        console.log(self);
        return tooltipText.join("");
      },
      footerFormat: "</table>",
      shared: false,
      useHTML: true,
    },
  };
  return opts;
});

const productCoverageYtdChartOptions = computed(() => {
  let series = [
    {
      name: "Products",
      colorByPoint: true,
      data: state.analytics.data.productCoverageYtd.map((d) => {
        return {
          name: d.rangeId,
          y: d.totalQuantity,
          custom: d,
        };
      }),
    },
  ];

  let opts = {
    chart: {
      type: "pie",
      style: {
        fontFamily: "Inter",
      },
    },
    credits: { enabled: false },
    title: {
      text: "Product Coverage, All Regions & Statuses, YTD",
    },
    xAxis: {
      crosshair: true,
    },
    series,
    tooltip: {
      headerFormat: `<span style="font-size:10px">{point.key}</span>`,
      pointFormatter: function () {
        let self = this;
        let tooltipText = [
          `<table>` +
          `<tr>
              <td style="color:${self.series.color
          };padding:0"><b>Total Qauntity: </b></td>
              <td style="padding-left:10px"><b>${pricing.toCurrencyString(
            self.y
          )}</b></td>
              </tr>
              <tr>
                <td style="color:${self.series.color
          };padding:0"><b>Total Value: </b></td>
              <td style="padding-left:10px"><b>${self.custom.totalQuantity
          }</b></td>
                </tr>
              `,
        ];
        console.log(self);
        return tooltipText.join("");
      },
      footerFormat: "</table>",
      shared: false,
      useHTML: true,
    },
  };
  return opts;
});

const topContractorsChartOptions = computed(() => {
  let tooltip = {
    headerFormat: `<span style="font-size:10px">{point.key}</span>`,
    pointFormatter: function () {
      let self = this;
      let tooltipText = [
        `<table>` +
        `<tr><td style="color:${self.series.color};padding:0"><b>Total ${self.series.name}: </b></td>` +
        `<td style="padding-left:10px"><b>${pricing.toCurrencyString(
          self.y
        )}</b></td></tr>`,
      ];

      self.series.options.analyticsItems.forEach((d) => {
        {
          if (d.location == self.category)
            tooltipText.push(
              `<tr><td style="color:{series.color};padding:0"><b>${d.contractor ?? "Unknown"
              }</b>: </td>` +
              `<td style="padding-left:10px"><b>${pricing.toCurrencyString(
                d.totalPrice
              )}</b></td></tr>`
            );
        }
      });
      return tooltipText.join("");
    },
    footerFormat: "</table>",
    shared: false,
    useHTML: true,
  };

  return createChartOptions(
    state.analytics.data.topContractors,
    "Top Contractors, Value (AED)",
    tooltip
  );
});

const topConsultantsChartOptions = computed(() => {
  let tooltip = {
    // headerFormatter: function () {
    //   return `<span style="font-size:10px">${this.series.options.analyticsItems[0].contractor}</span><table>`;
    // },
    headerFormat: `<span style="font-size:10px">{point.key}</span>`,
    pointFormatter: function () {
      let self = this;
      let tooltipText = [
        `<table>` +
        `<tr><td style="color:${self.series.color};padding:0"><b>Total ${self.series.name}: </b></td>` +
        `<td style="padding-left:10px"><b>${pricing.toCurrencyString(
          self.y
        )}</b></td></tr>`,
      ];

      self.series.options.analyticsItems.forEach((d) => {
        {
          if (d.location == self.category)
            tooltipText.push(
              `<tr><td style="color:{series.color};padding:0"><b>${d.consultant ?? "Unknown"
              }</b>: </td>` +
              `<td style="padding-left:10px"><b>${pricing.toCurrencyString(
                d.totalPrice
              )}</b></td></tr>`
            );
        }
      });
      return tooltipText.join("");
    },
    footerFormat: "</table>",
    shared: false,
    useHTML: true,
  };
  return createChartOptions(
    state.analytics.data.topConsultants,
    "Top Consultants, Value (AED)",
    tooltip
  );
});

const summaryAllRegionsStatusesYtd = computed(() => {
  let statuses = new Set(
    state.analytics.data.allRegionsStatusesYtd.map((d) => d.status)
  );

  let summary = [];

  statuses.forEach((s) => {
    summary.push({
      status: s,
      total:
        state.analytics.data.allRegionsStatusesYtd
          .filter((d) => d.status == s)
          .map((d) => d.totalPrice)
          .reduce((a, b) => a + b, 0) / 1000000.0,
    });
  });

  return summary;
});

const totalValueAllRegionsStatusesYtd = computed(() => {
  return summaryAllRegionsStatusesYtd.value
    .map((d) => d.total)
    .reduce((a, b) => a + b, 0);
});

const summaryTopProjectsStatuses = computed(() => {
  let statuses = new Set(
    state.analytics.data.topProjects.map((d) => d.status)
  );

  let summary = [];

  statuses.forEach((s) => {
    summary.push({
      status: s,
      total:
        state.analytics.data.topProjects
          .filter((d) => d.status == s)
          .map((d) => d.totalPrice)
          .reduce((a, b) => a + b, 0) / 1000000.0,
    });
  });

  return summary;
});

const totalValueTopProjectsStatuses = computed(() => {
  return summaryTopProjectsStatuses.value
    .map((d) => d.total)
    .reduce((a, b) => a + b, 0);
});

const summaryTopContractorsStatuses = computed(() => {
  let statuses = new Set(
    state.analytics.data.topContractors.map((d) => d.status)
  );

  let summary = [];

  statuses.forEach((s) => {
    summary.push({
      status: s,
      total:
        state.analytics.data.topContractors
          .filter((d) => d.status == s)
          .map((d) => d.totalPrice)
          .reduce((a, b) => a + b, 0) / 1000000.0,
    });
  });

  return summary;
});

const totalValueTopContractorsStatuses = computed(() => {
  return summaryTopContractorsStatuses.value
    .map((d) => d.total)
    .reduce((a, b) => a + b, 0);
});

const summaryTopConsultantsStatuses = computed(() => {
  let statuses = new Set(
    state.analytics.data.topConsultants.map((d) => d.status)
  );

  let summary = [];

  statuses.forEach((s) => {
    summary.push({
      status: s,
      total:
        state.analytics.data.topConsultants
          .filter((d) => d.status == s)
          .map((d) => d.totalPrice)
          .reduce((a, b) => a + b, 0) / 1000000.0,
    });
  });

  return summary;
});

const totalValueTopConsultantsStatuses = computed(() => {
  return summaryTopConsultantsStatuses.value
    .map((d) => d.total)
    .reduce((a, b) => a + b, 0);
});

const createChartOptions = (data, title, tooltip) => {
  let categories = [...new Set(data.map((d) => d.location))];
  let series = [];

  data.forEach((d) => {
    let categoryIndex = categories.indexOf(d.location);

    var statusSeries = series.find((s) => s.name == d.status);
    if (statusSeries) {
      statusSeries.data[categoryIndex] += d.totalPrice;
      statusSeries.analyticsItems.push(d);
    } else {
      var newSeries = {
        name: d.status,
        data: [],
        analyticsItems: [d],
      };
      for (let i = 0; i < categories.length; i++) {
        newSeries.data.push(0);
      }
      newSeries.data[categoryIndex] = d.totalPrice;
      series.push(newSeries);
    }
  });

  let opts = {
    chart: {
      type: "column",
      style: {
        fontFamily: "Inter",
      },
    },
    credits: { enabled: false },
    title: {
      text: title,
    },
    xAxis: {
      // categories: state.analytics.filters.locations
      //   .filter((l) => l.value !== "")
      //   .map((l) => l.text),
      categories,
      crosshair: true,
    },
    tooltip,
    series,
  };
  return opts;
};

const userGroupOptions = computed(() => {
  var opts = [];
  state.userGroupList.forEach((ug) => {
    opts.push({ text: ug.name, value: ug.id });
  });
  return opts;
});

const actionEditUserClick = (userId) => {
  state.editUserId = userId;
  state.editingUser = { ...state.userList.find((u) => u.id == userId) };
  state.editUserModalOpen = true;
};

const cancelEditUser = () => {
  state.editUserModalOpen = false;
  state.editUserId = null;
  state.editingUser = null;
};

const actionApproveUserClick = (user) => {
  state.approveUserId = user.id;
  state.approveUserEmail = user.email;
  state.approveUserModalOpen = true;
};

const cancelApproveUser = () => {
  state.approveUserModalOpen = false;
  state.approveUserId = null;
  state.approveUserEmail = null;
};

const actionRevokeUserClick = (user) => {
  state.revokeUserId = user.id;
  state.revokeUserEmail = user.email;
  state.revokeUserModalOpen = true;
};

const cancelRevokeUser = () => {
  state.revokeUserModalOpen = false;
  state.revokeUserId = null;
  state.revokeUserEmail = null;
};

const actionDeleteUserClick = (user) => {
  state.deleteUserId = user.id;
  state.deleteUserEmail = user.email;
  state.deleteUserModalOpen = true;
};

const cancelDeleteUser = () => {
  state.deleteUserModalOpen = false;
  state.deleteUserId = null;
  state.deleteUserEmail = null;
};

const actionEditUserGroupClick = (userGroupId) => {
  state.editUserGroupId = userGroupId;
  state.editingUserGroup = state.userGroupList.find((u) => u.id == userGroupId);
  state.editUserGroupModalOpen = true;
};

const cancelEditUserGroup = () => {
  state.editUserGroupModalOpen = false;
  state.editingUserGroup = null;
  state.editUserGroupId = null;
};

const cancelNewUserGroup = () => {
  state.newUserGroupModalOpen = false;
  state.newUserGroup = {
    name: "New user group",
    isAdmin: false,
    permissions: {
      pricing: {
        maximumDiscount: 0,
        showCostPricing: false,
        showSalePricing: false,
      },
      project: {
        showAllProjects: false,
      },
    },
  };
};

const actionDeleteUserGroupClick = (userGroupId) => {
  state.deleteUserGroupId = userGroupId;
  state.deleteUserGroupModalOpen = true;
};

const cancelDeleteUserGroup = () => {
  state.deleteUserGroupModalOpen = false;
  state.deleteUserGroupId = null;
  state.deleteUserGroupName = null;
};

const saveNewUserGroup = () => {
  state.newUserGroupModalOpen = false;
  state.loading = true;
  userStore.addUserGroup(state.newUserGroup).then(() => {
    state.newUserGroup = {
      name: "New user group",
      isAdmin: false,
      permissions: {
        pricing: {
          maximumDiscount: 0,
          showCostPricing: false,
          showSalePricing: false,
        },
        project: {
          showAllProjects: false,
        },
      },
    };
    updateData();
  });
};

const saveEditUser = () => {
  state.editUserModalOpen = false;
  state.loading = true;
  userStore
    .setUserGroup(state.editingUser.id, state.editingUser.userGroupId)
    .then(() => {
      state.editingUser = null;
      state.editUserId = null;
      updateData();
    });
};

const saveEditUserGroup = () => {
  state.editUserGroupModalOpen = false;
  state.loading = true;
  userStore.updateUserGroup(state.editingUserGroup).then(() => {
    state.editingUserGroup = null;
    state.editUserGroupId = null;
    updateData();
  });
};

const approveUser = () => {
  state.approveUserModalOpen = false;
  state.loading = true;
  userStore.approveUser(state.approveUserId).then(() => {
    state.approveUserId = null;
    state.approveUserEmail = null;
    updateData();
  });
};

const revokeUser = () => {
  state.revokeUserModalOpen = false;
  state.loading = true;
  userStore.revokeUser(state.revokeUserId).then(() => {
    state.revokeUserId = null;
    state.revokeUserEmail = null;
    updateData();
  });
};

const deleteUser = () => {
  state.deleteUserModalOpen = false;
  state.loading = true;
  userStore.deleteUser(state.deleteUserId).then(() => {
    state.deleteUserId = null;
    state.deleteUserEmail = null;
    updateData();
  });
};

const deleteUserGroup = () => {
  state.deleteUserGroupModalOpen = false;
  state.loading = true;
  userStore.deleteUserGroup(state.deleteUserGroupId).then(() => {
    state.deleteUserGroupId = null;
    state.deleteUserGroupName = null;
    updateData();
  });
};

const currentPageId = computed(() => {
  return state.subNavigation.find((item) => {
    return item.current == true;
  })?.id;
});

const setCurrentPage = (newItem) => {
  state.subNavigation.forEach((item) => {
    item.current = item.id == newItem.id;
  });
};

const getLocations = () => {
  return fetchWrapper.get(`${analyticsUrl}/get-locations`);
};

const getStatuses = () => {
  return fetchWrapper.get(`${analyticsUrl}/get-statuses`);
};

const getUsers = () => {
  return fetchWrapper.get(`${analyticsUrl}/get-users`);
};

const getAllStatusesRegionsYtd = () => {
  return fetchWrapper.get(`${analyticsUrl}/get-data-ytd-all`);
};

const getProductCoverageYtd = () => {
  return fetchWrapper.get(`${analyticsUrl}/get-product-coverage-ytd-all`);
};

const getFilteredData = () => {
  state.analytics.loading = true;
  let dataRequest = {
    location: state.analytics.filters.selectedLocation,
    userId: state.analytics.filters.selectedUser,
    status: state.analytics.filters.selectedStatus,
    startDate: state.analytics.filters.startDate,
    endDate: state.analytics.filters.endDate,
  };

  fetchWrapper
    .post(`${import.meta.env.VITE_API_URL}/api/analytics/get-data`, dataRequest)
    .then((response) => {
      state.analytics.loading = false;
      state.analytics.data.topProjects = response.topProjects;
      state.analytics.data.topContractors = response.topContractors;
      state.analytics.data.topConsultants = response.topConsultants;
      state.analytics.data.productCoverage = response.productCoverage;
    });
};

const selectedAnalyticsFilters = computed(() => {
  const selectedFilters = {
    location: state.analytics.filters.selectedLocation,
    status: state.analytics.filters.selectedStatus,
    user: state.analytics.filters.selectedUser,
    startDate: state.analytics.filters.startDate,
    endDate: state.analytics.filters.endDate,
  };
  return selectedFilters;
});

watch(
  () => selectedAnalyticsFilters.value,
  () => {
    getFilteredData();
  },
  { immediate: true }
);

const getAnalyticsFilters = () => {
  state.analytics.loading = true;
  Promise.all([
    getLocations(),
    getStatuses(),
    getUsers(),
    getAllStatusesRegionsYtd(),
    getProductCoverageYtd(),
  ]).then((values) => {
    state.analytics.filters.locations = values[0];
    state.analytics.filters.statuses = values[1];
    state.analytics.filters.users = values[2];
    state.analytics.data.allRegionsStatuses = values[3];
    state.analytics.data.productCoverageYtd = values[4];
    state.analytics.loading = false;
  });
};

const updateData = () => {
  state.loading = true;
  Promise.all([userStore.getUsers(), userStore.getUserGroups()]).then(
    (values) => {
      state.userList = values[0];
      state.userGroupList = values[1];
      state.loading = false;
    }
  );
};

onBeforeMount(() => {
  updateData();
  getAnalyticsFilters();
});
</script>
