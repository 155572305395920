<template>
  <div class="relative flex items-start">
    <div class="flex h-5 items-center">
      <input
        type="checkbox"
        class="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
        :class="props.checkboxClass"
        :value="props.modelValue"
        @change="emitInput($event)"
        :checked="props.modelValue"
      />
    </div>
    <div class="ml-3 text-sm">
      <label class="font-medium text-gray-700 text-sm" :class="props.labelClass"
        ><T :keyName="props.label"
      /></label>
    </div>
  </div>
</template>

<script setup>
const props = defineProps([
  "label",
  "modelValue",
  "labelClass",
  "checkboxClass",
]);
const emits = defineEmits(["update:modelValue"]);
const emitInput = (evt) => {
  emits("update:modelValue", !props.modelValue);
};
</script>

<style scoped></style>
