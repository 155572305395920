<template>
  <div v-if="state.loading" class="absolute top-0 bottom-0 left-0 right-0 backdrop-blur-1 z-50 h-[50vh]">
    <div class="m-auto block w-fit relative top-[48%]">
      <svg class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-primary-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </div>
  </div>
  <div v-else class="grid grid-cols-12 gap-4 xs:px-2">
    <div class="col-span-3 col-start-1 xs:col-span-12">
      <div class="divide-y divide-gray-200 bg-white shadow rounded-lg">
        <div class="px-4 py-2 text-md">Selection</div>
        <div class="px-4 py-2">
          <Dropdown class="col-span-1 mb-1" :options="calculationMethods" label="input_label_calculation_method" @change="calculationMethodChanged" v-model="state.calculationMethod" />

          <div class="grid grid-cols-12">
            <Dropdown
              class="col-span-12 text-sm mb-1"
              :options="selectionInformation.noiseSources.items"
              label="input_label_noise_source"
              v-model="selectionInformation.noiseSources.selectedValue"
              @change="calculateTreatmentRequired()"
            />
            <div class="col-span-12 space-x-2 grid grid-cols-2">
              <InputDropdown
                label="input_label_volume"
                :options="volumeFlowUnits"
                placeholder="0.00"
                :input-value="selectionInformation.volume"
                :select-value="selectionInformation.volumeUnit"
                @input-changed="volumeChanged"
                @select-changed="volumeUnitChanged"
                class="col-span-1 mb-1"
              />

              <InputDropdown
                label="input_label_pressure"
                :options="pressureUnits"
                placeholder="0"
                :input-value="selectionInformation.pressure"
                :select-value="selectionInformation.pressureUnit"
                @input-changed="pressureChanged"
                @select-changed="pressureUnitChanged"
                class="col-span-1 gap-2 mb-1"
              />
            </div>

            <div class="col-span-6 text-sm">
              <label class="inline-flex">Motor Power</label>
              <div class="flex items-center align-middle w-full" :class="{ disabled: state.calculationMethod == 'dba_at_distance' || state.calculationMethod == 'direct_acoustic' }">
                <LeadingTrailingInput
                  :readonly="state.calculationMethod == 'dba_at_distance' || state.calculationMethod == 'direct_acoustic'"
                  trailing-text="kW"
                  input-class="!text-xs !pl-5"
                  input-type="text"
                  placeholder="0"
                  v-model.number="selectionInformation.motorPowerKw"
                  @input-change="calculateTreatmentRequired(false, false, true)"
                />
              </div>
            </div>

            <div class="grid grid-cols-2 space-x-2 col-span-12">
              <div class="col-span-1 text-sm mt-1 disabled">
                <label class="inline-flex">Sound Level dBW</label>
                <div class="flex items-center align-middle w-full">
                  <LeadingTrailingInput
                    readonly
                    trailing-text="dB"
                    input-class="!text-xs !pl-5"
                    input-type="text"
                    placeholder="0"
                    v-model.number="selectionInformation.soundLevelSwl"
                    @input-change="calculateTreatmentRequired(true, false, false)"
                  />
                </div>
              </div>
            
              <div class="col-span-1 text-sm my-1">
                <label class="inline-flex">dBW(A) @ Source</label>
                <div class="flex items-center align-middle w-full disabled">
                  <LeadingTrailingInput trailing-text="dBW(A)" input-class="!text-xs !pl-5 " input-type="text" placeholder="0" readonly v-model.number="selectionInformation.soundDbaSource" />
                </div>
              </div>
            </div>

            <div class="grid grid-cols-2 space-x-2 col-span-12">

              <div class="col-span-1 text-sm mt-1">
                <label class="inline-flex">Sound Level dBA</label>
                <div class="flex items-center align-middle w-full" :class="{ disabled: state.calculationMethod == 'beranek' || state.calculationMethod == 'direct_acoustic' }">
                  <LeadingTrailingInput
                    :readonly="state.calculationMethod == 'beranek' || state.calculationMethod == 'direct_acoustic'"
                    trailing-text="dBA"
                    input-class="!text-xs !pl-5"
                    input-type="text"
                    placeholder="0"
                    v-model.number="selectionInformation.soundLevelDba"
                    @input-change="calculateTreatmentRequired(false, true, false)"
                  />
                </div>
              </div>

              <div class="col-span-1 text-sm my-1">
                <label class="inline-flex">Distance</label>
                <div class="flex items-center align-middle w-full" :class="{ disabled: state.calculationMethod == 'beranek' || state.calculationMethod == 'direct_acoustic' }">
                  <LeadingTrailingInput
                    :readonly="state.calculationMethod == 'beranek' || state.calculationMethod == 'direct_acoustic'"
                    trailing-text="m"
                    input-class="!text-xs !pl-5"
                    input-type="text"
                    placeholder="0"
                    v-model.number="selectionInformation.soundDistance"
                    @input-change="calculateTreatmentRequired()"
                  />
                </div>
              </div>

            </div>

            <!-- 
            <div class="col-span-12 text-sm mt-1">
              <Dropdown
                :options="roomReverberationOptions"
                label="input_label_room_type"
                v-model="state.roomReverberation"
              />
            </div> -->

            <Button
              class="my-2 p-2 text-sm col-span-12 focus:border-gray-500 focus:ring-gray-500 focus:ring-0 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500 bg-white cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 rounded-full border-gray-500 border-opacity-40 border-solid border text-gray-600"
              button-text="button_text_ductwork_configurator"
              button-size="sm"
              @click="toggleDuctworkConfigModal"
            />

            <DuctworkConfigModal :show="state.showDuctworkConfigModal" @close="toggleDuctworkConfigModal" @apply-changes="applyDuctworkConfigChanges"> </DuctworkConfigModal>

            <div class="col-span-12 text-sm">
              <div class="gap-2 mb-1">
                <RadioPanel
                  name="types_of_environment"
                  :options="state.typesOfEnivronmentOptions"
                  label="input_label_types_of_environment"
                  label-class="mb-1"
                  v-model="selectionInformation.typesOfEnvironment"
                />
              </div>
            </div>
            <div class="col-span-12 text-sm">
              <label class="inline-flex">Outlet Size</label>
              <div class="flex items-center space-x-2">
                <div class="flex items-center align-middle w-20">
                  <LeadingTrailingInput trailing-text="mm" input-class="!text-xs !pl-5" input-type="text" placeholder="0" v-model="state.outlet.length" @input="calculateTreatmentRequired()" />
                </div>
                <label class="text-right align-middle">x</label>
                <div class="align-middle flex items-center w-20">
                  <LeadingTrailingInput trailing-text="mm" input-type="text" input-class="!text-xs !pl-5" placeholder="0" v-model="state.outlet.width" @input="calculateTreatmentRequired()" />
                </div>
              </div>
            </div>
            <div class="col-span-12 text-sm mt-1">
              <div class="grid grid-cols-2">
                <Dropdown
                  label="input_label_outlet_type"
                  :options="outletTypesOptions"
                  v-model="selectionInformation.outletTypes"
                  class="col-span-4"
                  :class="{
                    disabled: selectionInformation.typesOfEnvironment != 'in_room',
                  }"
                />
              </div>
            </div>

            <div class="col-span-12 text-sm">
              <div class="gap-2 mb-1 mt-1">
                <RadioPanel
                  name="types_of_attenuator"
                  :options="state.typesOfAttenuatorOptions"
                  label="input_label_types_of_attenuator"
                  label-class="mb-1 mt-1"
                  v-model="selectionInformation.typesOfAttenuator"
                />
              </div>
            </div>
            <div v-if="selectionInformation.typesOfAttenuator == 'circular'" class="col-span-12 text-sm">
              <label class="inline-flex">Duct Diameter at Attenuator</label>
              <div class="flex items-center space-x-3">
                <div class="flex items-center align-middle w-20">
                  <LeadingTrailingInput trailing-text="mm" input-class="!text-xs !pl-5" input-type="text" placeholder="0" v-model="state.duct.diameter" @input="calculateTreatmentRequired()" />
                </div>
              </div>
            </div>
            <div v-if="selectionInformation.typesOfAttenuator == 'rectangular'" class="col-span-12 text-sm">
              <label class="inline-flex">Duct Width at Attenuator</label>
              <div class="flex items-center space-x-2">
                <div class="flex items-center align-middle w-20">
                  <LeadingTrailingInput trailing-text="mm" input-type="text" input-class="!text-xs !pl-5" placeholder="0" v-model="state.duct.width" @input="calculateTreatmentRequired()" />
                </div>
              </div>
              <label class="inline-flex">Duct Height at Attenuator</label>
              <div class="flex items-center space-x-2">
                <div class="flex items-center align-middle w-20">
                  <LeadingTrailingInput trailing-text="mm" input-type="text" input-class="!text-xs !pl-5" placeholder="0" v-model="state.duct.height" @input="calculateTreatmentRequired()" />
                </div>
              </div>
            </div>
            <div class="grid grid-cols-4 col-span-12 gap-3 mt-3">
              <Button
                class="col-span-3 text-sm focus:border-gray-500 focus:ring-gray-500 focus:ring-0 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500 bg-white border cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 rounded-full border-gray-600 text-gray-600"
                button-text="button_text_load_test_presets"
                button-size="sm"
                @click="loadTestPresets"
              />
              <Button v-if="!state.selecting" class="col-span-2 col-start-4" button-color="primary" button-text="button_text_select" button-size="sm" @click="startSelection" />
              <Button v-else class="col-span-2 col-start-4" button-color="primary" button-text="button_text_stop" button-size="sm" @click="stopSelection" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-9 xs:col-span-12">
      <div class="divide-y divide-gray-200 bg-white shadow rounded-lg">
        <div class="flex justify-between px-4 py-2 text-md rounded-lg ">
          <!-- <T keyName="card_title_acoustics" /> -->
          <div>
            Acoustics
          </div>
          <div class="flex gap-x-1 items-center">
            <label for="debug_checkbox">enable debug?</label>
            <input type="checkbox" id="debug_checkbox" v-model="state.debug_checked" />
          </div>
        </div>
        <div class="px-4 py-2">
          <div class="flex">
            <div class="w-1/3"></div>
            <div class="w-2/3">
              <div class="grid grid-cols-9 gap-2">
                <div class="col-span-1 text-center text-xs">C</div>
                <div v-for="i in state.swlFan" class="col-span-1 text-center text-xs" :key="i">
                  {{ i.title }}
                </div>
              </div>
            </div>
          </div>

          <div class="flex text-sm pb-2">
            <div class="w-1/3">Source Spectrum</div>
            <div class="w-2/3 align-middle">
              <div class="grid grid-cols-9 gap-2" :class="{ disabled: state.calculationMethod == 'dba_at_distance' || state.calculationMethod == 'beranek' }">
                <div class="col-span-1">
                  <Input
                    v-model.number="selectedNoiseSource.constant"
                    placeholder="0"
                    class="col-span-1"
                    input-class="!text-xs text-center"
                    :readonly="state.calculationMethod == 'beranek' || state.calculationMethod == 'dba_at_distance'"
                  />
                </div>
                <Input v-for="(v, i) in selectedNoiseSource.db" v-model.number="selectedNoiseSource.db[i]" placeholder="0" class="col-span-1" input-class="!text-xs text-center" :key="i" />
              </div>
            </div>
          </div>

          <div class="flex text-sm">
            <div class="w-1/3 align-middle">
              <label class="text-right align-middle">SWL at fan(db) </label>
            </div>
            <div class="w-2/3 align-middle" :class="{ disabled: state.calculationMethod == 'dba_at_distance' || state.calculationMethod == 'beranek' }">
              <div class="grid grid-cols-9 gap-2">
                <div class="col-span-1"></div>
                <Input
                  :readonly="state.calculationMethod == 'dba_at_distance' || state.calculationMethod == 'beranek'"
                  v-for="v in state.swlFan"
                  v-model.number="v.value"
                  placeholder="0"
                  class="col-span-1"
                  input-class="!text-xs text-center"
                  :key="v"
                />
              </div>
            </div>
          </div>
          <div class="flex items-center text-sm my-1">
            <div class="w-1/3 align-middle">
              <label class="text-right align-middle">Duct Losses </label>
            </div>
            <div class="w-2/3">
              <div class="grid grid-cols-9 gap-2">
                <div class="col-span-1"></div>
                <div v-for="i in state.ductLosses" class="col-span-1 text-center text-xs" placeholder="0" :key="i">
                  {{ i.toFixed(1) }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="state.debug_checked" class="flex items-center text-sm my-1 bg-gray-600 text-white">
            <div class="w-1/3 align-middle">
              <label class="text-right align-middle italic ml-4">Less Outlet Corrections</label>
            </div>
            <div class="w-2/3">
              <div class="grid grid-cols-9 gap-2">
                <div class="col-span-1"></div>
                <div v-for="i in state.outletCorrections" class="col-span-1 text-center text-xs" placeholder="0" :key="i">
                  {{ i.toFixed(1) }}
                </div>
              </div>
            </div>
          </div>

          <div class="flex items-center text-sm my-1">
            <div class="w-1/3 align-middle">
              <label class="text-right align-middle">Resultant SWL</label>
            </div>
            <div class="w-2/3">
              <div class="grid grid-cols-9 gap-2">
                <div class="col-span-1"></div>
                <div v-for="i in state.requirements" class="col-span-1 text-center text-xs" placeholder="0" :key="i">
                  {{ i.toFixed(1) }}
                </div>
              </div>
            </div>
          </div>

          <div class="flex items-end align-middle mt-2">            
            <div class="flex align-middle items-center w-1/3 justify-between">
              <div class="text-sm">Requirements</div>
              <DropdownCheckbox :items="selectionInformation.noiseCriteria" text-key="criteriaName" value-key="criteriaName" v-model="selectedNoiseCriteria" />
            </div>
            
            <div class="w-2/3 align-middle">
              <div class="grid grid-cols-9 gap-2">
                <div class="col-span-1"></div>
                <Input v-model="selectedNoiseCriteria.db63" class="col-span-1" input-class="!text-xs text-center" />
                <Input v-model="selectedNoiseCriteria.db125" class="col-span-1" input-class="!text-xs text-center" />
                <Input v-model="selectedNoiseCriteria.db250" class="col-span-1" input-class="!text-xs text-center" />
                <Input v-model="selectedNoiseCriteria.db500" class="col-span-1" input-class="!text-xs text-center" />
                <Input v-model="selectedNoiseCriteria.db1k" class="col-span-1" input-class="!text-xs text-center" />
                <Input v-model="selectedNoiseCriteria.db2k" class="col-span-1" input-class="!text-xs text-center" />
                <Input v-model="selectedNoiseCriteria.db4k" class="col-span-1" input-class="!text-xs text-center" />
                <Input v-model="selectedNoiseCriteria.db8k" class="col-span-1" input-class="!text-xs text-center" />
              </div>
            </div>
          </div>
          <div class="flex items-center text-sm mt-2">
            <div class="w-1/3 align-middle">
              <label class="text-right align-middle">Treatment Required</label>
            </div>
            <div class="w-2/3 align-middle">
              <div class="grid grid-cols-9 gap-2">
                <div class="col-span-1"></div>
                <Input v-for="t in state.treatmentRequired" :key="t" :input-value="t.toFixed(1)" placeholder="0" class="col-span-1" input-class="!text-xs text-center" :readonly="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectionInformation.typesOfEnvironment == 'in_room'" class="grid grid-cols-12 pt-4">
        <div class="col-span-6">
          <div class="divide-y divide-gray-200 bg-white shadow rounded-lg">
            <div class="px-4 py-2 text-md rounded-lg">
              <T keyName="card_title_room_configuration" />
            </div>
            <div class="px-4 py-2 text-sm space-y-2">
              <div class="align-middle flex items-center justify-between">
                <label class="inline-flex"> Sound reaching room </label>
                <div class="inline-flex w-20">
                  <LeadingTrailingInput trailing-text="%" input-class="!text-xs text-center !pl-4" v-model="state.soundReaching" placeholder="100" />
                </div>
              </div>
              <div class="align-middle flex items-center justify-between">
                <label class="inline-flex">
                  <T keyName="input_label_room_type" />
                </label>
                <div class="inline-flex w-max">
                  <Dropdown :options="roomReverberationOptions" v-model="state.roomReverberation" />
                </div>
              </div>
              <div class="align-middle flex items-center justify-between">
                <label class="inline-flex"> Reverberation Time</label>
                <div class="inline-flex w-20">
                  <LeadingTrailingInput trailing-text="s" input-class="!text-xs text-center" v-model="selectedRoomReverberation.reverberation" placeholder="0" />
                </div>
              </div>
              <div class="flex items-center align-middle justify-between">
                <label class="text-left"> Room Size</label>
                <div class="flex items-center space-x-2">
                  <div class="flex items-center align-middle w-20">
                    <LeadingTrailingInput trailing-text="m" input-class="!text-xs text-center !pl-4" placeholder="0" v-model="state.roomVolume.height" />
                  </div>
                  <label class="text-left">x</label>
                  <div class="align-middle flex items-center w-20">
                    <LeadingTrailingInput trailing-text="m" input-class="!text-xs text-center !pl-4" v-model="state.roomVolume.length" placeholder="0" />
                  </div>
                  <label class="text-left">x</label>
                  <div class="align-middle flex items-center w-20">
                    <LeadingTrailingInput trailing-text="m" input-class="!text-xs !pl-4" v-model="state.roomVolume.width" placeholder="0" />
                  </div>
                </div>
              </div>
              <div class="align-middle flex items-center justify-between">
                <label class="inline-flex"> Sound reaching outlet </label>
                <div class="inline-flex w-20">
                  <LeadingTrailingInput trailing-text="%" input-class="!text-xs" v-model="state.soundReachingOutlet" placeholder="0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="selection-results" class="md:col-span-full sm:col-span-12 rounded-lg">
      <ItemsGrid
        :items="selectionResultItems"
        :grid-columns="selectionGridColumns"
        :loading="state.selecting"
        @actions-view-clicked="viewSelectionResult"
        @actions-add-to-project-clicked="addToProject"
        :current-page="state.currentPage"
        @page-changed="pageChanged"
      />
    </div>
  </div>
  <Modal :open="state.showErrorModal" @on-close="state.showErrorModal = false" panel-class="!max-w-sm">
    <template #content>
      <div class="flex justify-center">
        <ExclamationTriangleIcon class="h-16 w-16 text-amber-500" />
      </div>
      <div class="flex justify-center">
        {{ state.errorMessage }}
      </div>
    </template>
  </Modal>
</template>

<script setup>
import InputDropdown from "../components/shared/InputDropdown.vue";
import Input from "../components/shared/Input.vue";
import Button from "../components/shared/Button.vue";
import ItemsGrid from "../components/selection/ItemsGrid.vue";
import DropdownCheckbox from "../components/shared/DropdownCheckbox.vue";
import { useSelectionStore } from "../stores/selection";
import { useProjectStore } from "../stores/project";
import { storeToRefs } from "pinia";
import SelectionService from "../services/selection";
import { reactive, computed, watch, onMounted } from "vue";
import { HubConnectionState } from "@microsoft/signalr";
import { useRouter, useRoute } from "vue-router";
import LeadingTrailingInput from "../components/shared/LeadingTrailingInput.vue";
import Dropdown from "../components/shared/Dropdown.vue";
import cheetahJs from "cheetah-js";
import RadioPanel from "../components/shared/RadioPanel.vue";
import { useAuthStore } from "../stores/auth";
import { initTooltips } from "flowbite";
import { notify } from "notiwind";
import Modal from "../components/shared/Modal.vue";
import DuctworkConfigModal from "../components/selection/DuctworkConfigModal.vue";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import { useGlobalStore } from "../stores/global";
import { useDataStore } from "../stores/data";
import { utils } from "../helpers/utils";

const authStore = useAuthStore();
const projectStore = useProjectStore();
const globalStore = useGlobalStore();
const dataStore = useDataStore();

const { user } = storeToRefs(authStore);
const router = useRouter();
const route = useRoute();
const { project } = storeToRefs(projectStore);

const selectionService = new SelectionService();
selectionService.onItemSelected = (item) => {
  selectionStore.addSelectionResultItem(item);
  initTooltips();
};

selectionService.onSelectionComplete = () => {
  state.selecting = false;
};
const selectionStore = useSelectionStore();

const { volumeFlowUnits, pressureUnits, temperatureUnits, densityUnits, distanceUnits, selectionInformation, selectionResultItems } = storeToRefs(selectionStore);

const state = reactive({
  errorMessage: "",
  showErrorModal: false,
  showDuctworkConfigModal: false,
  loading: true,
  productsSearchTerm: "",
  accessoriesSearchTerm: "",
  selecting: false,
  currentPage: 1,
  pageLength: 10,
  conditionsOpen: false,
  typesOfEnivronmentOptions: [
    {
      text: "input_value_field",
      value: "free_field",
    },
    {
      text: "input_value_room",
      value: "in_room",
    },
  ],
  typesOfAttenuatorOptions: [
    {
      text: "input_value_rectangle",
      value: "rectangular",
    },
    {
      text: "input_value_circle",
      value: "circular",
    },
  ],
  swlFan: [
    { title: "63", value: null },
    { title: "125", value: null },
    { title: "250", value: null },
    { title: "500", value: null },
    { title: "1k", value: null },
    { title: "2k", value: null },
    { title: "4k", value: null },
    { title: "8k", value: null },
  ],
  requirements: [0, 0, 0, 0, 0, 0, 0, 0],
  treatmentRequired: [0, 0, 0, 0, 0, 0, 0, 0],
  outletCorrections: [0, 0, 0, 0, 0, 0, 0, 0],
  distanceListener: [0, 0, 0, 0, 0, 0, 0, 0],
  soundReaching: 100,
  reverberation: 10,
  roomVolume: {
    height: 10,
    length: 10,
    width: 10,
  },
  soundReachingOutlet: 100,
  distanceToListener: 1,
  outlet: {
    width: null,
    length: null,
  },
  duct: {
    height: null,
    width: null,
  },
  noiseSource: "",
  motorPower: 0,
  sourceSpectrum: [0, 0, 0, 0, 0, 0, 0, 0],
  axialSWlLevel: 0,
  soundLeveldBA: 0,
  distance: 0,
  ductLosses: [0, 0, 0, 0, 0, 0, 0, 0],
  calculationMethod: "dba_at_distance",
  debug_checked: false,
});

const toggleDuctworkConfigModal = () => {
  state.showDuctworkConfigModal = !state.showDuctworkConfigModal;
};

const loadTestPresets = () => {
  state.swlFan[0].value = 75;
  state.swlFan[1].value = 76;
  state.swlFan[2].value = 77;
  state.swlFan[3].value = 80;
  state.swlFan[4].value = 77;
  state.swlFan[5].value = 75;
  state.swlFan[6].value = 75;
  state.swlFan[7].value = 72;

  selectionInformation.value.noiseCriteria.forEach((nc) => (nc.isSelected = false));
  selectionInformation.value.noiseCriteria[3].isSelected = true;
  state.outlet.length = 500;
  state.outlet.width = 500;
  selectionInformation.value.typesOfAttenuator = "rectangular";
  state.duct.height = 500;
  state.duct.width = 500;
  selectionInformation.value.volume = 0.5;
  selectionInformation.value.pressure = 300;
  selectionInformation.value.volumeUnit = "M3S";
};

const outletTypesOptions = computed(() => {
  return [
    { text: "In Free Space", value: "In_Free_Space" },
    { text: "Flush With Surface", value: "Flush_With_Surface" },
    { text: "Junction Of 2 Surfaces", value: "Junction_Of_2_Surfaces" },
    { text: "Junction Of 3 Surfaces", value: "Junction_Of_3_Surfaces" },
  ];
});

const calculationMethods = computed(() => {
  return [
    //  { text: "Please Select", value: "0" },
    { text: "Via dBA measured at distance", value: "dba_at_distance" },
    { text: "Via Beranek", value: "beranek" },
    { text: "Via direct acoustic values", value: "direct_acoustic" },
  ];
});

const calculationMethodChanged = () => {
  // reset relevant values on changing calculation method
  selectionInformation.value.volume = 0;
  selectionInformation.value.pressure = 0;
  selectionInformation.value.motorPowerKw = 0;
  selectionInformation.value.soundLevelSwl = 0;
  selectionInformation.value.soundLevelDba = 0;
  selectionInformation.value.soundDistance = 3;
  selectionInformation.value.soundDbaSource = 0;
  state.requirements = [0, 0, 0, 0, 0, 0, 0, 0,];
  
  for (let i = 0; i < state.swlFan.length; i++) {
    state.swlFan[i].value = 0;
  }

  selectionInformation.value.noiseSources.selectedValue = null; // ISSUE!!! need to reset noise source to clear source spectrum values
};

const roomReverberationOptions = computed(() => {
  let options = [{ text: "Please Select", value: "" }];

  selectionInformation.value.roomReverberation.forEach((rr) => {
    options.push({
      text: rr.roomType,
      value: rr.roomType,
    });
  });

  return options;
});

const selectedNoiseSource = computed(() => {
  let source = selectionInformation.value.noiseSources.items.find((ns) => {
    return ns.value == selectionInformation.value.noiseSources.selectedValue;
  });
  if (source == null) {
    return { db: [0, 0, 0, 0, 0, 0, 0, 0] };
  } else {
    return source.item;
  }
});

const selectedRoomReverberation = computed(() => {
  let source = selectionInformation.value.roomReverberation.find((rr) => {
    return rr.roomType == state.roomReverberation;
  });
  selectionInformation.value.roomReverberation.forEach((rr) => {
    rr.isSelected = rr.roomType == state.roomReverberation;
  });
  if (source == null) {
    return { roomType: "", reverberation: 0 };
  } else {
    return source;
  }
});

const projectCurrency = computed(() => {
  return project.value.currencyOptions.find((c) => c.value == project.value.currency);
});

const selectionGridColumns = computed(() => {
  let columns = [
    { value: "actions", text: " ", sortable: false, fixed: true, width: 100 },
    {
      value: "module",
      text: "Module",
      sortable: true,
      fixed: true,
      width: 85,
    },
    {
      value: "type",
      text: "Type",
      sortable: true,
      width: 80,
    },
    {
      value: "length",
      text: "Length",
      sortable: true,
      width: 80,
    },
    {
      value: "width",
      text: "Width",
      sortable: true,
      width: 80,
    },
    {
      value: "height",
      text: "Height",
      sortable: true,
      width: 80,
    },
    
    {
      value: "diameter",
      text: "Diameter",
      sortable: true,
      width: 80,
    },

    {
      value: "velocity",
      text: "Velocity",
      sortable: true,
      width: 85,
    },
    {
      value: "loss",
      text: "Loss",
      sortable: true,
      width: 65,
    },
    {
      value: "db63",
      text: "63",
      sortable: true,
      width: 75,
    },
    {
      value: "db125",
      text: "125",
      sortable: true,
      width: 65,
    },
    {
      value: "db250",
      text: "250",
      sortable: true,
      width: 65,
    },
    {
      value: "db500",
      text: "500",
      sortable: true,
      width: 65,
    },
    {
      value: "db1k",
      text: "1k",
      sortable: true,
      width: 65,
    },
    {
      value: "db2k",
      text: "2k",
      sortable: true,
      width: 65,
    },
    {
      value: "db4k",
      text: "4k",
      sortable: true,
      width: 65,
    },
    {
      value: "db8k",
      text: "8k",
      sortable: true,
      width: 65,
    },
  ];

  if (!user.value.userGroup.permissions.pricing.showSalePricing) {
    columns = columns.filter((c) => c.value != "price");
  }

  if (selectionInformation.value.typesOfAttenuator == "rectangular") {
    columns = columns.filter((c) => c.value != "diameter");
  }

  if (selectionInformation.value.typesOfAttenuator == "circular") {
    columns = columns.filter((c) => c.value != "width" && c.value != "height");
  }

  return columns;
});

const selectedNoiseCriteria = computed({
  get() {
    return selectionInformation.value.noiseCriteria.find((nc) => nc.isSelected);
  },
  set(item) {
    selectionInformation.value.noiseCriteria.forEach((nc) => (nc.isSelected = nc.criteriaName == item.criteriaName));
  },
});

const pageChanged = (value) => {
  state.currentPage = value;
};

const volumeUnitChanged = (value) => {
  selectionInformation.value.volumeUnit = value;
};

const volumeChanged = (value) => {
  selectionInformation.value.volume = parseFloat(value);
  if (selectionInformation.value.motorPowerKw > 0 || selectionInformation.value.pressure > 0) calculateTreatmentRequired(false, false, true);
};

const pressureUnitChanged = (value) => {
  selectionInformation.value.pressureUnit = value;
};

const pressureChanged = (value) => {
  selectionInformation.value.pressure = parseFloat(value);
  if (selectionInformation.value.motorPowerKw > 0 || selectionInformation.value.volume > 0) calculateTreatmentRequired(false, false, true);
};

const viewSelectionResult = (item) => {
  router.push({
    name: "technical",
    params: { id: item.id, referrer: "selection" },
  });
};

const addToProject = (item) => {
  projectStore.addToProject(item, item.accessories).then(() => {
    notify(
      {
        group: "notifications",
        title: "Success",
        text: "Item was added to the project",
      },
      5000
    );
  });
};

const startSelection = () => {
  state.currentPage = 1;
  state.selecting = true;
  selectionStore.clearSelectionResultItems();
  let sc = selectedNoiseCriteria.value;
  selectionService
    .startSelection({
      ...selectionInformation.value,
      volume: selectionInformation.value.volume == null ? 0 : selectionInformation.value.volume,
      pressure: selectionInformation.value.pressure == null ? 0 : selectionInformation.value.pressure,
      connectionId: selectionService.connectionId,
      requiredCriteria: {
        description: sc.criteriaName,
        dB: [sc.db63, sc.db125, sc.db250, sc.db500, sc.db1k, sc.db2k, sc.db4k, sc.db8k],
      },
      treatmentRequired: {
        description: "Attenuation required",
        dB: state.treatmentRequired,
      },
      swlFan: {
        description: "SWL @ source",
        dB: state.swlFan.map((v) => v.value),
      },
      swlAdjusted: { description: "Adjusted", dB: state.requirements },
      width: state.duct.width,
      height: state.duct.height,
      diameter: state.duct.diameter,
      ductworkConfiguration: selectionInformation.value.ductworkConfiguration,
    })
    .catch((err) => {
      if (err.name && err.name != "AbortError") {
        state.errorMessage = err;
        state.showErrorModal = true;
        state.selecting = false;
      }
    });
};

const stopSelection = () => {
  selectionService.stopSelection();
};

const getDbaSource = (spl, d) => {
  return spl + 20 * Math.log10(d);
};

const aWeighting = [-26.2, -16.1, -8.6, -3.2, 0, 1.2, 1, -1.1];

const getDbaFromDbw = (dbw, noiseSourceCorrections, distance) => {
  let swlCorrectedSpectrum = [];

  let dba = 0;

  for (let i = 0; i < noiseSourceCorrections.length; i++) {
    swlCorrectedSpectrum.push(dbw + noiseSourceCorrections[i]);
  }

  let dbaCorrectedSpectrum = [];

  for (let i = 0; i < aWeighting.length; i++) {
    dbaCorrectedSpectrum.push(swlCorrectedSpectrum[i] + aWeighting[i]);
  }

  let dbaDistanceSpectrum = [];

  for (let i = 0; i < dbaCorrectedSpectrum.length; i++) {
    dbaDistanceSpectrum.push(dbaCorrectedSpectrum[i] - 20 * Math.log10(distance));
  }

  dba =
    Math.log10(
      10 ** (dbaDistanceSpectrum[0] / 10) +
        10 ** (dbaDistanceSpectrum[1] / 10) +
        10 ** (dbaDistanceSpectrum[2] / 10) +
        10 ** (dbaDistanceSpectrum[3] / 10) +
        10 ** (dbaDistanceSpectrum[4] / 10) +
        10 ** (dbaDistanceSpectrum[5] / 10) +
        10 ** (dbaDistanceSpectrum[6] / 10) +
        10 ** (dbaDistanceSpectrum[7] / 10)
    ) * 10;

  return dba;
};

const applyDuctworkConfigChanges = () => {
  toggleDuctworkConfigModal();
  calculateTreatmentRequired();
};

const calculateTreatmentRequired = (swlMode, dbaMode, ceSplMode) => {
  console.log(`calculating treatment required, using method: ${state.calculationMethod}`);

  let selInfo = selectionInformation.value;

  let outletType = selInfo.typesOfEnvironment == "free_field" ? [0] : [];
  if (selInfo.typesOfEnvironment != "free_field") {
    switch (selInfo.outletTypes) {
      case "In_Free_Space":
        outletType = [1];
        break;
      case "Flush_With_Surface":
        outletType = [2];
        break;
      case "Junction_Of_2_Surfaces":
      case "Junction_Of_3_Surfaces":
        outletType = [3, 4];
        break;
      default:
        break;
    }
  }

  let dbaSource = 0;
  let ceSpl1 = 0;
  let ceSpl2 = 0;
  let ceSpl3 = 0;
  let dbw = selInfo.soundLevelSwl;
  let dba = selInfo.soundLevelDba;
  let distance = selInfo.soundDistance;
  let noiseSourceCorrections = selectedNoiseSource.value.db;
  let motorPower = selInfo.motorPowerKw;
  let pressure = selInfo.pressure;
  let volumeFlow = selInfo.volume;
  let useSwl = swlMode != undefined && swlMode;
  let useDba = dbaMode != undefined && dbaMode;
  let useCeSpl = ceSplMode != undefined && ceSplMode;

  if (volumeFlow > 0) {
    volumeFlow = cheetahJs.conversion.convertUnit(volumeFlow, selInfo.volumeUnit, cheetahJs.units.Enums.VolumeFlowUnits.M3S);
  }
  if (pressure > 0) {
    pressure = cheetahJs.conversion.convertUnit(pressure, selInfo.pressureUnit, cheetahJs.units.Enums.PressureUnits.Pa);
  }

  // if (!useSwl && !useDba && !useCeSpl) {
  //   if (selInfo.soundLevelDba != null && selInfo.soundLevelDba != null > 0) {
  //     useDba = true;
  //   } else {
  //     if (selInfo.soundLevelSwl != null && selInfo.soundLevelSwl > 0) {
  //       useSwl = true;
  //     } else {
  //       if ((selInfo.pressure > 0 && selInfo.motorPowerKw > 0) || (selInfo.volume > 0 && selInfo.pressure > 0) || (selInfo.volume > 0 && selInfo.motorPowerKw > 0)) {
  //         useCeSpl = true;
  //       }
  //     }
  //   }
  // }

  useSwl = state.calculationMethod == false;
  useDba = state.calculationMethod == "dba_at_distance";
  useCeSpl = state.calculationMethod == "beranek";

  if (!useSwl && !useDba && !useCeSpl) return;

  if (useDba) {
    // dba_at_distance
    dba = selInfo.soundLevelDba;

    dbaSource = getDbaSource(dba, distance); // 85bda @ 3m = 94.5424
    let spectrum1 = [];
    let spectrum2 = [];

    for (let i = 0; i < aWeighting.length; i++) {
      spectrum1.push(dbaSource + aWeighting[i]);
    }

    for (let i = 0; i < noiseSourceCorrections.length; i++) {
      spectrum2.push(spectrum1[i] + noiseSourceCorrections[i]);
    }

    let dbw2 =
      Math.log10(
        10 ** (spectrum2[0] / 10) +
          10 ** (spectrum2[1] / 10) +
          10 ** (spectrum2[2] / 10) +
          10 ** (spectrum2[3] / 10) +
          10 ** (spectrum2[4] / 10) +
          10 ** (spectrum2[5] / 10) +
          10 ** (spectrum2[6] / 10) +
          10 ** (spectrum2[7] / 10)
      ) * 10;

    dbw = dbw2 + selectedNoiseSource.value.constant; // 85bda @ 3m = 101.5295
    selInfo.soundLevelSwl = utils.roundToN(dbw, 1);
    selInfo.soundDbaSource = utils.roundToN(dbaSource, 1);
  }

  if (useSwl) {
    // direct_acoustic
    dbw = selInfo.soundLevelSwl;

    dba = getDbaFromDbw(dbw, noiseSourceCorrections, distance);

    selInfo.soundLevelDba = dba;
    dbaSource = getDbaSource(dba, distance);
    selInfo.soundLevelDba = utils.roundToN(dba, 1);
    selInfo.soundDbaSource = utils.roundToN(dbaSource, 1);
  }

  if (useCeSpl) {
    // beranek
    if (motorPower >= 0 && pressure > 0) {
      ceSpl1 = 67.0 + 10 * Math.log10(motorPower) + 10 * Math.log10(pressure);
    }

    if (volumeFlow > 0 && pressure > 0) {
      ceSpl2 = 40.0 + 10 * Math.log10(volumeFlow) + 20 * Math.log10(pressure);
    }

    if (volumeFlow > 0 && motorPower > 0) {
      ceSpl3 = 94.0 + 20 * Math.log10(motorPower) - 10 * Math.log10(volumeFlow);
    }
    let ceSpl = [ceSpl1, ceSpl2, ceSpl3].filter((v) => v > 0);

    let ceSplAvg = useCeSpl ? ceSpl.reduce((a, b) => a + b) / ceSpl.length : 0;

    if (ceSplAvg > 0) dbw = ceSplAvg;
    selInfo.soundLevelSwl = dbw;
    dba = getDbaFromDbw(dbw, noiseSourceCorrections, distance);
    // dbaSource = getDbaSource(dbw, distance);
    dbaSource = Math.round((dba + 20 * Math.log10(distance)) * 100) / 100;
    selInfo.soundLevelSwl = utils.roundToN(dbw, 1);
    selInfo.soundLevelDba = utils.roundToN(dba, 1);
    selInfo.soundDbaSource = utils.roundToN(dbaSource, 1);
  }

  for (let index = 0; index < noiseSourceCorrections.length; index++) {
    state.swlFan[index].value = dbw + noiseSourceCorrections[index];
  }

  let outletCorrections = [0, 0, 0, 0, 0, 0, 0, 0];
  let swlLeavingSystem = [0, 0, 0, 0, 0, 0, 0, 0];
  let soundReachingRoomCorrection = [0, 0, 0, 0, 0, 0, 0, 0];
  let roomVolumeCorrections = [0, 0, 0, 0, 0, 0, 0, 0];
  let reverberationTimeCorrections = [0, 0, 0, 0, 0, 0, 0, 0];
  let treatmentRequired = [0, 0, 0, 0, 0, 0, 0, 0];
  let ductWorkSectionItems = null;

  let corrections = dataStore.areaCorrectionsData.filter((c) =>
    c.outletType
      .split(",")
      .map((a) => parseInt(a))
      .some((a) => outletType.includes(a))
  );

  let outletArea = (state.outlet.length / 1000.0) * (state.outlet.width / 1000.0); // area in m2

  let aboveArea = null;
  let belowArea = null;

  let aboveAreaCorrection = null;
  let belowAreaCorrection = null;

  if (outletArea > 0) {
    aboveArea = Math.min(...corrections.map((c) => c.area).filter((a) => a >= outletArea));
    belowArea = Math.max(...corrections.map((c) => c.area).filter((a) => a <= outletArea));

    aboveAreaCorrection = corrections.find((c) => c.area == aboveArea);
    belowAreaCorrection = corrections.find((c) => c.area == belowArea);

    let aboveAreaCorrectionDb = [
      aboveAreaCorrection.db63,
      aboveAreaCorrection.db125,
      aboveAreaCorrection.db250,
      aboveAreaCorrection.db500,
      aboveAreaCorrection.db1k,
      aboveAreaCorrection.db2k,
      aboveAreaCorrection.db4k,
      aboveAreaCorrection.db8k,
    ];

    let belowAreaCorrectionDb = [
      belowAreaCorrection.db63,
      belowAreaCorrection.db125,
      belowAreaCorrection.db250,
      belowAreaCorrection.db500,
      belowAreaCorrection.db1k,
      belowAreaCorrection.db2k,
      belowAreaCorrection.db4k,
      belowAreaCorrection.db8k,
    ];

    let upFactor = aboveArea == belowArea ? 1.0 : (outletArea - belowAreaCorrection.area) / (aboveAreaCorrection.area - belowAreaCorrection.area);

    for (let index = 0; index < outletCorrections.length; index++) {
      outletCorrections[index] = (aboveAreaCorrectionDb[index] - belowAreaCorrectionDb[index]) * upFactor + belowAreaCorrectionDb[index];

      swlLeavingSystem[index] = state.swlFan[index].value + outletCorrections[index];
    }

    state.outletCorrections = outletCorrections

  }

  let ductworkConfig = selectionInformation.value.ductworkConfiguration;
  let ductComponents = ductworkConfig.addedDuctTypes;
  let ductLosses = [0, 0, 0, 0, 0, 0, 0, 0];

  for (let i = 0; i < ductComponents.length; i++) {
    const component = ductComponents[i];
    let ductType = component.ductType.items.find((d) => d.value == component.ductType.selectedValue);
    let subDuctType = ductType.subItems.items.find((d) => d.value == ductType.subItems.selectedValue);
    let correction = ductworkConfig.ductCorrections.find(
      (d) => d.ductTypeId == ductType.value && d.subDuctTypeId == subDuctType.value && d.minWidth <= component.width && d.maxWidth >= component.width
    );
    if (correction) {
      for (let j = 0; j < correction.corrections.length; j++) {
        const db = correction.corrections[j] * -1.0 * component.length * component.quantity;
        ductLosses[j] = ductLosses[j] + db;
      }
    }
  }

  for (let i = 0; i < ductLosses.length; i++) {
    swlLeavingSystem[i] = swlLeavingSystem[i] + ductLosses[i];
  }

  let soundReachingOutlet = [0, 0, 0, 0, 0, 0, 0, 0];
  let soundDistanceCorrection = [0, 0, 0, 0, 0, 0, 0, 0];
  let splReverberant = [0, 0, 0, 0, 0, 0, 0, 0];
  let soundReachingOutletCorrection = [0, 0, 0, 0, 0, 0, 0, 0];
  let soundReachingRoomCorrectionOutput = [0, 0, 0, 0, 0, 0, 0, 0];

  if (selInfo.typesOfEnvironment == "in_room") {
    corrections = "SOUND_REACHING_ROOM";

    let soundReachingRoom = state.soundReaching;

    let overAllCorrections = dataStore.overAllCorrectionsData.filter((c) => c.correctionType == corrections);

    if (soundReachingRoom > 0) {
      let aboveParameter = Math.min(...overAllCorrections.map((c) => c.parameter).filter((a) => a >= soundReachingRoom));
      let belowParameter = Math.max(...overAllCorrections.map((c) => c.parameter).filter((a) => a <= soundReachingRoom));
      let above = overAllCorrections.find((c) => c.parameter == aboveParameter);
      let below = overAllCorrections.find((c) => c.parameter == belowParameter);

      let aboveparameterCorrection = { ...above };
      let belowparameterCorrection = { ...below };
      let upFactor =
        aboveparameterCorrection.parameter == belowparameterCorrection.parameter
          ? 1
          : (soundReachingRoom - belowparameterCorrection.parameter) / (aboveparameterCorrection.parameter - belowparameterCorrection.parameter);

      soundReachingRoomCorrection = (aboveparameterCorrection.attenuation - belowparameterCorrection.attenuation) * upFactor + belowparameterCorrection.attenuation;

      for (let i = 0; i < 8; i++) {
        soundReachingRoomCorrectionOutput[i] = soundReachingRoomCorrection;
      }
    }

    let roomVolume = (state.roomVolume.height / 1000.0) * (state.roomVolume.length / 1000.0) * (state.roomVolume.width / 1000.0);

    corrections = "ROOM_VOLUME";

    overAllCorrections = dataStore.overAllCorrectionsData.filter((c) => c.correctionType == corrections);

    if (roomVolume > 0) {
      let aboveParameter = Math.min(...overAllCorrections.map((c) => c.parameter).filter((a) => a >= roomVolume));
      let belowParameter = Math.max(...overAllCorrections.map((c) => c.parameter).filter((a) => a <= roomVolume));
      let above = overAllCorrections.find((c) => c.parameter == aboveParameter);
      let below = overAllCorrections.find((c) => c.parameter == belowParameter);

      let upFactor = (roomVolume - below.parameter) / above.parameter - below.parameter;

      let roomVolumeCorrection = (above.attenuation - below.attenuation) * upFactor + below.attenuation;

      roomVolumeCorrections = [
        roomVolumeCorrection,
        roomVolumeCorrection,
        roomVolumeCorrection,
        roomVolumeCorrection,
        roomVolumeCorrection,
        roomVolumeCorrection,
        roomVolumeCorrection,
        roomVolumeCorrection,
      ];
    }

    let reverberationTime = state.reverberation / 1000.0;

    corrections = "REVERBERATION_TIME";

    overAllCorrections = dataStore.overAllCorrectionsData.filter((c) => c.correctionType == corrections);

    if (reverberationTime > 0) {
      let aboveParameter = Math.min(...overAllCorrections.map((c) => c.parameter).filter((a) => a >= reverberationTime));
      let belowParameter = Math.max(...overAllCorrections.map((c) => c.parameter).filter((a) => a <= reverberationTime));
      let above = overAllCorrections.find((c) => c.parameter == aboveParameter);
      let below = overAllCorrections.find((c) => c.parameter == belowParameter);

      let upFactor = reverberationTime - below.parameter / above.parameter - below.parameter;
      let reverberationTimeCorrection = (above.attenuation - below.attenuation) * upFactor + below.attenuation;

      reverberationTimeCorrections = [
        reverberationTimeCorrection,
        reverberationTimeCorrection,
        reverberationTimeCorrection,
        reverberationTimeCorrection,
        reverberationTimeCorrection,
        reverberationTimeCorrection,
        reverberationTimeCorrection,
        reverberationTimeCorrection,
      ];
    }

    for (let i = 0; i < 8; i++) {
      splReverberant[i] = swlLeavingSystem[i] + soundReachingRoomCorrection + roomVolumeCorrections[i] + reverberationTimeCorrections[i];
    }

    corrections = "SOUND_REACHING_ROOM";

    let soundReachingOutlet = state.soundOutlet;

    overAllCorrections = dataStore.overAllCorrectionsData.filter((c) => c.correctionType == corrections);

    if (soundReachingOutlet > 0) {
      let aboveParameter = Math.min(...overAllCorrections.map((c) => c.parameter).filter((a) => a >= soundReachingOutlet));
      let belowParameter = Math.max(...overAllCorrections.map((c) => c.parameter).filter((a) => a <= soundReachingOutlet));
      let above = overAllCorrections.find((c) => c.parameter == aboveParameter);
      let below = overAllCorrections.find((c) => c.parameter == belowParameter);

      let aboveparameterCorrection = { ...above };
      let belowparameterCorrection = { ...below };
      let upFactor =
        aboveparameterCorrection.parameter == belowparameterCorrection.parameter
          ? 1
          : (soundReachingOutlet - belowparameterCorrection.parameter) / (aboveparameterCorrection.parameter - belowparameterCorrection.parameter);

      let soundReachingRoomOutlet = (aboveparameterCorrection.attenuation - belowparameterCorrection.attenuation) * upFactor + belowparameterCorrection.attenuation;

      for (let index = 0; index < 8; index++) {
        soundReachingOutletCorrection[index] = soundReachingRoomOutlet;
      }
    }

    corrections = "DISTANCE_LISTENER";
    let distanceListener = state.distanceToListener;

    overAllCorrections = dataStore.overAllCorrectionsData.filter((c) => c.correctionType == corrections);
    let loss = 20 * Math.log10(distanceListener) + 11;

    for (let index = 0; index < 8; index++) {
      soundDistanceCorrection[index] = loss * -1;
    }
  }
  let requriedDB = [
    selectedNoiseCriteria.value.db63,
    selectedNoiseCriteria.value.db125,
    selectedNoiseCriteria.value.db250,
    selectedNoiseCriteria.value.db500,
    selectedNoiseCriteria.value.db1k,
    selectedNoiseCriteria.value.db2k,
    selectedNoiseCriteria.value.db4k,
    selectedNoiseCriteria.value.db8k,
  ];
  let directSPL = [0, 0, 0, 0, 0, 0, 0, 0];
  let resultantSPL = [0, 0, 0, 0, 0, 0, 0, 0];

  for (let i = 0; i < 8; i++) {
    directSPL[i] = swlLeavingSystem[i] + soundReachingOutlet[i] + soundDistanceCorrection[i];

    resultantSPL[i] = 10 * Math.log10(Math.pow(10, directSPL[i] / 10) + Math.pow(10, splReverberant[i] / 10));
    if (resultantSPL[i] > requriedDB[i]) {
      treatmentRequired[i] = resultantSPL[i] - requriedDB[i];
      if (treatmentRequired[i] < 0) {
        treatmentRequired[i] = 0;
      } else {
        treatmentRequired[i];
      }
    } else {
      treatmentRequired[i] = 0;
    }
  }

  state.ductLosses = ductLosses;

  state.treatmentRequired = treatmentRequired;
  state.requirements = resultantSPL;

  for (let index = 0; index < state.swlFan.length; index++) {
    const element = state.swlFan[index];
    element.value = parseFloat(element.value.toFixed(1));
  }
};

onMounted(() => {
  Promise.all([dataStore.getAreaCorrectionsData(), dataStore.getOverAllCorrectionsData()]).then(() => {
    state.loading = false;
  });
  if (selectionService.hubConnection?.state != HubConnectionState.Connected && selectionService.hubConnection?.state != HubConnectionState.Connecting) selectionService.startConnection();

  initTooltips();

  watch(
    () => selectedNoiseCriteria.value,
    () => {
      calculateTreatmentRequired();
    }
  );
});

const openDuctworkConfigModal = () => {
  state.showDuctworkConfigModal = true;
};
</script>
